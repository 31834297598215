import { configureStore } from '@reduxjs/toolkit';
import NavigationReducer from './NavigationSlice';
import UserReducer from './UserSlice';
import ClockTimeSavedReducer from './ClockTimeSavedSlice';
import GeneralSliceReducer from './GeneralSlice';

export const store = configureStore({
	reducer: {
		navState: NavigationReducer,
		userState: UserReducer,
		clockTimeSavedState: ClockTimeSavedReducer,
		generalState: GeneralSliceReducer,
	},
})