import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'


/***
 * ModalDialog
 * 
 * Props:
 * --> icon
 * --> title
 * --> message
 * --> cancelButtonTitle
 * --> cancelButtonFunc
 * --> confirmButton
 * --> confirmButtonFunc
 */
export default function ModalDialog(props) {
  const [open, setOpen] = useState(props.show)

  useEffect(() => {
    // console.log('props.show: ' + (props.show ? 'true' : 'false') + ' open: ' + (open ? 'true' : 'false'));
    if (open !== props.show) {
       setOpen(!props.show);
     }
  }, [props.show])

  return (
    <Transition.Root show={props.show} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full">
                  {props.icon}
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    {props.title}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      {props.message}                      
                    </p>
                  </div>
                </div>
              </div>

              {(props.confirmButtonTitle && props.cancelButtonTitle) &&
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 sm:col-start-2 sm:text-sm"
                    onClick={() => {
                      if (props.confirmButtonFunc) {
                        props.confirmButtonFunc();
                      } else {
                        setOpen(false)
                      }

                    }}>
                    {props.confirmButtonTitle}
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => {
                      if (props.cancelButtonFunc) {
                        props.cancelButtonFunc();
                      } else {
                        setOpen(false)
                      }

                    }}
                  >
                    {props.cancelButtonTitle}
                  </button>
                </div>
              }

              {!props.cancelButtonTitle && 
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-1 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 sm:text-sm"
                    onClick={() => {
                      if (props.confirmButtonFunc) {
                        props.confirmButtonFunc();
                      } else {
                        setOpen(false)
                      }

                    }}>
                    {props.confirmButtonTitle}
                  </button>                                    
                </div>
              }
              <div>
                {props.mason}
              </div>
              
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
