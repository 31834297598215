import { Fragment, useState, useEffect } from 'react'
import { BellIcon, PlusCircleIcon } from '@heroicons/react/20/solid'
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { updateUserInfo } from '../app/UserSlice';

import Parse from 'parse';

import * as Constants from '../model/index';
import ModalDialog from './ModalDialog';
import ModalIndeterminate from './ModalIndeterminate';

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

function formattedDate(dateString) {
    let date = new Date(dateString).toLocaleString('en-US'); //.toLocaleDateString("en-US");
    return date;
}

export default function DashboardPayments() {    
    const [alert, setAlert] = useState({show: false});
	const [progress, setProgress] = useState({show: false});
    const [currentTab, setCurrentTab] = useState('All');
    const [allCount, setAllAcount] = useState(0);
    const [pendingCount, setPendingCount] = useState(0);
    const [issuesCount, setIssuesCount] = useState(0);
    const [completedCount, setCompletedCount] = useState(0);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [workLogData, setWorkLogData] = useState([]);
    const [workLogTotal, setWorkLogTotal] = useState(0);
    const [displayItems, setDisplayItems] = useState([]);

    const userState = useSelector((state) => state.userState);
        
    const dispatch = useDispatch(); 
    const navigate = useNavigate();

    useEffect(()=>{
        getUserData();	
    }, []);

    useEffect(() => {
        setDisplayItems(userState.businessPayments);
    }, [userState]);


    const getUserData = async () => {
        Parse.Cloud.run('GetUserData', {}).then((result) => {				
            console.log('GetUSerData Result payments:', result);
            setDisplayItems(result.businessPayments);
            processTabCounts(result.businessPayments);
            dispatch(updateUserInfo(result))
        })
        .catch((error) => {
            if (error.code === 209) { // invalid session
                // token expired
                console.log("Payments Dashboard User Login Expired");
                Parse.User.logOut().then(()=> navigate('/sign-in', {replace: true}));
            } else {
                console.log("Payments Dashboard Error with getUserData:", error);
            }
        });	
    }
    
    const processTabCounts = (payments) => {
        if (!payments) return;

        setAllAcount(payments?.length || 0);

        let totalPending = 0;
        let totalCompleted = 0; 
        let totalIssues = 0;

        payments?.forEach(payment => {
            if (payment.status === "Pending") {                                
                totalPending++;
            } else if (payment.status === "Completed") {
                totalCompleted++;
            } else if (payment.status === "Issues") {
                totalIssues++;
            }
        });

        setPendingCount(totalPending);
        setCompletedCount(totalCompleted);            
        setIssuesCount(totalIssues);
    }


    const handleMakePayment = (payment) => {
        setAlert({
            show: true,
            title: `Are You Sure You Want to Pay ${payment.payeeName}?`,
            message: `If approved, a total of ${payment.amountRequested} will be deducted from your account and deposited in 1-3 days in ${payment.payeeName}'s account.`,
            okTitle: 'Approve Payment',
            cancelTitle: 'Cancel',
            confirmButtonFunc: ()=> {
                setAlert({show:false});
                // todo: approve payment
                console.log("Approve Payment Clicked", payment.id);
                processPayment(payment.id);
            },
            cancelButtonFunc: ()=> {
                setAlert({show:false});
            }
        });
    };


    const processPayment = async (paymentId) => {
        setProgress({show: true, title: 'Processing Payment', message: 'Please wait while we process your payment.'});
        try {
            const result = await Parse.Cloud.run('ProcessPayment', {paymentId: paymentId});
            console.log('ProcessPayment Result:', result);
            
            setProgress({show: false});
            
            setAlert({
                show: true,
                title: 'Payment Processed Successfully',
                message: `Payment has been processed successfully and will be deposited in the worker's account within 1-3 days.`,
                okTitle: 'Ok'
            });

            // update user info
            getUserData();

        } catch (error) {
            console.log('ProcessPayment Error:', error);
            setProgress({show: false});
            setAlert({
                show: true,
                title: 'Payment Processing Error',
                message: `An error occurred while processing the payment. Please try again later.`,
                okTitle: 'Ok'
            });
        }                                
    }

    const getWorkLogDataForPayment = async (paymentId) => {
        try {
            const results = await Parse.Cloud.run('GetWorkLogFromPaymentId', {paymentId: paymentId});
            console.log('GetWorkLogDataForPayment Result:', results);
            setWorkLogData(results);

            // get the total from the work log for display
            let total = 0;
            results.forEach(workLog => {
                total += parseFloat(workLog.workHours).toFixed(2) / 60 * parseFloat(workLog.workRate).toFixed(2);
            }); 

            setWorkLogTotal(total.toFixed(2));

        } catch (error) {
            console.log('GetWorkLogDataForPayment Error:', error);
        }
    }

    const formatMoney = (value) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
        }).format(value);
    }
    
    return (
        <>                     
            <ModalDialog show={alert.show || false} title={alert.title} message={alert.message}	
				confirmButtonTitle={alert.okTitle || "Ok"} cancelButtonTitle={alert.cancelTitle} icon={<BellIcon className="text-blue-500" />}
				confirmButtonFunc={alert.confirmButtonFunc || (() => setAlert({showAlert:false}))} 
                cancelButtonFunc={alert.cancelButtonFunc || (() => setAlert({showAlert:false}))} />

			<ModalIndeterminate show={progress.show} title={progress.title || ''} message={progress.message || ''} />   
            
            {/* Payments List */}
            <div>
                <div className="sm:hidden">
                    <label htmlFor="tabs" className="sr-only">
                        Select a tab
                    </label>
                    {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                    <select
                        id="tabs"
                        name="tabs"
                        className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                        defaultValue={Constants.tabs.find((tab) => tab.current).name}
                    >
                        <option>All</option>
                        <option>Pending</option>
                        <option>Completed</option>
                        <option>Issues</option>
                    </select>
                </div>

                <div className="hidden sm:block mt-10 text-left">
                    <div className="flex justify-between">
                        {/* <div className="text-4xl text-gray-900">Contractors</div> */}                        
                    </div>
                    <div className="border-b border-gray-200">
                        <div className='flex'>
                            <nav className="-mb-px flex space-x-8" aria-label="Tabs">

                                {/* All Tab */}
                                <a
                                    href="#all"
                                    className={classNames(
                                        currentTab === 'All'
                                            ? 'border-blue-500 text-blue-600'
                                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                                        'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm'
                                    )}
                                    aria-current={'page'}
                                >
                                    {'All'}
                                    
                                    <span
                                        className={classNames(
                                            currentTab ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-900',
                                            'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                                        )}
                                    >
                                        {allCount}
                                    </span>                                        
                                </a>

                                {/* Pending Tab */}
                                <a
                                    href="#pending"
                                    className={classNames(
                                        currentTab === 'Pending'
                                            ? 'border-blue-500 text-blue-600'
                                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                                        'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm'
                                    )}
                                    aria-current={'page'}
                                >
                                    {'Pending'}
                                    
                                    <span
                                        className={classNames(
                                            currentTab === 'Pending' ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-900',
                                            'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                                        )}
                                    >
                                        {pendingCount}
                                    </span>                                        
                                </a>

                                {/* Completed Tab */}
                                <a
                                    href="#completed"
                                    className={classNames(
                                        currentTab === 'Completed'
                                            ? 'border-blue-500 text-blue-600'
                                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                                        'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm'
                                    )}
                                    aria-current={'page'}
                                >
                                    {'Completed'}
                                    
                                    <span
                                        className={classNames(
                                            currentTab === 'Completed' ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-900',
                                            'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                                        )}
                                    >
                                        {completedCount}
                                    </span>                                        
                                </a>

                                {/* Issues Tab */}
                                <a
                                    href="#issues"
                                    className={classNames(
                                        currentTab === 'Issues'
                                            ? 'border-blue-500 text-blue-600'
                                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                                        'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm'
                                    )}
                                    aria-current={'page'}
                                >
                                    {'Issues'}
                                    
                                    <span
                                        className={classNames(
                                            currentTab === 'Issues' ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-900',
                                            'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                                        )}
                                    >
                                        {issuesCount}
                                    </span>                                        
                                </a>
                            </nav>
                            <div>
                                <button className="inline-flex items-center ml-4 px-3 py-2 border border-transparent text-sm leading-4 font-medium focus:outline-none"
                                    onClick={() => console.log("New Project Clicked")}>
                                    <PlusCircleIcon className="self-center flex-shrink-0 h-8 w-8 text-blue-500" />
                                    <div className="ml-1 font-bold text-blue-500"><Link to="#add-payment">Manual Payment</Link></div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Team Table */}
            <div className="mt-5 flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>                                        
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Name
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Title
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Hours
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Total Amount
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Date Req.
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Date Paid
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Status
                                        </th>
                                        <th className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium text-gray-500">{/* Actions */}</th>
                                    </tr>
                                </thead>
                                <tbody>                                    
                                    {userState.businessPayments?.length === 0 && 
                                        <>                                            
                                            <tr>
                                                <td className="px-4 py-4 whitespace-nowrap text-left text-base font-bold text-gray-900"> 
                                                    No Payments Have Been Initiated Yet <br/>
                                                    <span className="text-left text-sm font-normal text-gray-700">
                                                        Create a Manual Payment or Wait for a Worker to Request Payment
                                                    </span>                                                    
                                                </td>
                                            </tr>
                                        </>
                                    }
                                    
                                    {/* {userState.businessPayments && userState.businessPayments.map((payment, index) => ( */}
                                    {displayItems && displayItems.map((payment, index) => (
                                        <>
                                            <tr key={index} 
                                                className={classNames(index % 2 === 0 ? 'bg-white' : 'bg-blue-50', 'hover:bg-blue-100 hover:cursor-pointer')}
                                                onClick={e => {
                                                    e.preventDefault(true); 
                                                    setWorkLogData([]);
                                                    setWorkLogTotal(0);
                                                    if (selectedIndex === index) {
                                                        setSelectedIndex(null);
                                                        return;
                                                    } else {
                                                        setSelectedIndex(index);
                                                        getWorkLogDataForPayment(payment.id);
                                                    }
                                                }}>

                                                <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium text-gray-900">{payment.payeeName}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium text-gray-500">{payment.title}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium text-gray-500">{payment.hours}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium text-gray-500">${parseFloat(payment.amountRequested).toFixed(2)}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium text-gray-500">{formattedDate(payment.createdDate)}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium text-gray-500">{payment.datePaid ? formattedDate(payment.datePaid) : 'n/a'}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium text-gray-500">
                                                    {payment.status === "Completed" && (
                                                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                            {payment.status}
                                                        </span>
                                                    )}

                                                    {payment.status === "Pending" && (
                                                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-600">
                                                            {payment.status}
                                                        </span>
                                                    )}
                                                        
                                                    {payment.status === "Issues" && (
                                                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-700">
                                                            {payment.status}
                                                        </span>
                                                    )}                                                
                                                    
                                                </td>                                            
                                                <td>
                                                    {payment.status !== "Completed" && payment.status !== "Issues" &&                                                        
                                                        <div className='mr-4'>
                                                            <button
                                                                type="submit"
                                                                className="w-full flex justify-center h-8 py-2 px-4 border border-transparent rounded-md shadow-sm text-xs font-bold text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                                                onClick={e => {
                                                                    e.preventDefault(true)
                                                                    handleMakePayment(userState.businessPayments[index]);
                                                                }}>
                                                                Pay
                                                            </button>
                                                        </div>
                                                    }
                                                </td>                                            
                                            </tr>
                                            
                                            {/* show work log data if selected */}
                                            {selectedIndex === index && (
                                                <>
                                                    <tr key={'workLog-' + index} className="bg-gray-400">
                                                        <td colSpan={8} className="p-8">
                                                            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                                            <table className="w-full divide-y divide-blue-200 bg-white rounded-md">
                                                                <thead className="bg-blue-100">
                                                                    <tr>            
                                                                        <th
                                                                            scope="col"
                                                                            className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider"
                                                                        >
                                                                            Date
                                                                        </th>                            
                                                                        <th
                                                                            scope="col"
                                                                            className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider"
                                                                        >
                                                                            Description
                                                                        </th>
                                                                        <th
                                                                            scope="col"
                                                                            className="px-6 py-3 text-right text-xs font-medium text-blue-500 uppercase tracking-wider"
                                                                        >
                                                                            Work Time
                                                                        </th>
                                                                        <th
                                                                            scope="col"
                                                                            className="px-6 py-3 text-right text-xs font-medium text-blue-500 uppercase tracking-wider"
                                                                        >
                                                                            Rate
                                                                        </th>
                                                                        <th
                                                                            scope="col"
                                                                            className="px-6 py-3 text-right text-xs font-medium text-blue-500 uppercase tracking-wider"
                                                                        >
                                                                            Total Earned
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className='divide-y divide-blue-200'>
                                                                    {workLogData && workLogData.map((workLog, index) => (
                                                                        <>                                                                    
                                                                            <tr key={'mini-'+index} colSpan={7}>
                                                                                <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium text-gray-900">
                                                                                    {workLog.dateWorked}
                                                                                </td>
                                                                                <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium text-gray-900">
                                                                                    {workLog.workDescription}
                                                                                </td>
                                                                                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium text-gray-500">
                                                                                    {(workLog.workHours / 60).toFixed(2)}
                                                                                </td>
                                                                                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium text-gray-500">
                                                                                    {formatMoney(workLog.workRate)}
                                                                                </td>
                                                                                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium text-gray-500">
                                                                                    {formatMoney(parseFloat(workLog.workHours).toFixed(2)/60 * parseFloat(workLog.workRate).toFixed(2))}
                                                                                </td>
                                                                            </tr>                                                                            
                                                                        </>                                                            
                                                                    ))}
                                                                    <tr>
                                                                        <td colSpan={4} className="px-6 py-4 whitespace-nowrap text-right text-base font-medium text-gray-900">
                                                                            Total:
                                                                        </td>
                                                                        <td colSpan={4} className="px-6 py-4 whitespace-nowrap text-right text-base font-bold text-gray-900">
                                                                            {formatMoney(workLogTotal)}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </>
                                            )}
                                        </>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>            
        </>
    );
}