import { Fragment, useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { updateUserInfo } from '../app/UserSlice';

import {
    ChevronRightIcon,
    CheckCircleIcon,
    XCircleIcon,
    ExclamationCircleIcon
} from '@heroicons/react/24/outline'

import { PlusCircleIcon } from '@heroicons/react/20/solid'

import Parse from 'parse';
import handleParseError from '../util/handleParseError';

import * as Constants from '../model/index';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


export default function TaxFilingOverview() {
    const userState = useSelector((state) => state.userState);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
    }, []);


    return (
        <>
            <div>
                <span className="font-bold">Important Filing Information:</span><br />
                In the United States, IRS forms 1099-NEC, 1099-MISC, etc. must be filed before the tax deadline to avoid penalties and other issues by the IRS.
                <br /><br />
                <span className="font-bold">Filing Deadlines:</span><br />
                <span className='underline font-bold'>Wednesday, January 31st, 2024</span> is the upcoming filing deadline for these forms. In order to provide you a seamless experience, 
                all workers must have completed on-boarding <span className="underline">before</span> this date which includes collecting tax information. Any workers that have not completed onboarding with your company will not be eligible to be filed via Qurbie for this tax year.
                <br /><br />
                <span className="font-bold">Before The Filing Deadline:</span><br />
                Qurbie will request you to enter any additional information and verify the amounts paid as well as the filing information &nbsp; 
                <span className='underline font-bold'>by January 1st, 2024</span>.
                <br/><br/>
                <span className="font-bold">Tax Season is Coming!</span><br />
                As we approach tax filing season, you will begin hearing from the team at Qurbie. We will provide updated information and this page will 
                change to reflect the tools available. To make yoru life eaiser at the end of the year, we highly reccomend you review your worker's tax 
                status on a regular basis. This can be accomplished via the <a href="/workers" className="font-bold underline text-blue-700">Workers</a> menu option.
            </div>
        </>
    );
}