import { Fragment, useState, useEffect } from 'react'
import { useParams, useNavigate } from "react-router-dom";

import { CheckCircleIcon } from '@heroicons/react/20/solid'

import { ReactComponent as QurbieLogo } from '../assets/Qurbie.svg'
import ModalOverlay from './ModalOverlay';


export default function SignUpWorker4Review() {
	let navigate = useNavigate();

	return (
		<>
			<div className="bg-white min-h-full w-1/2 flex flex-col justify-center p-20 mx-auto my-20 rounded-2xl">	

				<div className="sm:mx-auto sm:w-full sm:max-w-lg">
					
					<QurbieLogo className="mx-auto h-12 w-auto" />

					<div className="mt-6 flex justify-center items-center">
						<div>
							<div className="space-y-8 divide-y divide-gray-200 w-14 text-green-500 flex mr-2">
								<CheckCircleIcon />
							</div>
						</div>
						<div>
							<h2 className="text-left text-2xl font-extrabold text-gray-700">You Are All Set!</h2>
							<p className="text-left text-base text-gray-600">
								Welcome to Qurbie. You Are Now Ready to Begin Working.
							</p>
						</div>
					</div>					
				</div>

				<div className="mt-8 border-t border-b border-gray-200 px-4 py-5 sm:px-6 font-bold text-center">
					If you are new to Qurbie, we've put together a 3 minute tutorial on how easy it is to use. <br/><br/>

					<iframe width="100%" height="315" src="https://www.youtube.com/embed/9G1Hfb8oiUM?si=A4POLrAHy-0VM1aR" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
					<br/>

					Still have questions? <a href="https://qurbie.com/contact" className="text-blue-500">Reach Out!</a> We are here to help.
				</div>


				<form className="space-y-8 divide-y divide-gray-200">
					

					<div className="pt-10">
						<div className="flex justify-center">
							
							<button
								type="submit"
								className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
								onClick={e => {
									e.preventDefault(true);

									navigate("/", { replace: false });
								}}
							>
								Get Started
							</button>
						</div>
					</div>
				</form>
			</div>
		</>
	)
}