import { Fragment, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { changeClockTimeSaved } from '../app/ClockTimeSavedSlice';
import { changeGeneralState } from '../app/GeneralSlice';
import { Switch } from '@headlessui/react'
import Parse from 'parse';
import { loadSettings } from '../util/settingsStorage';
import { ChevronRightIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { current } from '@reduxjs/toolkit';
import ModalDialog from './ModalDialog';
import ModalIndeterminate from './ModalIndeterminate';
import { BellAlertIcon } from '@heroicons/react/20/solid';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function TimeLog(props) {    
    const [appSettings, setAppSettings] = useState({});
    const [selectedTab, setSelectedTab] = useState('Current');
    const [alert, setAlert] = useState({show: false, title: '', message: ''});
    const [progress, setProgress] = useState({show: false});

    const [workLog, setWorkLog] = useState(props.items?.filter(item => item.status === 'unpaid') || []);
    const [workLogPending, setWorkLogPending] = useState(props.items?.filter(item => item.status === 'requested') || []);
    const [workLogPaid, setWorkLogPaid] = useState(props.items?.filter(item => item.status === 'paid') || []);
    const [displayItems, setDisplayItems] = useState(workLog);
    const [editRowIndex, setEditRowIndex] = useState(null);    
    const [currentCount, setCurrentCount] = useState(workLog?.length || 0);
    const [pendingCount, setPendingCount] = useState(workLogPending?.length || 0);
    const [paidCount, setPaidCount] = useState(workLogPaid?.length || 0);

    const [editedWorkDescription, setEditedWorkDescription] = useState('');
    const [editedWorkHours, setEditedWorkHours] = useState(null);
    const [editedWorkDate, setEditedWorkDate] = useState(null);
    const [invoiceNumber, setInvoiceNumber] = useState(null);

    const [sendToCompany, setSendToCompany] = useState(true);

    const workLogItems = useSelector((state) => state.generalState.workRequestItems);    
    const workLogSelection = useSelector((state) => state.generalState.workLogSelection);         

    const dispatch = useDispatch();

    const calcSubtotal = () => {
        let subtotal = 0.0;

        displayItems.forEach((item, index) => {
            //console.log(`Item: ${(item.workHours.toFixed(2)/60).toFixed(2)} * ${item.workRate.toFixed(2)} = ${((item.workHours.toFixed(2) / 60).toFixed(2) * item.workRate).toFixed(2)}`);
            subtotal = subtotal + ((item.workHours.toFixed(2) / 60).toFixed(2) * item.workRate);
        });        

        return subtotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    // load settings
    useEffect(() => {
        setAppSettings(loadSettings());
        updateWorkLog(props.items);
        
    }, []);

    // update workLog if props.items changes
    useEffect(() => {
        updateWorkLog(props.items);        
    }, [props.items])

    useEffect(() => {
        selectedTab === 'Current' && setDisplayItems(workLog);
        selectedTab === 'Pending' && setDisplayItems(workLogPending);
        selectedTab === 'Paid' && setDisplayItems(workLogPaid); 

        if (selectedTab !== 'current') {
            setEditRowIndex(null);
        }
    }, [selectedTab]);

    const manuallyAddTime = () => {
        // dispatch to let the TimeLog know to display
        dispatch(changeClockTimeSaved({manualEntry: true}));
    }

    const updateWorkLog = (items) => {
        let updatedLog = items?.filter(item => item.status === 'unpaid') || [];
        let updatedLogPending = items?.filter(item => item.status === 'requested') || [];
        let updatedLogPaid = items?.filter(item => item.status === 'paid') || [];

        setWorkLog(updatedLog);
        setWorkLogPending(updatedLogPending);
        setWorkLogPaid(updatedLogPaid);

        setCurrentCount(updatedLog.length);
        setPendingCount(updatedLogPending.length);
        setPaidCount(updatedLogPaid.length);        

        setDisplayItems(updatedLog);
    }

    const calcRate = (item) => {        
        let finalResult = (item.workHours / 60).toFixed(2) * item.workRate;

        return finalResult;       
    }

    const shouldCheckItem = (id) => {
        const result = workLogItems?.includes(id) ? true : false;
        return result;
    }

    const toggleWorkLogItem = (id) => {
        if (workLogItems?.includes(id)) {
            // remove item
            let newLogItems = workLogItems.filter((item) => item !== id);
            console.log("NewLogItems:", newLogItems);
            dispatch(changeGeneralState({workLogSelection: true, workRequestItems: newLogItems}));
        } else {
            // add item
            dispatch(changeGeneralState({workLogSelection: true, workRequestItems: [...workLogItems, id]}));
        }
    }

    const markAllItemsAsChecked = () => {
        let workLogItems = [];
        workLog?.forEach((item) => {
            workLogItems.push(item.id);                                                        
        });
        dispatch(changeGeneralState({workLogSelection: true, workRequestItems: workLogItems}))
    }

    const toggleAllItems = () => {        
        if (workLogItems?.length === workLog.length ) {
            dispatch(changeGeneralState({workLogSelection: true, workRequestItems: []}));
        } else {
            markAllItemsAsChecked();
        }
    }

    const getWorkLogDateRange = () => {
        // get the first and last date from the work log
        let firstDate = new Date(workLog[0]?.updatedAt);
        let lastDate = new Date(workLog[workLog.length - 1]?.updatedAt);

        const options = { month: 'short', day: 'numeric' };

        const datePart1 = firstDate.toLocaleDateString('en-US', options);
        const timePart1 = firstDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
        
        const datePart2 = lastDate.toLocaleDateString('en-US', options);
        const timePart2 = lastDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
        
        const formattedDate = `${datePart1} ${timePart1} - ${datePart2} ${timePart2}`;

        return formattedDate;        
    }

    const confirmDelete = (itemId) => {   
        setAlert({
            show: true,
            title: 'Delete Item',
            message: 'Are you sure you want to delete this item?',
            okTitle: 'Delete',
            cancelTitle: 'Cancel',
            confirmButtonFunc: () => {
                setAlert({show: false});
                
                deleteLogItem(itemId);

                //console.log("Deleting Item:", item);                
            }
        });
    }

    const deleteLogItem = (logItemId) => {
        Parse.Cloud.run('DeleteWorkLogItem', {logItemId: logItemId}).then((result) => {
            setEditRowIndex(null);
            console.log('DeleteWorkLogItem Result:', result);
            dispatch(changeGeneralState({shouldUpdateWorkLog: true}));
        }).catch((error) => {
            console.log('Error with DeleteWorkLogItem:', error);
            setAlert({
                show: true,
                title: 'Problem Deleting Item',
                message: `Unable to delete the work log item. Please try again later. ${error}`,   
            });            
        });
    }

    const saveLogItemChanges = (logItemId) => {
        
        // Save log item changes
        console.log("Saving Log Item Changes:", logItemId);
        Parse.Cloud.run('UpdateWorkLogItem', {logItemId: logItemId, updatedWorkDate: editedWorkDate, updatedWorkDescription: editedWorkDescription, updatedWorkHours: editedWorkHours})
        .then((result) => {
            console.log('UpdateWorkLogItem Result:', result);
            setEditRowIndex(null);
            dispatch(changeGeneralState({shouldUpdateWorkLog: true}));
        }).catch((error) => {
            console.log('Error with UpdateWorkLogItem:', error);
            setAlert({
                show: true,
                title: 'Problem Saving Changes',
                message: `Unable to save the changes to the work log item. Please try again later. ${error}`,   
            });
        });        
    }

    const formatDateForControl = (dateString) => {        
        console.log("Formatting Date:", dateString);
        const d = new Date(dateString);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        const year = d.getFullYear();
    
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
    
        console.log("Formatted Date:", [year, month, day].join('-'));
        return [year, month, day].join('-');
    }

    const createPaymentRequest = () => {
        // 1. loop through all selected work log items                                                        
        // --> add up hours
        // --> add up hours * rate
        // 2. Create new Payment object and fill out fields
        // 3. Save the new payment object
        // 4. Saving triggers a request to go to primary business contact for payment
        // 5. --> Send email to business contact
        // 6. Show active payment requests below payment log
        // 7. Clear payment log items (i.e. mark as 'requested')
        // 8. Tell user what is going on / next steps!                                              
        if (workLogItems.length < 1) {
            console.log("Unable to call GetWorkLogHours - no work log items");                                                            
        } else if (!appSettings.accountCompany) {
            console.log("Unable to call GetWorkLogHours - no account company");
        } else {
            setProgress({show: true, title: 'Requesting Payment', message: 'Please wait while we process your request.'});                                                            

            Parse.Cloud.run('CreatePaymentRequest', {
                invoiceNumber: parseInt(invoiceNumber), 
                workLogIds: workLogItems, 
                businessId: appSettings.accountCompany.id,
                sendToCompany: sendToCompany
            }).then((result) => {		
                console.log("Create Payment Request Result:", result);

                dispatch(changeGeneralState({shouldUpdateWorkLog: true, workLogSelection: false}));
                setProgress({show: false});
                
                setTimeout(() => {
                    setAlert({show: true, title: 'Payment Request Sent', message: 'Your payment request has been sent to the business contact. You will receive an email confirmation shortly.'});
                }, 200);
            })
            .catch((error) => {
                setProgress({show: false});
                if (error.code === 209) { // invalid session
                    // token expired
                    console.log("Create Payment Request User Login Expired");
                    //Parse.User.logOut().then(()=> navigate('/sign-in', {replace: true}));
                } else {
                    console.log("Error with Create Payment Request:", error);
                }
            });		
        }
    }

    return (
        <>
            <ModalDialog show={alert.show || false} title={alert.title} message={alert.message}
				confirmButtonTitle={alert.okTitle || "Ok"} cancelButtonTitle={alert.cancelTitle} icon={<BellAlertIcon className="text-blue-500" />}
				confirmButtonFunc={alert.confirmButtonFunc || (() => setAlert({showAlert:false}))} />

            <ModalIndeterminate show={progress.show} title={progress.title || ''} message={progress.message || ''} />
	

            <div className="px-4 sm:px-6 lg:px-0">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-xl font-semibold text-gray-900">{Parse.User.current().get('firstName')}'s Work Log {appSettings?.accountCompany ? `for ${appSettings.accountCompany.businessName}` : ''}</h1>
                        <p className="mt-2 text-sm text-gray-700">
                            {workLog?.length < 1 ? 
                                (<>For work completed starting today.</>)
                                :
                                (<>For work completed between <strong>{getWorkLogDateRange()}</strong>.</>)
                            }
                        </p>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        <button
                            type="button"
                            className="bg-white mr-4 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            onClick={() => manuallyAddTime()}
                        >
                            Manually Add Time
                        </button>
                        
                    </div>
                </div>
                <div className="-mx-4 mt-8 flex flex-col sm:-mx-6 md:mx-0">
                    {/* Tabs */}
                    <div>
                        <div className="sm:hidden">
                            <label htmlFor="tabs" className="sr-only">
                                Select a tab
                            </label>
                            {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                            <select
                                id="tabs"
                                name="tabs"
                                className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                defaultValue={'Current'}
                            >
                                <option>Current</option>
                                <option>Pending Payment</option>
                                <option>Paid</option>
                                <option>All</option>
                            </select>
                        </div>
                        <div className="hidden sm:block">
                            <div className="border-b border-gray-200">
                                <nav className="-mb-px flex space-x-8" aria-label="Tabs">

                                    {/* Current Tab */}
                                    <div
                                        className={classNames(
                                            selectedTab === 'Current'
                                                ? 'border-blue-500 text-blue-600'
                                                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                                            'cursor-pointer whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm'
                                        )}
                                        aria-current={selectedTab === 'Current' ? 'page' : undefined}
                                        onClick={() => setSelectedTab('Current')}
                                    >
                                        Current
                                        {currentCount ? (
                                            <span
                                                className={classNames(
                                                    selectedTab === 'Current' ? 'bg-indigo-100 text-blue-600' : 'bg-gray-100 text-gray-900',
                                                    'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                                                )}
                                            >
                                                {currentCount}
                                            </span>
                                        ) : null}
                                    </div>

                                    {/* Pending Tab */}
                                    <div 
                                        className={classNames(
                                            selectedTab === 'Pending'
                                                ? 'border-blue-500 text-blue-600'
                                                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                                            'cursor-pointer whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm'
                                        )}
                                        aria-current={selectedTab === 'Pending' ? 'page' : undefined}
                                        onClick={() => {
                                            setSelectedTab('Pending');                                            
                                        }}
                                    >
                                        Pending Payment
                                        {pendingCount ? (
                                            <span
                                                className={classNames(
                                                    selectedTab === 'Pending' ? 'bg-indigo-100 text-blue-600' : 'bg-gray-100 text-gray-900',
                                                    'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                                                )}
                                            >
                                                {pendingCount}
                                            </span>
                                        ) : null}
                                    </div>

                                    {/* Paid Tab */}
                                    <div
                                        className={classNames(
                                            selectedTab === 'Paid'
                                                ? 'border-blue-500 text-blue-600'
                                                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                                            'cursor-pointer whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm'
                                        )}
                                        aria-current={selectedTab === 'Paid' ? 'page' : undefined}
                                        onClick={() => setSelectedTab('Paid')}
                                    >
                                        Total Paid
                                        {paidCount ? (
                                            <span
                                                className={classNames(
                                                    selectedTab === 'Paid' ? 'bg-indigo-100 text-blue-600' : 'bg-gray-100 text-gray-900',
                                                    'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                                                )}
                                            >
                                                {paidCount}
                                            </span>
                                        ) : null}
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                    {/* --- */}
                    <div className="mt-5 flex flex-col">
                        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                {workLogSelection &&
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider"
                                                    >
                                                        <input
                                                            id="comments"
                                                            aria-describedby="comments-description"
                                                            name="comments"
                                                            type="checkbox"
                                                            className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                                                            checked={workLogItems?.length === workLog.length ? true : false}
                                                            onChange={() => toggleAllItems()}
                                                        />
                                                        
                                                    </th>
                                                }
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider">
                                                    {/* space for edit button */}
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider"
                                                >
                                                    Work Description
                                                </th>                                                
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider"
                                                >
                                                    Date
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider"
                                                >
                                                    Hours
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider"
                                                >
                                                    Rate
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-10 py-3 text-right text-xs font-medium text-blue-500 uppercase tracking-wider"
                                                >
                                                    Total
                                                </th>                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {displayItems.length < 1 &&
                                                <tr>
                                                    <td colSpan={workLogSelection ? 6 : 5} className="px-6 py-4 text-left text-sm font-medium text-gray-900">
                                                        No work log items available.
                                                    </td>
                                                </tr>
                                            }
                                            
                                            {/* Due Date Title Owner Revenue Costs Profitability Status */}                                            
                                            {displayItems.map((logItem, index) => (
                                                <>
                                                    {index !== editRowIndex &&
                                                        <tr key={''+logItem.id+'-'+logItem.status} className={index % 2 === 0 ? 'bg-white' : 'bg-blue-50'}>
                                                            {workLogSelection &&
                                                                <td className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider">
                                                                    <input
                                                                        id="comments"
                                                                        aria-describedby="comments-description"
                                                                        name="comments"
                                                                        type="checkbox"                                                                
                                                                        className={classNames(logItem.status !== 'unpaid' ? 'bg-gray-200' : '', "h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600")}
                                                                        disabled={logItem.status !== 'unpaid'}
                                                                        checked={shouldCheckItem(logItem.id)}
                                                                        onChange={() => toggleWorkLogItem(logItem.id)}
                                                                    />                                                            
                                                                </td>
                                                            }

                                                            {/* Edit Button only availabe in current tab */}
                                                            {selectedTab === 'Current' ? 
                                                                <td 
                                                                    className="px-3 py-4 text-left text-xs font-medium text-blue-400 cursor-pointer"
                                                                    onClick={()=> {
                                                                        setEditRowIndex(index);
                                                                    }}>
                                                                        Edit                                                        
                                                                </td> 
                                                                :
                                                                <td></td> 
                                                            }
                                                            
                                                            <td className="px-6 py-4 text-left text-sm font-medium text-gray-900">{logItem.workDescription}</td>                                                         
                                                            <td className="py-4 px-6 text-left text-sm text-gray-500 sm:table-cell">{logItem ? new Date(logItem.dateWorked).toLocaleDateString() : 'n/a'}</td>
                                                            <td className="py-4 px-6 text-left text-sm text-gray-500 sm:table-cell">{(logItem.workHours / 60).toFixed(2)}</td>
                                                            <td className="py-4 px-6 text-left text-sm text-gray-500 sm:table-cell">{Number( Math.round( logItem.workRate+'e2' ) + 'e-2' ).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td> 
                                                            <td className="py-4 pl-3 px-10 text-right text-sm text-gray-500 font-semibold">{calcRate(logItem).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>                                                                                                    
                                                        </tr>
                                                    }

                                                    {/* edit item view */}
                                                    {index === editRowIndex &&
                                                        <tr>
                                                            <td colSpan={6} className="px-6 py-4 text-left text-sm font-medium text-gray-900">
                                                                <div>
                                                                    <div className="flex justify-between">
                                                                        <label htmlFor="workDescription" className="block text-sm font-medium text-gray-700">
                                                                            Work Description
                                                                        </label>
                                                                        <span className="text-sm text-gray-500" id="workDescription-description">
                                                                            Max 500 characters
                                                                        </span>
                                                                    </div>
                                                                    <textarea
                                                                        id="workDescription"
                                                                        name="workDescription"
                                                                        rows={3}
                                                                        className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                                        defaultValue={logItem.workDescription}
                                                                        onChange={(e) => setEditedWorkDescription(e.target.value)}
                                                                    />
                                                                </div>

                                                                <div className="mt-4 flex justify-evenly">

                                                                    {/* Work Hours */}
                                                                    <div>
                                                                        <div className="flex justify-between">
                                                                            <label htmlFor="workHours" className="block text-sm font-medium text-gray-700">
                                                                                Work Hours
                                                                            </label>                                                                
                                                                        </div>
                                                                        <input
                                                                            type="number"
                                                                            name="workHours"
                                                                            id="workHours"
                                                                            className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                                            defaultValue={logItem.workHours}
                                                                            onChange={(e) => setEditedWorkHours(parseInt(e.target.value))}
                                                                        />  
                                                                    </div>

                                                                    {/* Work Date */}
                                                                    <div>
                                                                        <div className="flex justify-between">
                                                                            <label htmlFor="workDate" className="block text-sm font-medium text-gray-700">
                                                                                Work Date
                                                                            </label>                                                                
                                                                        </div>
                                                                        <input
                                                                            type="date"
                                                                            name="workDate"
                                                                            id="workDate"
                                                                            className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                                            defaultValue={formatDateForControl(logItem.dateWorked)}
                                                                            onChange={(e) => {
                                                                                const selectedDate = new Date(e.target.value);
                                                                                selectedDate.setMinutes(selectedDate.getMinutes() + selectedDate.getTimezoneOffset()); // Adjust for timezone
                                                                                setEditedWorkDate(selectedDate);
                                                                            }}
                                                                        />
                                                                    </div>

                                                                </div>
                                                                
                                                                <div className="mt-10 flex justify-between">
                                                                    <div className='flex justify-start'>                                                        
                                                                        <button
                                                                            type="button"
                                                                            className="ml-1 inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto"
                                                                            onClick={() => {
                                                                                saveLogItemChanges(logItem.id);
                                                                            }}>
                                                                            Save Changes
                                                                        </button>

                                                                        <button
                                                                            type="button"
                                                                            className="ml-6 inline-flex items-center justify-center rounded-md border border-transparent bg-gray-300 px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto" 
                                                                            onClick={() => {
                                                                                setEditRowIndex(null);
                                                                            }}>
                                                                            Cancel
                                                                        </button>
                                                                    </div>
                                                                    
                                                                    <button
                                                                        type="button"
                                                                        className="ml-6 inline-flex items-center justify-center rounded-md border border-transparent px-4 py-2 text-xs font-medium text-red-600  hover:text-red-700  sm:w-auto"
                                                                        onClick={() => {
                                                                            confirmDelete(logItem.id);
                                                                        }}>
                                                                        Delete Item
                                                                    </button>                                                            
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    } 
                                                </>
                                            ))}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th
                                                    scope="row"
                                                    colSpan={workLogSelection ? 6 : 5}
                                                    className="hidden pl-6 pr-3 pt-6 text-right text-sm font-normal text-gray-500 sm:table-cell md:pl-0"
                                                >
                                                    Subtotal:
                                                </th>
                                                <th scope="row" className="pl-4 pr-3 pt-6 text-left text-sm font-normal text-gray-500 sm:hidden">
                                                    Subtotal:
                                                </th>
                                                <td className="pl-3 px-10 pt-6 text-right text-sm text-gray-500">{calcSubtotal()}</td>
                                            </tr>
                                            <tr>
                                                <th
                                                    scope="row"
                                                    colSpan={workLogSelection ? 6 : 5}
                                                    className="hidden pl-6 pr-3 pt-4 pb-8 text-right text-lg font-semibold text-gray-900 sm:table-cell md:pl-0"
                                                >
                                                    Total:
                                                </th>
                                                <th scope="row" className="pl-4 pr-3 pt-4 pb-8 text-left text-lg font-semibold text-gray-900 sm:hidden">
                                                    Total:
                                                </th>
                                                <td className="pl-3 px-10 pt-4 pb-8 text-right text-lg font-semibold text-gray-900">
                                                    {calcSubtotal()}
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                    {selectedTab === 'Current' &&
                                        <>
                                            <div className="flex justify-end">
                                                {workLogSelection ?
                                                    <>
                                                        <input
                                                            id="invoiceNum"
                                                            aria-describedby="invoice-number"
                                                            name="invoiceNum"
                                                            type="number"
                                                            placeholder='Set Invoice #'
                                                            className="mr-4 mb-4 w-36 font-medium rounded border-gray-300 text-gray-700"                                                        
                                                            checked={workLogItems?.length === workLog.length ? true : false}
                                                            onChange={(e) => setInvoiceNumber(e.target.value)}
                                                        />
                                                        <button
                                                            type="button"
                                                            className="mr-4 mb-4 inline-flex items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto"
                                                            onClick={(e) => {
                                                                e.preventDefault(true);
                                                                dispatch(changeGeneralState({workLogSelection: false}));
                                                            }}
                                                        >
                                                            Cancel Request
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="mr-4 mb-4 inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto"
                                                            onClick={(e) => {
                                                                e.preventDefault(true);

                                                                createPaymentRequest();                                                                
                                                            }}
                                                        >
                                                            Confirm Work Log
                                                        </button>
                                                    </>
                                                    :
                                                    <>
                                                        <button
                                                            type="button"
                                                            className="mr-4 mb-4 inline-flex items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto"
                                                            onClick={() => manuallyAddTime()}
                                                        >
                                                            Manually Add Time
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="mr-4 mb-4 inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto"
                                                            onClick={(e) => {
                                                                e.preventDefault(true);

                                                                if (!displayItems || displayItems.length < 1) {
                                                                    setAlert({
                                                                        show: true,
                                                                        title: 'No Time Work Logged',
                                                                        message: 'Please add to your work log before creating an invoice.'
                                                                    });
                                                                    return;
                                                                }

                                                                if (workLogSelection) {
                                                                    dispatch(changeGeneralState({workLogSelection: false}));
                                                                } else {
                                                                    // Add all items to the list                                                    
                                                                    markAllItemsAsChecked();
                                                                }
                                                            }}
                                                        >
                                                            Create Invoice
                                                        </button>
                                                    </>
                                                }
                                            </div>                                            

                                            {workLogSelection && 
                                                <div className="mx-4 mb-4 flex justify-end items-stretch">
                                                    
                                                    <div className="mr-4 text-md font-small text-gray-900 text-left">
                                                        Send Payment Request to Company                                                        
                                                    </div>

                                                    <Switch
                                                        checked={sendToCompany}
                                                        onChange={setSendToCompany}
                                                        className={classNames(
                                                            sendToCompany ? 'bg-blue-600' : 'bg-gray-400',
                                                            'relative inline-flex flex-shrink-0 h-6 w-10 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                                                        )}>
                                                        <span
                                                            aria-hidden="true"
                                                            className={classNames(
                                                                sendToCompany ? 'translate-x-4' : 'translate-x-0',
                                                                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                                                            )} />
                                                    </Switch>
                                                </div>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}