
import { Fragment, useState, useEffect } from 'react'
import { useParams, useNavigate } from "react-router-dom";

import { ReactComponent as QurbieLogo } from '../assets/Qurbie.svg'
import ModalOverlay from './ModalOverlay';

import CheckImage from '../assets/check-sm.jpg';
import { ReactComponent as StripeLogo } from '../assets/StripeLogo.svg'

const progressList = [
	{ id: '1', name: 'Create Account', href: '#', status: 'current' },
	{ id: '2', name: 'Setup Payment', href: '#', status: 'upcoming' },
	{ id: '3', name: 'Setup Tax Info', href: '#', status: 'upcoming' },
	{ id: '4', name: 'Start Working', href: '#', status: 'upcoming' },
]

export default function SignUpWorker2PayInfo() {
	const [showProgress, setShowProgress] = useState(false);
	let navigate = useNavigate();

	return (
		<>
			<div className="bg-white min-h-full w-3/5 flex flex-col justify-center p-20 mx-auto my-20 rounded-2xl">	

				<div className="sm:mx-auto sm:w-full sm:max-w-md">
					<QurbieLogo className="mx-auto h-12 w-auto" />
					<h2 className="mt-6 text-center text-2xl font-extrabold text-gray-700">Get Paid Faster with Direct Deposit</h2>
				</div>

				<form className="space-y-8 divide-y divide-gray-200">
					<div className="space-y-8 divide-y divide-gray-200">
						<div className="pt-8">
							
							<div>
								<h3 className="text-lg leading-6 font-medium text-gray-900">Setup Direct Deposit</h3>
								<div className="mt-1 text-sm text-gray-500">
									In order to get paid quickly and securely, we need to know where to send your money. By connecting your financial institution, you will typically receive all funds directly depostited within 1-3 days.
									<br/>
									For security purposes and to prevent fraud, we also need to verify your identity during this step. 
									<br/>
									<br/>
									<strong>In the next step, you will need the following information:</strong>
									<ul className="list-disc ml-8">
										<li>Your Bank Account Information (Login or Account/Routing Numbers)</li>
										<li>Your Personal (SSN) or Business Tax Identifier (TIN/EIN)</li>
										<li>Your Name or Business Name</li>
										<li>General Information About Your Business</li>
										<li>Drivers License or Government ID</li>
									</ul>				
									<br/>
									<div className='md:visible invisible text-gray-900 flex flex-row flex-wrap items-center'>
										Qurbie partners with <a href="https://stripe.com/docs/security" rel="noreferrer" target="_blank"><StripeLogo className="w-16" /></a> to provide secure financial services. <a href="https://stripe.com/docs/security" className="ml-1 text-sm font-bold text-blue-600">Learn More</a>
									</div>								
								</div>
							</div>
							
						</div>
					</div>

					<div className="pt-5">
						<div className="flex justify-end">
							<button
								type="button"
								className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
							>
								Skip for Now
							</button>
							<button
								type="submit"
								className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
								onClick={e => {
									e.preventDefault(true);

									setShowProgress(true);

									// TODO: call create account 
									setTimeout(()=> {
										// navigate("/worker-signup/tax-info", { replace: true });
										console.log("Connect fiancnial account");
									}, 2000);
								}}
							>
								Connect Financial Account
							</button>
						</div>
					</div>
				</form>				
			</div>
			{showProgress &&
				<ModalOverlay show progress title="Preparing Your Account..." message="" buttonTitle="" />
			}

		</>
	)
}