// Goal is to have the user select the account type and then display the correct information
import { BriefcaseIcon, BuildingOffice2Icon } from '@heroicons/react/20/solid';
import { Fragment, useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";

import Parse from 'parse';
import { ReactComponent as QurbieLogo} from '../assets/Qurbie.svg'
import { saveSettings, loadSettings, clearSettings } from '../util/settingsStorage';



export default function SelectAccountType(props) {
	let navigate = useNavigate();
    const [companies, setCompanies] = useState([]);  
    const [selectedCompany, setSelectedCompany] = useState('');
    const [selectedType, setSelectedType] = useState('');

    const getCompanies = async () => {
        try {
            const results = await Parse.Cloud.run("GetUserAccounts", {});
            setCompanies(results);
            console.log("Results:", results);

            // if only one type, save it and move on
            if (results.length === 1) {
// TODO: re-add this before launch
//                handleSelectAccountType(results[0]);
            }
        } catch (error) {
            console.log("Error getting companies", error);
        }
        
    }

    const handleSelectAccountType = (company) => {
        console.log("Selected Company:", company);
        setSelectedCompany(company);
        setSelectedType(company.type);

        saveSettings({accountCompany: company, accountType: company.type});

        navigate('/dashboard');
    }

    useEffect(() => {
        //clearSettings();
        loadSettings();
        getCompanies();
    }, []);


    return (
        <>
            <div className="py-0">
                <div className="my-8 sm:mx-auto sm:w-full sm:max-w-md">
					<QurbieLogo className="mx-auto h-12 w-auto" />
				</div>

                <div className="mx-auto max-w-7xl px-6 text-center lg:px-8">
                    <div className="mx-auto max-w-2xl">
                        <h3 className="text-3xl font-bold tracking-tight sm:text-4xl">What Are You Working On Today?</h3>
                        <p className="mt-4 text-lg leading-8 text-gray-500">
                            You have a company account and you are a worker on other accounts. Select which account type you want to interact with. You can switch accounts at any time.
                        </p>
                    </div>
                    
                    <ul className="mt-8 mx-auto grid max-w-2xl grid-cols-1 gap-6 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:gap-8">
                        {companies.map((company) => (
                            <li key={company.id} 
                                className="rounded-2xl  px-8 py-10 shadow-lg hover:bg-blue-50 cursor-pointer"
                                onClick={()=>{
                                    handleSelectAccountType(company);
                                }}>
                                {company.logo ?
                                    <img alt="" src={company.logo} className="mx-auto h-48 w-48 rounded-full md:h-48 md:w-48" />
                                    :
                                    <div className="mx-auto h-32 w-32 text-blue-300 rounded-full md:h-32 md:w-32">
                                        {company.type === 'company' ? 
                                            <BuildingOffice2Icon className="mx-auto" />
                                            :
                                            <BriefcaseIcon className="mx-auto" />
                                        }
                                    </div>
                                    
                                }
                                
                                <h3 className="mt-2text-base font-semibold leading-7 tracking-tight">{company.businessName}</h3>
                                <h4 className="text-base text-gray-600 leading-7 tracking-tight">{company.type === 'company' && 'Company Account'}{company.type === 'worker' && 'Worker Account'}</h4>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="mt-16 sm:mx-auto sm:w-full sm:max-w-md">
					<p className="mt-2 text-center text-sm text-gray-600">
						<a href="/sign-out" className="font-medium text-blue-400 hover:text-blue-500">
							Sign Out
						</a>					
					</p>
				</div>
                
            </div>
        </>
    )
}




