import { Fragment, useState, useEffect } from 'react'
import { useParams, useNavigate } from "react-router-dom";
import { Listbox, Transition, Switch, Dialog } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon, ClipboardDocumentListIcon, PaperClipIcon } from '@heroicons/react/20/solid'
import { PencilSquareIcon, TrashIcon, PlusIcon, ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { ReactComponent as QurbieLogo} from '../../assets/Qurbie.svg'
import NumberFormat from 'react-number-format'
import DropdownWorkers from './DropdownWorkers'
import Divider from '../Divider'
import { DateTime } from 'luxon'
import ModalOverlay from '../ModalOverlay';
import { StateOptions, CountryOptions } from '../../model/Constants';

const steps = [
	{ id: 'Step 1', name: 'Review Work', href: '#', status: 'current' },
	{ id: 'Step 2', name: 'Accept or Reject', href: '#', status: 'upcoming' },
	{ id: 'Step 3', name: 'Finalize', href: '#', status: 'upcoming' },
]

const workRequestData = {
	id: "MRNTTwv8vn",
	title: 'Duffek Website Refresh',
	description: "The Duffek website must be overhauled and better represent the Duffek brand as well as it's capabilities.",	
	dueDate: DateTime.now().plus({weeks: 1}),
	status: 'Proposed',	
	tasks: [
		{ id: '1', title: 'Complete Design', description: 'Finish designing the interface of the application.' },
		{ id: '2', title: 'Implement Interface', description: 'Implement the designs and build the UI in Tailwind CSS using the Tailwind UI components to make this process faster.' },
		{ id: '3', title: 'Develop MVP', description: 'Build out each component one at a time and test them to ensure they are working correctly. Then put them together in one nice designed web application.' },
	],
	workers: [
		{ id: 2, name: 'Chelsea Bill', rate: 65.00, estHours: 22, type: 'Designer' },
	],
	requester: {
		businessName: 'Duffek Mobile',
		firstName: 'David',
		lastName: 'Nelson',
		email: 'david@duffekmoile.com',
		phone: '5555555555'
	}
}

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

function checkIfAccountExists() {
	// TODO: Make Parse.Cloud call to check if worker's email is already in the system
	// for now doing a simple debug
	//return true;
	return false;
}

export default function WorkRequest1Review(props) {	
	const [workRequest, setWorkRequest] = useState(null);
	const [open, setOpen] = useState(false);
	const [showNextOverlay, setShowNextOverlay] = useState(false)
	let params = useParams();
	let navigate = useNavigate();


	// Scroll to top on first load
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	// get work request data from server
	// TODO: change to Parse server request
	useEffect( () => {
		if (params.workRequestId == "MRNTTwv8vn") {
			return setWorkRequest(workRequestData)
		}
		
	}, [params.workRequestId]);

	return(
		<>
			<div className="lg:max-w-4xl ml-auto mr-auto pb-20 pt-20">
				<div className="bg-white overflow-hidden shadow-lg sm:rounded-lg text-left rounded-lg">
					<div className="px-4 py-5 sm:p-6">																								
						{/* Review of Project */}
						<div>
							<QurbieLogo className="w-48 mt-10 ml-auto mr-auto" />
							
							{!workRequest && 
								<>
									<div className="px-4 py-5 sm:px-6 mx-auto mb-20 text-center">
										<h3 className="text-xl leading-6 font-semibold text-gray-900">Work Request is No Longer Available</h3>
										<p className="mt-1 text-lg text-gray-500">This work reqest is no longer available because it was accepted, rejected, or has expired.<br/>Please contact the requesting party for more information.</p>
									</div>
								</>
							}

							{workRequest && 
								<>
									<div className="px-4 py-5 sm:px-6 ml-auto mr-auto text-center">
										<h3 className="text-xl leading-6 font-semibold text-gray-900">{workRequest.requester.businessName} is Requesting Your Help on a Project</h3>
										<p className="mt-1 text-lg text-gray-500">Please review the project details below and accept or reject the work request.</p>
									</div>

									{/* Progress Bar */}
									<div className="mt-10 mb-10 lg:ml-20 lg:mr-20">
										<nav aria-label="Progress">
											<ol className="space-y-4 md:flex md:space-y-0 md:space-x-8">
												{steps.map((step) => (
													<li key={step.name} className="md:flex-1">
														{step.status === 'complete' ? (
															<a href={step.href} className="group pl-4 py-2 flex flex-col border-l-4 border-green-600 hover:border-green-800 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4">
																<span className="text-xs text-green-600 font-semibold tracking-wide uppercase group-hover:text-green-800">
																	{step.id} | {step.name}
																</span>
																{/* <span className="text-sm font-medium text-green-600">{step.name}</span> */}
															</a>
														) : step.status === 'current' ? (
															<a href={step.href} className="pl-4 py-2 flex flex-col border-l-4 border-blue-600 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4" aria-current="step">
																<span className="text-xs text-blue-600 font-semibold tracking-wide uppercase">{step.id} | {step.name}</span>
																{/* <span className="text-sm font-medium">{step.name}</span> */}
															</a>
														) : (
															<a href={step.href} className="group pl-4 py-2 flex flex-col border-l-4 border-gray-200 hover:border-gray-300 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4">
																<span className="text-xs text-gray-500 font-semibold tracking-wide uppercase group-hover:text-gray-700">
																	{step.id} | {step.name}
																</span>
																{/* <span className="text-sm font-medium">{step.name}</span> */}
															</a>
														)}
													</li>
												))}
											</ol>
										</nav>
									</div>


									{/* Project Details */}
									<div className="border-t border-gray-200 px-4 py-5 sm:px-6">
										<dt className="text-lg font-medium text-gray-900 mb-5">Project Details</dt>
										<dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
											<div className="sm:col-span-full">
												<dt className="text-sm font-medium text-gray-500">Project Title</dt>
												<dd className="mt-1 text-base text-gray-900">{workRequest.title}</dd>
											</div>
											<div className="sm:col-span-full">
												<dt className="text-sm font-medium text-gray-500">Project Description</dt>
												<dd className="mt-1 text-base text-gray-900">{workRequest.description}</dd>
											</div>
											<div className="sm:col-span-1">
												<dt className="text-sm font-medium text-gray-500">Estimated Hours Needed</dt>
												<dd className="mt-1 text-2xl font-bold text-blue-500">{workRequest.workers[0].estHours}</dd>
											</div>
											
											<div className="sm:col-span-1">
												<dt className="text-sm font-medium text-gray-500">Proposed Rate</dt>
												<dd className="mt-1 text-2xl font-bold text-blue-500">
													<NumberFormat 
														value={workRequest.workers[0].rate} 
														displayType={'text'} 
														thousandSeparator={true} 
														decimalScale={2} 
														fixedDecimalScale 
														prefix={'$'} /><span className="text-sm">/hr</span>
												</dd>
											</div>

											<div className="sm:col-span-1">
												<dt className="text-sm font-medium text-gray-500">Project Work Estimate</dt>
												<dd className="mt-1 text-2xl font-bold text-blue-500">
													<NumberFormat 
														value={workRequest.workers[0].estHours * workRequest.workers[0].rate} 
														displayType={'text'} 
														thousandSeparator={true} 
														decimalScale={2} 
														fixedDecimalScale 
														prefix={'$'} />
												</dd>
											</div>									

											<div className="sm:col-span-1">
												<dt className="text-sm font-medium text-gray-500">Estimated Due Date</dt>
												<dd className="mt-1 text-2xl font-bold text-blue-500">{workRequest.dueDate.toLocaleString({month: 'short', day: 'numeric', year: 'numeric'})}</dd>
											</div>
										</dl>
									</div>


									<div className="border-t border-gray-200 px-4 py-5 sm:px-6">
										<dt className="text-lg font-medium text-gray-900">Estimated Tasks / Work to be Done</dt>
										<p className="mt-1 text-base text-gray-500">Below is a listing of tasks or potential work to be completed. Please review and 
											<a href="#contact" className="text-blue-700"> contact the requester</a> if you have an questions.
										</p>

										<div className="sm:col-span-full">
											{/* Project Task List */}
											<div className="mt-10 mb-10">
												<div className="flow-root">
													<ul className="-mb-8">
														{workRequest.tasks.map((task, taskIndex) => (
															<li key={task.id}>
																<div className="relative pb-8">
															
																	{taskIndex !== workRequest.tasks.length - 1 ? (
																		<span className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
																	) : null}

																	<div className="relative flex justify-between">
																		<div className="relative flex items-start space-x-3">
																			<div className="relative w-10 h-10 ring-2 bg-white ring-blue-500 rounded-full flex justify-center items-center">
																				<ClipboardDocumentListIcon 
																					className="h-6 w-6 text-blue-500 text-center" />
																			</div>
																			<div className="min-w-0 flex-1">
																				<div>
																					<div className="text-md font-semibold text-gray-600">																		
																						{task.title}																		
																					</div>
																					<p className="mt-0.5 text-sm text-gray-500">{task.description}</p>
																				</div>
																			</div>
																		</div>																
																	</div>
																</div>
															</li>
														))}
													</ul>
												</div>
											</div>
										</div>
									</div>
											

									{/* Client Information */}
									<div className="border-t border-gray-200 px-4 py-5 sm:px-6">
										<dt className="text-lg font-medium text-gray-900 mb-5">Company Requesting Your Work</dt>
										<dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
											<div className="sm:col-span-full">
												<dt className="text-sm font-medium text-gray-500">Business Name</dt>
												<dd className="mt-1 text-sm text-gray-900">{workRequest.requester.businessName}</dd>
											</div>
											<div className="sm:col-span-1">
												<dt className="text-sm font-medium text-gray-500">Contact First Name</dt>
												<dd className="mt-1 text-sm text-gray-900">{workRequest.requester.firstName}</dd>
											</div>
											<div className="sm:col-span-1">
												<dt className="text-sm font-medium text-gray-500">Contact Last Name</dt>
												<dd className="mt-1 text-sm text-gray-900">{workRequest.requester.lastName}</dd>
											</div>
											<div className="sm:col-span-1">
												<dt className="text-sm font-medium text-gray-500">Phone Number</dt>
												<dd className="mt-1 text-sm text-gray-900">
													<NumberFormat 
														value={workRequest.requester.phone} 
														displayType={'text'} 
														format="(###) ###-####"/>											
												</dd>
											</div>
											<div className="sm:col-span-1">
												<dt className="text-sm font-medium text-gray-500">Contact Email</dt>
												<dd className="mt-1 text-sm text-gray-900">{workRequest.requester.email}</dd>
											</div>
										</dl>
									</div>


									{/* Bottom Buttons */}
									<div className="pt-10 mb-5">
										<div className="flex justify-end">
											<button
												type="button"
												className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
												onClick={() => setOpen(true)}>
												Reject Request
											</button>
											<button
												type="submit"
												className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
												onClick={()=> {													
													setShowNextOverlay(true);
													setTimeout( ()=> {
														if (checkIfAccountExists()) {
															navigate("accepted", { replace: false });
														} else {
															navigate("../worker-signup", { replace: false });
														}
													},3000);
												}}>
												Accept Request
											</button>
										</div>
									</div>
								</> 
							}
							
						</div>
					</div>
				</div>			
			</div>

			{/* Modal PopUp */}
			<Transition.Root show={open} as={Fragment}>
				<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setOpen}>
					<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
								<div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
									<button
										type="button"
										className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
										onClick={() => setOpen(false)}
									>	
										<span className="sr-only">Close</span>
										<XMarkIcon className="h-6 w-6" aria-hidden="true" />
									</button>
								</div>
								<div className="sm:flex sm:items-start">
									<div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
										<ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
									</div>
									<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
										<Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
											Reject Project
										</Dialog.Title>
										<div className="mt-2">
											<p className="text-sm text-gray-500">
												Please confirm that you do not accept this project and enter a reason for your rejection.
											</p>
										</div>
										<div className="mt-2">
											<textarea
                  								id="reason"
                  								name="reason"
                  								rows={3}
                  								className="max-w-lg shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-md"
                  								defaultValue={''}
                							/>
										</div>
									</div>
								</div>
								<div className="mt-5 sm:mt-4 sm:flex sm:justify-end">
									
									<button
										type="button"
										className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
										onClick={() => setOpen(false)}
									>
										Cancel
									</button>
									
									<button
										type="button"
										className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
										onClick={() => setOpen(false)}
									>
										Reject Project
									</button>
								</div>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition.Root>
			
			{showNextOverlay &&
				<ModalOverlay show progress title="Loading..." message="Accepting and loading" buttonTitle="Cancel" />
			}
		</>
	)
}