import { Fragment, useEffect, useState } from 'react'

import {
	ChevronRightIcon,
    UsersIcon,
    CurrencyDollarIcon,
    CheckCircleIcon,
    XCircleIcon,
    ExclamationCircleIcon
} from '@heroicons/react/24/outline'

import { ArrowSmallUpIcon, ArrowSmallDownIcon, PlusCircleIcon } from '@heroicons/react/20/solid'

import NumberFormat from 'react-number-format'

import * as Constants from '../model/index';
import { Link } from 'react-router-dom';

import Parse from 'parse';
import handleParseError from '../util/handleParseError';

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}


export default function DashboardWorkerStats(props) {
    const [workerStats, setWorkerStats] = useState(null);

    useEffect(() => {
        getWorkerStats();
    }, []);


    const getWorkerStats = () => {
        Parse.Cloud.run('GetWorkerStats', {}).then((result) => {
            console.log('Worker Stats:', result);
            setWorkerStats(result);
        }).catch((error) => {
            handleParseError(error);
        });
    }    


    const formatMoney = (value) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
        }).format(value);
    }


    return (
        <>        
            {/* Team Stats */}
            <div>
                <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                    {/* Total Companies */}
                    <div className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
                        <dt>
                            <div className="absolute bg-blue-500 rounded-md p-3">
                                <UsersIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </div>
                            <p className="ml-16 text-sm font-medium text-left text-gray-500 truncate">Total Companies</p>
                        </dt>
                        <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                            <p className="text-2xl font-semibold text-gray-900">

                            {workerStats?.totalCompanies || 'N/A'}
                            
                            </p>
                            {/* Show % Change */}
                            {/* <p
                                className={classNames(
                                    'text-green-600', // : 'text-red-600',
                                    'ml-2 flex items-baseline text-sm font-semibold'
                                )}>
                                    <ArrowSmallUpIcon className="self-center flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
                                    <ArrowSmallDownIcon className="self-center flex-shrink-0 h-5 w-5 text-red-500" aria-hidden="true" />
                                    10%
                                </p> 
                            \*/}

                            {/* <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
                                <div className="text-sm text-left">
                                    <a href="#teamDeets" className="font-bold text-blue-600 hover:text-blue-500">
                                        {' '}
                                        Change Company
                                    </a>
                                </div>
                            </div> */}
                        </dd>
                    </div>

                    {/* Pay Amount */}
                    <div className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
                        <dt>
                            <div className="absolute bg-blue-500 rounded-md p-3">
                                <CurrencyDollarIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </div>
                            <p className="ml-16 text-sm font-medium text-left text-gray-500 truncate">Total Earned</p>
                        </dt>
                        <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                            <p className="text-2xl font-semibold text-gray-900">
                            
                                {workerStats?.totalEarned > 0 ? formatMoney(workerStats.totalEarned) : formatMoney(0)}

                            </p>
                            
                            {/* <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
                                <div className="text-sm text-left">
                                    <a href="/invoice-history" className="font-bold text-blue-600 hover:text-blue-500">
                                        {' '}
                                        View Details
                                    </a>
                                </div>
                            </div> */}
                        </dd>
                    </div>

                    {/* Pending Payments */}
                    <div className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
                        <dt>
                            <div className="absolute bg-blue-500 rounded-md p-3">
                                <CurrencyDollarIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </div>
                            <p className="ml-16 text-sm font-medium text-left text-gray-500 truncate">Pending Payments</p>
                        </dt>
                        <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                            <p className="text-2xl font-semibold text-gray-900">
                            
                            {workerStats?.totalPending > 0 ? formatMoney(workerStats.totalPending) : 'N/A'}
                            
                            </p>
                            
                            {/* <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
                                <div className="text-sm text-left">
                                    <a href="/invoice-history" className="font-bold text-blue-600 hover:text-blue-500">
                                        {' '}
                                        View Details
                                    </a>
                                </div>
                            </div> */}
                        </dd>
                    </div>        
                </dl>
            </div>
        </>
    );
}