import React from 'react';
import { createRoot } from 'react-dom/client';
import { store } from './app/store'
import { Provider } from 'react-redux'

import './index.css';

import reportWebVitals from './reportWebVitals';

import {
	BrowserRouter,
	Routes,
	Route
} from "react-router-dom";

import Parse from 'parse';

import QurbieDash from './views/QurbieDash';
import SignIn from './components/SignIn';
import SignInForgottenPassword from './components/SignInForgottenPassword';
import Dashboard from './views/OldDashboard';
import WorkRequest1Review from './components/Old/WorkRequest1Review';
import WorkRequestAccept1 from './components/Old/WorkRequestAccept1';

import SignUpAccount from './components/SignUpAccount';
//import SignUp2BusinessInfo from './components/OldSignUp2BusinessInfo';
//import SignUp3Verify from './components/OldSignUp3Verify';
//import SignUp4Completed from './components/Old';

import SignUpWorker1Account from './components/SignUpWorker1Account';
import SignUpWorker2PayInfo from './components/SignUpWorker2PayInfo';
import SignUpWorker3TaxInfo from './components/SignUpWorker3TaxInfo';
import SignUpWorker4Review from './components/SignUpWorker4Review';

import GettingStartedDash from './views/GettingStartedDash';
import Invoice from './components/Invoice';

import PaymentRequest from './components/PaymentRequest';
import PayProto from './views/PayProto';
import PayAccountDemo from './views/PayAccountDemo';
import AccountReauth from './components/AccountReauth';
import AccountStatus from './components/AccountStatus';
import SignUpGuide from './components/SignUpGuide';
import WorkerStatus from './components/WorkerStatus';
import SelectAccountType from './components/SelectAccountType';

Parse.initialize("qurbie");
Parse.serverURL = "/parse";

const container = document.getElementById('root');
const root = createRoot(container);
//root.render(<App tab="home" />);

root.render(
	<React.StrictMode>
		<Provider store={store}>			
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<SignIn />} />
      				
					<Route path="/sign-in" element={<SignIn />} />
					<Route path="/select-account" element={<SelectAccountType />} />
					<Route path="/forgotten-password" element={<SignInForgottenPassword />} />
					<Route path="/forgot-password" element={<SignInForgottenPassword />} />
					
					<Route path="/sign-up" element={<SignUpAccount />} />
					{/* <Route path="/sign-up/business-info" element={<SignUp2BusinessInfo />} /> */}
					{/* <Route path="/sign-up/business-info/verify" element={<SignUp3Verify />} /> */}
					{/* <Route path="/verified" element={<SignUp4Completed />} /> */}

					<Route path="/dashboard" element={<QurbieDash dashboardView />} />
					<Route path="/payments" element={<QurbieDash paymentsView />} />
					<Route path="/workers" element={<QurbieDash teamView />} />					
					<Route path="/taxes" element={<QurbieDash taxes />} />
					
					<Route path="/request-pay" element={<QurbieDash workerPayRequest />} />
					<Route path="/payments-history" element={<QurbieDash paymentsHistory />} />
					<Route path="/invoice-history" element={<QurbieDash invoiceHistory />} />

					<Route path="/invoice" element={<Invoice />} />

					<Route path="/profile" element={<QurbieDash profileView />} />
					<Route path="/settings" element={<QurbieDash settingsView />} />
					<Route path="/help" element={<QurbieDash helpView />} />

					<Route path="/add-worker" element={<QurbieDash addWorkerView />} />
					<Route path="/add-worker/add-worker-review" element={<QurbieDash addWorkerReviewView />} />										

					<Route path="/worker-signup" element={<SignUpWorker1Account />}>
						<Route path=":requestId" element={<SignUpWorker1Account />}/>
					</Route>
					
					<Route path="/worker-signup/pay-info" element={<SignUpWorker2PayInfo />} />
					<Route path="/worker-signup/tax-info" element={<SignUpWorker3TaxInfo />} />
					<Route path="/worker-signup/review" element={<SignUpWorker4Review />} />

					<Route path="/worker-status" element={<WorkerStatus />}>
						<Route path=":workerId" element={<WorkerStatus />} />
					</Route>
					
					<Route path="/payment-request" element={<PaymentRequest />}>
						<Route path=":requestId" element={<PaymentRequest />} />
					</Route>

					{/* Old Items */}
					<Route path="projects" element={<Dashboard projectsView />} />
					<Route path="invoices" element={<Dashboard invoicesView />} />
					<Route path="new-project" element={<Dashboard newProjectView />} />

					<Route path="/work-request" element={<WorkRequest1Review />}>
						<Route path=":workRequestId" element={<WorkRequest1Review />} />						
					</Route>

					<Route path="/work-request/new-account" element={<SignUpWorker1Account />} />
					<Route path="/work-request/accepted" element={<WorkRequestAccept1 />} />

					<Route path="/getting-started" element={<GettingStartedDash start />} />
					<Route path="/getting-started/business-info" element={<GettingStartedDash businessInfo />} />
					{/* <Route path="/getting-started/banking-info" element={<GettingStartedDash bankingInfo />} /> */}
					<Route path="/getting-started/verify-info" element={<GettingStartedDash verifyInfo />} />
					
					<Route path="/reauth" element={<AccountReauth />} />
					<Route path="/stripe-reauth" element={<AccountReauth />} />
					<Route path="/account-reauth" element={<AccountReauth />} />
					<Route path="/account-status" element={<AccountStatus />} />


					{/* Testing Payment Prototype */}
					<Route path="/pay-proto" element={<PayProto />} />
					<Route path="/pay-demo" element={<PayAccountDemo />} />
					
					<Route path="/signupguide" element={<SignUpGuide />} />

    			</Routes>
			</BrowserRouter>
		</Provider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
