import { Fragment, useState, useEffect } from 'react'
import { useParams, useNavigate } from "react-router-dom";
import SignatureCanvas from 'react-signature-canvas'
import Parse from 'parse';
import { ReactComponent as QurbieLogo } from '../assets/Qurbie.svg'
import ModalOverlay from './ModalOverlay';
import ModalDialog from './ModalDialog';
import ModalIndeterminate from './ModalIndeterminate';
import { BellIcon } from '@heroicons/react/24/outline';

const progressList = [
	{ id: '1', name: 'Create Account', href: '#', status: 'complete' },
	{ id: '2', name: 'Setup Payment', href: '#', status: 'complete' },
	{ id: '3', name: 'Setup Tax Info', href: '#', status: 'current' },
	{ id: '4', name: 'Start Working', href: '#', status: 'upcoming' },
]

export default function SignUpWorker3TaxInfo() {
	const [alert, setAlert] = useState({showAlert: false});
	const [progress, setProgress] = useState({show: false});

	const [workerId, setWorkerId] = useState(localStorage.getItem('workerSignUpId'));
	const [signaturePad, setSignaturePad] = useState();

	const [name, setName] = useState('');	
	const [businessName, setBusinessName] = useState('');
	
	const [ckbxIndividual, setCkbxIndividual] = useState(false);
	const [ckbxSoleP, setCkbxSoleP] = useState(false);
	const [ckbxSingleMemberLLC, setCkbxSingleMemberLLC] = useState(false);	
	const [ckbxCcorp, setCkbxCcorp] = useState(false);
	const [ckbxScorp, setCkbxScorp] = useState(false);
	const [ckbxPartnership, setCkbxPartnership] = useState(false);
	const [ckbxTrustEstate, setCkbxTrustEstate] = useState(false);
	const [ckbxLLC, setCkbxLLC] = useState(false);
	const [ckbxLLCClass, setCkbxLLCClass] = useState('');
	const [ckbxOther, setCkbxOther] = useState(false);
	const [ckbxOtherDetails, setCkbxOtherDetails] = useState('');
	
	const [exemptCode, setExemptCode] = useState('');
	const [fatcaCode, setFatcaCode] = useState('');
	const [address, setAddress] = useState('');
	const [cityStateZip, setCityStateZip] = useState('');
	const [accountNumbers, setAccountNumbers] = useState('');
	const [requestersNameAddress, setRequestersNameAddress] = useState('');
	const [ssn, setSsn] = useState('');
	const [ein, setEin] = useState('');
	const [agreeEsign, setAgreeEsign] = useState(false);	
	const [signDate, setSignDate] = useState();
	const [clientIp, setClientIp] = useState();

	let navigate = useNavigate();

	useEffect(() => {
		getUsersIp();
		setSignDate(getCurrentDateString());
	}, []);

	const getUsersIp = async () => {

		fetch('https://geolocation-db.com/json/').then((result) => {
			result.json().then(jsonData => {
				//console.log("Json Data:", jsonData);
				if (jsonData.IPv4) {
					setClientIp(jsonData.IPv4);
					console.log("Client IP:", jsonData.IPv4);
				}
			})
		});
	}

	const deselectAll = () => {
		setCkbxIndividual(false);
		setCkbxSoleP(false);
		setCkbxSingleMemberLLC(false);
		setCkbxCcorp(false);
		setCkbxScorp(false);
		setCkbxPartnership(false);
		setCkbxTrustEstate(false);
		setCkbxLLC(false);
		setCkbxOther(false);		
	}

	const getCurrentDateString = () => {
		// Get the current date
		const currentDate = new Date();

		// Get the month, day, and year from the current date
		// Note: getMonth() returns 0-11, so we add 1 to get 1-12 for months
		const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Convert to string and ensure 2 digits
		const day = String(currentDate.getDate()).padStart(2, '0'); // Convert to string and ensure 2 digits
		const year = currentDate.getFullYear();

		// Combine them in the MM/DD/YYYY format
		const formattedDate = `${month}/${day}/${year}`;

		// Display the formatted date
		return formattedDate;
	}

	const getTaxClass = () => {
		if (ckbxIndividual) return "Individual";
		if (ckbxSoleP) return "Sole Proprietor";
		if (ckbxSingleMemberLLC) return "Single-Member LLC";
		if (ckbxCcorp) return "C Corporation";
		if (ckbxScorp) return "S Corporation";
		if (ckbxPartnership) return "Partnership";
		if (ckbxTrustEstate) return "Trust/Estate";
		if (ckbxLLC) return `Limited liability company (${ckbxLLCClass})`;
		if (ckbxOther) return ckbxOtherDetails;			
	}

	const handleSaveTaxInfo = () => {
		if (!ckbxCcorp && !ckbxIndividual && !ckbxLLC && !ckbxOther && !ckbxPartnership && !ckbxScorp && !ckbxSingleMemberLLC && !ckbxSoleP && !ckbxTrustEstate) {
			setAlert({ showAlert: true, title: 'Tax Classification Required', message: "Please select a tax classification."});
			return;
		}

		if (!name || name.length <= 0) {
			setAlert({ showAlert: true, title: 'Name Required', message: "Please enter your name."});
			return;
		}

		if (!address || address.length <= 0) {
			setAlert({ showAlert: true, title: 'Address Required', message: "Please enter your address."});
			return;
		}

		if (!cityStateZip || cityStateZip.length <= 0) {
			setAlert({ showAlert: true, title: 'City, State, Zip Required', message: "Please enter your city, state, and zip code."});
			return;
		}

		if (!ssn && !ein) {
			setAlert({ showAlert: true, title: 'SSN or EIN Required', message: "Please enter your Social Security Number or Employer Identification Number."});
			return;
		}			

		if (!agreeEsign) {
			setAlert({ showAlert: true, title: 'Agreement Required', message: "Please agree to the terms and conditions."});
			return;
		}

		if (!signaturePad || signaturePad.isEmpty()) {
			setAlert({ showAlert: true, title: 'Signature Required', message: "Please sign the form."});
			return;
		}

		if (!workerId) {
			setAlert({ showAlert: true, title: 'Something Went Wrong', message: "Please click on the invitation and try again. Worker Id Error 100."});
			return;
		}

		// create a new TaxInfo Object and attach it to the worker object
		const newTaxInfo = new Parse.Object("TaxInfo");		
		newTaxInfo.set("name", name);
		newTaxInfo.set("businessName", businessName);
		newTaxInfo.set("address", address);
		newTaxInfo.set("cityStateZip", cityStateZip);
		newTaxInfo.set("ssn", ssn);
		newTaxInfo.set("ein", ein);
		newTaxInfo.set("exemptCode", exemptCode);
		newTaxInfo.set("fatcaCode", fatcaCode);
		newTaxInfo.set("accountNumbers", accountNumbers);
		newTaxInfo.set("requestersNameAddress", requestersNameAddress);
		newTaxInfo.set("taxClass", getTaxClass());
		newTaxInfo.set("signature", signaturePad.getTrimmedCanvas().toDataURL('image/png'));
		newTaxInfo.set("signDate", signDate);
		newTaxInfo.set("clientIp", clientIp);
		newTaxInfo.set("agreeEsign", agreeEsign);

		const workerPointer = new Parse.Object("Workers");
		workerPointer.set("objectId", workerId);
		workerPointer.set("status", "active");
		workerPointer.set("");
		newTaxInfo.set("worker", workerPointer);

		setProgress({ show: true, title: 'Saving Tax Info' });
		newTaxInfo.save().then((taxInfo) => {
			console.log("Tax Info Saved:", taxInfo);			

			// navigate to the next step
			setProgress({ show: false });
			navigate('/worker-signup/review', { replace: true });
			console.log("tax info saved!");
			
		}).catch(error => {
			setProgress({show: false});
			console.log("Error Saving Tax Info:", error);
			setTimeout(() => 
				setAlert({ showAlert: true, title: 'Error Saving Tax Info', message: 'Unable to save tax information. Please try again.'}), 
			250);
			
		});
	
	}

	return (
		<>
			<ModalDialog show={alert.showAlert || false} title={alert.title} message={alert.message}
				confirmButtonTitle={alert.okTitle || "Ok"} cancelButtonTitle={alert.cancelTitle} icon={<BellIcon className="text-blue-500" />}
				confirmButtonFunc={() => setAlert({showAlert:false})} />

			<ModalIndeterminate show={progress.show} title={progress.title || ''} message={progress.message || ''} />

			<div className="bg-white min-h-full w-full px-2 md:px-8 lg:px-32 xl:px-64 flex flex-col justify-center mx-auto my-20 rounded-2xl">
				<div className="sm:mx-auto sm:w-full sm:max-w-md">
					<QurbieLogo className="mx-auto h-12 w-auto" />
					<h2 className="mt-6 text-center text-2xl font-extrabold text-gray-700">Setup Tax Information</h2>
					<p className="mt-2 mb-0 text-center text-sm text-gray-600">
						Your employer needs your tax information to provide you with end of the year documentation (Form 1099) so you can complete your taxes. You can return to Qurbie.com anytime you need your previous tax forms.
					</p>
				</div>
				
				<div className="w-full">

					{/* Progress Bar */}
					<div className="mt-8 mb-10 lg:ml-20 lg:mr-20">
						<nav aria-label="Progress">
							<ol className="space-y-4 md:flex md:space-y-0 md:space-x-8">
								{progressList.map((step) => (
									<li key={step.name} className="md:flex-1">
										{step.status === 'complete' ? (
											<a href={step.href} className="group pl-4 py-2 flex flex-col border-l-4 border-green-600 hover:border-green-800 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4">
												<span className="text-xs text-green-600 font-semibold tracking-wide uppercase group-hover:text-green-800">
													{step.id} | {step.name}
												</span>
												{/* <span className="text-sm font-medium text-green-600">{step.name}</span> */}
											</a>
										) : step.status === 'current' ? (
											<a href={step.href} className="pl-4 py-2 flex flex-col border-l-4 border-blue-600 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4" aria-current="step">
												<span className="text-xs text-blue-600 font-semibold tracking-wide uppercase">{step.id} | {step.name}</span>
												{/* <span className="text-sm font-medium">{step.name}</span> */}
											</a>
										) : (
											<a href={step.href} className="group pl-4 py-2 flex flex-col border-l-4 border-gray-200 hover:border-gray-300 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4">
												<span className="text-xs text-gray-500 font-semibold tracking-wide uppercase group-hover:text-gray-700">
													{step.id} | {step.name}
												</span>
												{/* <span className="text-sm font-medium">{step.name}</span> */}
											</a>
										)}
									</li>
								))}
							</ol>
						</nav>
					</div>
				</div>

				<form className="space-y-8 divide-y divide-gray-200 p-1 border border-gray-300">
					<div className="space-y-8 divide-y divide-gray-200">
						<div className="pt-0">

							{/* <div>
								<h3 className="text-lg leading-6 font-medium text-gray-900">Fill Out Your W9</h3>
								<p className="mt-1 text-sm text-gray-500">Enter your information below for your employer's records</p>
							</div> */}

							{/* Document Title */}
							<div className="my-6 grid grid-cols-1 gap-y-6 gap-x-0 sm:grid-cols-6">
								<div className="col-span-1 text-xs border-black border-r-2 border-b-2">
									Form<span className="text-3xl font-bold">W-9</span><br />
									(Rev. October 2018)<br />
									Department of the Treasury<br />
									Internal Revenue Service<br />
								</div>

								<div className="col-span-4 text-center border-black border-r-2 border-b-2">
									<span className="text-2xl font-bold">Request for Taxpayer <br />Identification Number and Certification</span>
									<br /><span className="font-bold">▶ Go to <a href="https://www.irs.gov/FormW9" className="text-blue-500">www.irs.gov/FormW9</a> for instructions<br />and the latest information.</span>
								</div>

								<div className="col-span-1 border-black border-b-2 text-xs text-right flex">
									<p className="my-auto">This Form will be Sent to the Requester<br /><br />Do Not Send to the IRS</p>
								</div>
							</div>

							{/* Name and Basic Input */}
							<div className="my-6 grid grid-cols-1 gap-y-6 gap-x-0 sm:grid-cols-6 p-2">
								<div className="col-span-full text-xs ">
									<label htmlFor="name" className="block text-sm font-medium text-gray-700">
										1 Name (as shown on your income tax return). Name is required on this line; do not leave this line blank.
									</label>
									<div className="mt-1">
										<input
											id="name"
											name="name"
											type="text"
											autoComplete="name"
											className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
											value={name}
											onChange={e => setName(e.target.value)}
										/>
									</div>
								</div>

								<div className="col-span-full text-xs ">
									<label htmlFor="name" className="block text-sm font-medium text-gray-700">
										2 Business name/disregarded entity name, if different from above
									</label>
									<div className="mt-1">
										<input
											id="businessName"
											name="businessName"
											type="text"
											autoComplete="businessName"
											className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
											value={businessName}
											onChange={e => setBusinessName(e.target.value)}
										/>
									</div>
								</div>

								<div className="col-span-4 text-xs pr-2">
									<label htmlFor="classifications" className="block text-sm font-medium text-gray-700">
										3 Check appropriate box for federal tax classification of the person whose name is entered on line 1. Check only one of the following seven boxes.
									</label>
									<div className="mt-1">
										<div className="my-6 grid grid-cols-1 gap-y-1 gap-x-0 sm:grid-cols-6">
											<div className="col-span-2 text-xs ">
												<input
													id="comments"
													aria-describedby="comments-description"
													name="comments"
													type="checkbox"
													className="h-4 w-4 text-blue-600 border-gray-300 rounded"
													checked={ckbxIndividual}
													onChange={e => {				
														deselectAll();										
														setCkbxIndividual(e.target.checked);														
													}}
												/>
												<span className="ml-2">Individual</span>
											</div>

											<div className="col-span-2 text-xs ">
												<input
													id="comments"
													aria-describedby="comments-description"
													name="comments"
													type="checkbox"
													className="h-4 w-4 text-blue-600 border-gray-300 rounded"
													checked={ckbxSoleP}
													onChange={e => {				
														deselectAll();										
														setCkbxSoleP(e.target.checked);														
													}}
												/>
												<span className="ml-2">Sole Proprietor</span>
											</div>

											<div className="col-span-2 text-xs ">
												<input
													id="comments"
													aria-describedby="comments-description"
													name="comments"
													type="checkbox"
													className="h-4 w-4 text-blue-600 border-gray-300 rounded"
													checked={ckbxSingleMemberLLC}
													onChange={e => {				
														deselectAll();										
														setCkbxSingleMemberLLC(e.target.checked);														
													}}
												/>
												<span className="ml-2">Single-Member LLC</span>
											</div>

											<div className="col-span-2 text-xs ">
												<input
													id="comments"
													aria-describedby="comments-description"
													name="comments"
													type="checkbox"
													className="h-4 w-4 text-blue-600 border-gray-300 rounded"
													checked={ckbxCcorp}
													onChange={e => {														
														deselectAll();
														setCkbxCcorp(e.target.checked);
													}}
												/>
												<span className="ml-2">C Corporation</span>
											</div>

											<div className="col-span-2 text-xs ">
												<input
													id="comments"
													aria-describedby="comments-description"
													name="comments"
													type="checkbox"
													className="h-4 w-4 text-blue-600 border-gray-300 rounded"
													checked={ckbxScorp}
													onChange={e => {
														deselectAll();
														setCkbxScorp(e.target.checked);
													}}
												/>
												<span className="ml-2">S Corporation</span>
											</div>

											<div className="col-span-2 text-xs ">
												<input
													id="comments"
													aria-describedby="comments-description"
													name="comments"
													type="checkbox"
													className="h-4 w-4 text-blue-600 border-gray-300 rounded"
													checked={ckbxPartnership}
													onChange={e => {
														deselectAll();
														setCkbxPartnership(e.target.checked);
													}}
												/>
												<span className="ml-2">Partnership</span>
											</div>

											<div className="col-span-2 text-xs ">
												<input
													id="comments"
													aria-describedby="comments-description"
													name="comments"
													type="checkbox"
													className="h-4 w-4 text-blue-600 border-gray-300 rounded"
													checked={ckbxTrustEstate}
													onChange={e => {
														deselectAll();
														setCkbxTrustEstate(e.target.checked);
													}}
												/>
												<span className="ml-2">Trust/Estate</span>
											</div>
										</div>

										<div className="my-6 grid grid-cols-1 gap-y-1 gap-x-0 sm:grid-cols-6">
											<div className="col-span-6 text-xs ">
												<div className="flex">
													<div>
														<input
															id="comments"
															aria-describedby="comments-description"
															name="comments"
															type="checkbox"
															className="h-4 w-4 text-blue-600 border-gray-300 rounded"
															checked={ckbxLLC}
															onChange={e => {
																deselectAll();
																setCkbxLLC(e.target.checked);
															}}
														/>
														<span className="ml-2">Limited liability company. Enter the tax classification</span><br />
														<span className="ml-6">(C=C corporation, S=S corporation, P=Partnership)</span>
													</div>
													<div className='flex justify-center my-auto w-8'>▶</div>
													<div>
														<input
															id="taxClass"
															name="taxClass"
															type="text"
															className='w-20 h-8 border-gray-300 rounded'
															value={ckbxLLCClass}
															onChange={e => {
																if (e.target.value?.toUpperCase() === 'C' || e.target.value?.toUpperCase() === 'S' || e.target.value?.toUpperCase() === 'P') {
																	setCkbxLLCClass(e.target.value.toUpperCase());
																} else {
																	setCkbxLLCClass('');
																}
															}}
														/>
													</div>
												</div>
											</div>
											<div className="col-span-full">
												<p className="ml-6 text-xs"><span className="font-bold">Note:</span> Check the appropriate box in the line above for the tax classification of the single-member owner. Do not check LLC if the LLC is classified as a single-member LLC
													that is disregarded from the owner unless the owner of the LLC is another LLC that is not disregarded from the owner for U.S. federal tax purposes. Otherwise, a single-member
													LLC that is disregarded from the owner should check the appropriate box for the tax classification of its owner.</p>
											</div>
											<div className="col-span-full text-xs ">
												<input
													id="other"
													aria-describedby="other-description"
													name="other"
													type="checkbox"
													className="h-4 w-4 text-blue-600 border-gray-300 rounded"
													checked={ckbxOther}
													onChange={e => {
														deselectAll();
														setCkbxOther(e.target.checked);
													}}
												/>
												<span className="ml-2">Other (see instructions) ▶
													<input
														id="taxClass"
														name="taxClass"
														type="text"
														className='w-80 h-8 ml-2 border-gray-300 rounded'
														value={ckbxOtherDetails}
														onChange={e => {
															setCkbxOtherDetails(e.target.value);
														}}
													/>
												</span>

											</div>
										</div>
									</div>
								</div>

								{/* Exemptions */}
								<div className="col-span-2 text-sm font-medium text-gray-700 ">
									4 Exemptions<br />
									<span className="text-xs">(codes apply only to certain entities, not individuals; <a href="#w9instructions">see instructions on page 3</a>):</span><br />
									<div className="ml-4 mt-4 text-xs">
										<p>Exempt payee code (if any)</p>
										<input
											id="exemptCode"
											name="exemptCode"
											type="text"
											className='w-auto h-8 border-gray-300 rounded'
											value={exemptCode}
											onChange={e => setExemptCode(e.target.value)}
										/>
									</div>

									<div className="ml-4 mt-4 text-xs">
										<p>Exemption from FATCA reporting code<br />(if any)</p>
										<input
											id="fatcaCode"
											name="fatcaCode"
											type="text"
											className='w-auto h-8 border-gray-300 rounded'
											value={fatcaCode}
											onChange={e => setFatcaCode(e.target.value)}
										/>
									</div>

									<div className="text-xs mx-4">(Applies to accounts maintained outside the U.S.)</div>
								</div>

								<div className="col-span-4 text-xs pr-4">
									<label htmlFor="address" className="block text-sm font-medium text-gray-700">
										5 Address (number, street, and apt. or suite no.) See instructions.
									</label>
									<div className="mt-1">
										<input
											id="address"
											name="address"
											type="text"
											autoComplete="address"
											className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
											value={address}
											onChange={e => setAddress(e.target.value)}
										/>
									</div>
								</div>

								<div className="h-full col-span-2 row-span-3 text-xs ">
									<label htmlFor="address" className="block text-sm font-medium text-gray-700">
										Requester's name and address (optional)
									</label>
									<div className="mt-1">
										<textarea
											id="address"
											name="address"
											autoComplete="address"
											rows="10"
											className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
											value={requestersNameAddress}
											onChange={e => setRequestersNameAddress(e.target.value)}
										/>
									</div>
								</div>

								<div className="col-span-4 text-xs pr-4">
									<label htmlFor="cityStateZip" className="block text-sm font-medium text-gray-700">
										6 City, state, and ZIP code
									</label>
									<div className="mt-1">
										<input
											id="cityStateZip"
											name="cityStateZip"
											type="text"
											autoComplete="cityStateZip"
											className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
											value={cityStateZip}
											onChange={e => setCityStateZip(e.target.value)}
										/>
									</div>
								</div>

								<div className="col-span-4 text-xs pr-4">
									<label htmlFor="accountNumbers" className="block text-sm font-medium text-gray-700">
										7 List account number(s) here (optional)
									</label>
									<div className="mt-1">
										<input
											id="accountNumbers"
											name="accountNumbers"
											type="text"
											autoComplete="accountNumbers"
											className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
											value={accountNumbers}
											onChange={e => setAccountNumbers(e.target.value)}
										/>
									</div>
								</div>
							</div>

							{/* Taxpayer Identification Number */}
							<div className="my-6 grid grid-cols-1 gap-y-6 gap-x-0 sm:grid-cols-6 p-2">

								<div className="col-span-1 text-base font-bold text-center bg-black text-white border-black border-2">
									Part I
								</div>

								<div className="col-span-5 text-base font-bold bg-white border-black border-2 pl-4">
									Taxpayer Identification Number (TIN)
								</div>

								<div className="col-span-4 text-xs mr-4">
									Enter your TIN in the appropriate box. The TIN provided must match the name given on line 1 to avoid
									backup withholding. For individuals, this is generally your social security number (SSN). However, for a
									resident alien, sole proprietor, or disregarded entity, see the instructions for Part I, later. For other
									entities, it is your employer identification number (EIN). If you do not have a number, see How to get a TIN, later.
									<br /><br />
									<b>Note:</b> If the account is in more than one name, see the instructions for line 1. Also see What Name and Number To Give
									the Requester for guidelines on whose number to enter.
								</div>

								<div className="h-full col-span-2 row-span-3 text-xs ">
									<label htmlFor="address" className="block text-sm font-bold text-gray-700">
										Social Security Number
									</label>
									<div className="mt-1">
										<input
											id="socialNumber"
											name="socialNumber"
											autoComplete="socialNumber"
											placeholder='000-00-0000'
											type="text"
											className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-base border-gray-300 rounded-md"
											value={ssn}
											onChange={e => setSsn(e.target.value)}
										/>
									</div>
									<div className='col-span-full text-base font-bold m-4'>
										or
									</div>
									<label htmlFor="address" className="block text-sm font-bold text-gray-700">
										Employer Identification Number
									</label>
									<div className="mt-1">
										<input
											id="ein"
											name="ein"
											autoComplete="ein"
											placeholder='00-0000000'
											type="text"
											className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-base border-gray-300 rounded-md"
											value={ein}
											onChange={e => setEin(e.target.value)}
										/>
									</div>
								</div>								

							</div>

							{/* Certification */}
							<div className="my-6 grid grid-cols-1 gap-y-6 gap-x-0 sm:grid-cols-6 p-2">
								<div className="col-span-1 text-base font-bold text-center bg-black text-white border-black border-2">
									Part II
								</div>

								<div className="col-span-5 text-base font-bold bg-white border-black border-2 pl-4">
									Certification
								</div>

								<div className="col-span-full text-sm ml-4">
									<b>Under Penalties of Perjury, I Certify That:</b><br />
									<div className="ml-4">
										<b>1.</b> The number shown on this form is my correct taxpayer identification number<br />(or I am waiting for a number to be issued to me); and<br />
										<b>2.</b> I am not subject to backup withholding because: (a) I am exempt from backup withholding, or (b) I have not been notified by the Internal Revenue
										Service (IRS) that I am subject to backup withholding as a result of a failure to report all interest or dividends, or (c) the IRS has notified me that I am
										no longer subject to backup withholding; and<br />
										<b>3.</b> I am a U.S. citizen or other U.S. person (defined below); and<br />
										<b>4.</b> The FATCA code(s) entered on this form (if any) indicating that I am exempt from FATCA reporting is correct. <br /><br />
									</div>
									<div>
										<b>Certification instructions</b><br />
										You must cross out item 2 above if you have been notified by the IRS that you are currently subject to backup withholding because
										you have failed to report all interest and dividends on your tax return. For real estate transactions, item 2 does not apply. For mortgage interest paid,
										acquisition or abandonment of secured property, cancellation of debt, contributions to an individual retirement arrangement (IRA), and generally, payments
										other than interest and dividends, you are not required to sign the certification, but you must provide your correct TIN. See the instructions for Part II, later.
									</div>

								</div>
							</div>

							
							<div className="my-6 p-4 flex border-2 border-black">
								<div className="flex items-center h-5">
									<input
										id="esig-consent"
										aria-describedby="comments-description"
										name="esign-consent"
										type="checkbox"
										className="focus:ring-blue-500 h-5 w-5 text-blue-600 border-black rounded"
										value={agreeEsign}
										onChange={e => setAgreeEsign(e.target.checked)}
									/>
								</div>
								<div className="ml-3 text-sm">
									<label htmlFor="comments" className="font-bold text-gray-700">
										I agree to sign this form electronically.
									</label>										
								</div>
							</div>

							{/* Certification */}
							<div className="my-6 grid grid-cols-1 gap-y-6 gap-x-0 sm:grid-cols-12 border-2 border-black">
								<div className="col-span-1 text-base font-bold text-center">
									Sign Here
								</div>

								<div className="col-span-8 text-base font-bold text-center p-1">									
									<div className='bg-gray-200 rounded-md'>
										<SignatureCanvas penColor='black'
											canvasProps={{ width: 500, height: 100, className: 'sigCanvas' }} 
											ref={(ref) => { setSignaturePad(ref); }}											
										/>
									</div>

								</div>

								<div className="col-span-3 text-xs font-bold flex justify-center">
									<div className="flex">
										<div className='flex justify-center my-auto mx-2 w-16'>Date ▶</div>
										<div className='flex my-auto pr-2'>
											{/* <input
												id="taxClass"
												name="taxClass"
												type="text"
												placeholder='__/__/____'
												className='w-full h-full border-gray-300 rounded'
											/> */}
											{getCurrentDateString()}
										</div>
									</div>
								</div>
							</div>
							<div className="text-center text-sm">Signer's IP Address at Time of Signature: {clientIp || ''}</div>
						</div>						
					</div>

					<div className="pt-5">
						<div className="flex justify-end">
							<button
								type="button"
								className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
								onClick={e => navigate(-1)}
							>
								Go Back
							</button>
							<button
								type="submit"
								className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
								onClick={e => {
									e.preventDefault(true);
																		
									handleSaveTaxInfo();									
								}}
							>
								Submit W9
							</button>
						</div>
					</div>

					<div className='mt-8 pt-8 flex justify-between'>
						<div className="text-2xl font-bold mr-3">Need Additional Help?</div>
						<a href="https://www.irs.gov/pub/irs-pdf/fw9.pdf" target="_blank" rel="noreferrer" className='text-xl text-blue-500 underline font-bold'>View the Instructions from the IRS.gov</a>
					</div>
				</form>
			</div>			
		</>
	)
}