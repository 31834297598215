
import { constants } from 'buffer';
import { Fragment, useState, useEffect } from 'react'
import { useParams, useNavigate } from "react-router-dom";
import NumberFormat from 'react-number-format'
	
import { getUsersIp } from '../util/ipUtils';
import ModalDialog from './ModalDialog';
import ModalIndeterminate from './ModalIndeterminate';

import Parse from 'parse';

import { BellIcon } from '@heroicons/react/24/outline';

import { ReactComponent as QurbieLogo } from '../assets/Qurbie.svg'
import ModalOverlay from './ModalOverlay';

import * as Constants from '../model/index';

import { useSelector, useDispatch } from 'react-redux'
import { changeNavState } from '../app/NavigationSlice';
import { updateUserInfo } from '../app/UserSlice';

export default function SignUpGuideBusinessInfo() {
	const [businessName, setBusinessName] = useState();
	const [address, setAddress] = useState();
	const [address2, setAddress2] = useState();
	const [city, setCity] = useState();
	const [state, setState] = useState();
	const [zip, setZip] = useState();
	const [phone, setPhone] = useState();
	const [website, setWebsite] = useState();
	const [entityType, setEntityType] = useState(Constants.entityTypes[0].value);
	const [entityOther, setEntityOther] = useState();
	const [taxId, setTaxId] = useState();
	const [description, setDescription] = useState();
	const [clientIp, setClientIp] = useState();

	const [alert, setAlert] = useState({show: false});
	const [progress, setProgress] = useState({show: false});

	const dispatch = useDispatch();
    const navigate = useNavigate();
    const userState = useSelector((state) => state.userState);

	useEffect(()=> {
		if (!Parse.User.current()) {
			console.log('user not logged in');
			navigate('/sign-in');
			return;
		}
		
		if (!clientIp) {
			getUsersIpAddress();

		}
	}, [])

	
	const getUsersIpAddress = async () => {		
		let usersIp = await getUsersIp();		
		
		setClientIp(usersIp);
	}
	
	const handleAddProfile = () => {		
	
		if (!clientIp) {
			setAlert({show: true, title: 'Unable to get your IP address. This is required by our financial partners for security reasons. If you are behind a firewall or VPN, please create your account while disconnected from these services. Please try again.'});
			return;
		}

		if (!businessName || businessName.length < 1 || !address || address.length < 1 || !city || city.length < 1 || 
			!state || state.length < 1 || !zip || zip.length < 1 || !phone || phone.length < 1 || !entityType || 
			entityType.length < 1) {
			console.log("missing fields")
			console.log(businessName, address, city, state, zip, phone, entityType);
		
			setAlert({show: true, title: 'Please fill out all required fields and try again.' })
			return;
		} else if (entityType === 'OTHER' && (!entityOther || entityOther.length < 1)) {
			console.log("other issue");		
			setAlert({show: true, title: 'Please fill out the entity type - other field and try again.' })
			return;
		} else {
		
			if (!Parse.User.current()) {
				console.log('user not logged in');
				navigate('/sign-in');
				return;
			}

			console.log("Saving business object!");
			const BusinessObject = Parse.Object.extend("Business");
			const newBusiness = new BusinessObject();
			newBusiness.set('businessName', businessName);
			newBusiness.set('addressLine1', address);
			newBusiness.set('addressLine2', address2);
			newBusiness.set('city', city);
			newBusiness.set('state', state);
			newBusiness.set('zip', zip);
			newBusiness.set('phone', phone);
			newBusiness.set('website', website);
			newBusiness.set('entityType', entityType);
			newBusiness.set('entityTypeOther', entityOther || '');
			newBusiness.set('description', description);
			newBusiness.set('taxId', taxId);
			newBusiness.set('owner', Parse.User.current());
			newBusiness.set('status', 'not_verified');

			setProgress({show: true, title: 'Saving Business Profile'});
			newBusiness.save().then((business) => {
				console.log("business object saved.");
				setProgress({show: false});
				console.log('Saved new business with name:', business.get('businessName'));			

				console.log("Creating stripe account");
				setProgress({show: true, title: 'Setting Up Business Account'});
				
				// update current user with business id
				let currentUser = Parse.User.current();
				currentUser.set('business', business);
				currentUser.save().then(result => console.log("Saved business to current user"))
								  .catch(error => console.log("Error saving business to current user:", error));

				console.log("Using email:", Parse.User.current().get("email"));
				
				// now create stripe account
				Parse.Cloud.run("QurbieCreateStripeAccount", {email: Parse.User.current().get("email"), businessId: business.id, clientIp: clientIp})
					.then((result)=>{
						setProgress({show: false});
						console.log('Result:', result);
						
						if (result.rawType) {
							setAlert({show: true, title: 'An Error Occured', message: `Something went wrong when trying to finalize your account: ${result.raw.message}`});
							return;
						}
						
						console.log("Stripe Account Created:", result);

						console.log("Updating completed state");
						let completedStates = Parse.User.current().get('setupStagesCompleted');
						let updatedStates = {...completedStates, business: true};
						Parse.User.current().set('setupStagesCompleted', updatedStates).save().then(result => {
							console.log("Saved completed states update", result);
							navigate("/getting-started", {replace: true});
						}).catch(error => console.log("Error:", error));

					}).catch(error => {
						setProgress({show: false});
						console.log(error);				
					});
				
			}).catch(error => {
				setProgress({show: false});
				console.log('Error:', error);
			})
		}
	}

	return (
		<>
			<ModalDialog show={alert.show || false} title={alert.title} message={alert.message}
				confirmButtonTitle={alert.okTitle || "Ok"} cancelButtonTitle={alert.cancelTitle} icon={<BellIcon className="text-blue-500" />}
				confirmButtonFunc={() => setAlert({show:false})} />

			<ModalIndeterminate show={progress.show} title={progress.title || ''} message={progress.message || ''} />

			<div className="m-10 bg-white flex flex-col px-10 py-10 rounded-2xl">	

				<div>
					<h2 className="text-4xl font-extrabold text-gray-700">Business Information</h2>
					<p className="mt-2  text-lg text-gray-600">
						We just need a few more details about your business to get started. These details help us set your company up for payment success!
					</p>
				</div>

				<form className="space-y-8 divide-y divide-gray-200">
					<div className="space-y-8 divide-y divide-gray-200">
						<div className="pt-0 ">
							
							{/* Divider */}
							<div className='mt-8 bg-gray-200 w-full' style={{height: '1px'}}></div>							

							{/* Business Information */}							
							<div className='mt-8'>
								<h3 className="text-lg leading-6 font-medium text-gray-900">Business Contact Information</h3>
								<p className="mt-1 text-sm text-gray-500">Qurbie uses this information to fill out important forms and to reach the business if issues come up.</p>
							</div>
							
							<div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
								<div className="sm:col-span-full">
									<label htmlFor="business-name" className="block text-sm font-medium text-gray-700">
										Business Name
									</label>
									<div className="mt-1">
										<input
											type="text"
											name="business-name"
											id="business-name"
											autoComplete="business-name"
											className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
											onChange={(e) => setBusinessName(e.target.value)}
										/>
									</div>
								</div>
							</div>

							<div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
								{/* Address */}
								<div className="sm:col-span-6">
									<label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
										Street Address
									</label>
									<div className="mt-1">
										<input
											type="text"
											name="street-address"
											id="street-address"
											autoComplete="street-address"
											className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
											onChange={(e) => setAddress(e.target.value)}
										/>
									</div>
								</div>

								{/* Address Line 2 */}
								<div className="sm:col-span-6">
									<label htmlFor="street-address2" className="block text-sm font-medium text-gray-700">
										Address Line 2
									</label>
									<div className="mt-1">
										<input
											type="text"
											name="street-address2"
											id="street-address2"
											autoComplete="street-address2"
											placeholder="Apt, Suite, etc (optional)"
											className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
											onChange={(e) => setAddress2(e.target.value)}
										/>
									</div>
								</div>

								<div className="sm:col-span-2">
									<label htmlFor="city" className="block text-sm font-medium text-gray-700">
										City
									</label>
									<div className="mt-1">
										<input
											type="text"
											name="city"
											id="city"
											autoComplete="address-level2"
											className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
											onChange={(e) => setCity(e.target.value)}
										/>
									</div>
								</div>

								<div className="sm:col-span-2">
									<label htmlFor="region" className="block text-sm font-medium text-gray-700">
										State / Province
									</label>
									<div className="mt-1">
										<select id="stateOptions" 
											className='shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md'
											onChange={(e)=>setState(e.target.value)}>

											{Constants.statesList.map( (state, index) => (
												<option key={index} value={state.code}>{state.name}</option>
											))}
										</select>											
									</div>
								</div>

								<div className="sm:col-span-2">
									<label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
										ZIP / Postal code
									</label>
									<div className="mt-1">
										<input
											type="text"
											name="postal-code"
											id="postal-code"
											autoComplete="postal-code"
											className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
											onChange={(e) => setZip(e.target.value)}
										/>
									</div>
								</div>

								{/* Phone Number */}
								<div className="sm:col-span-2">
									<label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
										Business Phone Number
									</label>
									<div className="mt-1">
										<NumberFormat
											placeholder={"(___)-___-____"}
											format={"(###)-###-####"}
											mask="_" 
											className="mt-1 shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
											onChange={(e)=> setPhone(e.target.value)}
										/>										
									</div>
								</div>

								{/* Website */}
								<div className="sm:col-span-4">
									<label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
										Business Website
									</label>
									<div className="mt-1">
										<input
											type="text"
											name="website"
											id="website"
											autoComplete="website"
											placeholder='https://www.example.com'
											className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
											onChange={(e) => setWebsite(e.target.value)}
										/>
									</div>
								</div>
							</div>

							{/* Business Information */}							
							<div className='mt-8'>
								<h3 className="text-lg leading-6 font-medium text-gray-900">Business Entity Information</h3>
								<p className="mt-1 text-sm text-gray-500">Tell us a little more about how your business is structured.</p>
							</div>
							
							{/* What type of business */}
							<div className="mt-0 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
								<div className="sm:col-span-full">
									<label htmlFor='businessSelect' className='mt-4 block text-sm font-medium text-gray-700'>What type of business is this?</label>
									<select id="businessSelect" className='w-full border-1 border-gray-300 rounded-md mt-1'
										onChange={(e) => setEntityType(e.target.value)}>
										{Constants.entityTypes.map( (entityType, index) => (
											<option key={index} value={entityType.value}>{entityType.title}</option>
										))}										
									</select>
									<span className="text-sm text-gray-500">Is your business type not listed? Some types require special authorizations. <a href="https://qurbie.com/contact" className='font-bold text-blue-600' target='_blank' rel="noreferrer">Contact Us</a> and we will get you set up as soon as possible.</span>
								</div>
							</div>

							{/* Tax ID of business */}
							<div className="mt-0 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
								<div className="sm:col-span-full">
									<label htmlFor='businessSelect' className='mt-4 block text-sm font-medium text-gray-700'>What is the Tax ID for this business? (e.g. EIN / TIN)</label>
									<NumberFormat
										placeholder={"00-0000000"}
										format={"##-#######"}
										mask="_" 
										className="mt-1 shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
										onChange={(e)=> setTaxId(e.target.value)}
									/>
								</div>
							</div>
							
							{/* Business Description */}
							<div className="mt-0 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
								<div className="sm:col-span-full">
									<label htmlFor='business-description' className='mt-4 block text-sm font-medium text-gray-700'>Business Description:</label>
									<div className="mt-1">
										<input
											type="text"
											name="business-description"
											id="business-description"
											autoComplete="business-description"
											placeholder='i.e. We provide marketing services to small and medium sized businesses.'
											className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
											onChange={(e) => setDescription(e.target.value)}
										/>
										<span className="text-sm text-gray-500">Please provide a <span className='underline font-bold'>brief</span> and high-level sentence on what your product or service is.</span>
									</div>		

								</div>
							</div>



							
							
						</div>
					</div>

					<div className="pt-5">
						<div className="flex justify-end">
							<button
								type="button"
								className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
								onClick={ e => {
									e.preventDefault();
									console.log('back');

									navigate("/getting-started");									
								}}
							>
								Back
							</button>

							<button
								type="submit"
								className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
								onClick={ e => {
									e.preventDefault();
									console.log('save business clicked');
								
									handleAddProfile();	
								}}
							>
								Save Business Profile
							</button>
						</div>
					</div>
				</form>
			</div>
		</>
	)
}