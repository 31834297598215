import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    shouldUpdateWorkLog: false,
    workLogSelection: false,
    workRequestItems: [],
    workLogBusiness: null,
};

export const generalSlice = createSlice({
    name: 'generalSlice',
    initialState,
    reducers: {       
        changeGeneralState: (state, action) => {
            state.shouldUpdateWorkLog = action.payload.shouldUpdateWorkLog || false;
            state.workLogSelection = action.payload.workLogSelection;
            state.workRequestItems = action.payload.workRequestItems;
            if (action.payload.workLogBusiness) {
                state.workLogBusiness = action.payload.workLogBusiness;
            }
        },        
    },
});

export const { changeGeneralState } = generalSlice.actions

export default generalSlice.reducer