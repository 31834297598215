import Parse from 'parse';
import { clearSettings } from '../util/settingsStorage';

export default function handleParseError(err, logOutHandler, errorHandler) {
    switch (err.code) {
      case Parse.Error.INVALID_SESSION_TOKEN:
        clearSettings();
        Parse.User.logOut();
        logOutHandler && logOutHandler();        
        break;
  
      default:
        console.log(`Handle Parse Error Code: ${err.code} Message: ${err.error || err.message || 'Undefined Error Message'}`);
        errorHandler && errorHandler(err);
        break;
    }
  }