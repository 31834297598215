
import { Fragment, useState, useEffect } from 'react'
import { useParams, useNavigate } from "react-router-dom";

import { ReactComponent as QurbieLogo} from '../assets/Qurbie.svg'
import ModalOverlay from './ModalOverlay';


export default function SignInForgottenPassword(props) {	
	const [resetSent, setResetSent] = useState(false);

	let navigate = useNavigate();

	return (
		<>
			<div className="min-h-full flex flex-col justify-center my-32 sm:px-6 lg:px-8">				

				<div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">					
					<div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
						<div className="sm:mx-auto sm:w-full sm:max-w-md">
							<QurbieLogo className="mx-auto h-12 w-auto" />
							<h2 className="mt-4 mb-6 text-center text-2xl font-extrabold text-gray-700">Password Reset</h2>					
						</div>
						
						<form className="space-y-6" action="#" method="POST">
							<div>
								<label htmlFor="email" className="block text-sm font-medium text-gray-700">
									Email address
								</label>
								<div className="mt-1">
									<input
										id="email"
										name="email"
										type="email"
										autoComplete="email"
										required
										className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
									/>
								</div>
							</div>
							
							{resetSent &&
								<div>
									<h3 className='text-blue-500 font-semibold'>If your email matches an existing account you will receive an email with instructions on how to reset your password.</h3>
								</div>
							}

							<div>
								<button
									type="submit"
									className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-base font-bold text-white bg-blue-600 hover:bg-blue-700 focus:outline-none"
									onClick={e => {
										e.preventDefault(true);										
										setResetSent(true);

										// TODO: try to login
									}}
								>
									Reset Password
								</button>
							</div>
							
						</form>
						
					</div>
				</div>
			</div>			
		</>
	)
}
