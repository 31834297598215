import { Fragment, useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { updateUserInfo } from '../app/UserSlice';

import {
	ChevronRightIcon,
    CheckCircleIcon,
    XCircleIcon,
    ExclamationCircleIcon
} from '@heroicons/react/24/outline'

import { EnvelopeIcon, PhoneIcon, PlusCircleIcon } from '@heroicons/react/20/solid'

import Parse from 'parse';
import handleParseError from '../util/handleParseError';

import * as Constants from '../model/index';

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}


export default function DashboardWorkers() {
    const userState = useSelector((state) => state.userState);

    const [currentTab, setCurrentTab] = useState('All');
    const [activeCount, setActiveCount] = useState(0);
    const [inactiveCount, setInactiveCount] = useState(0);
    const [displayItems, setDisplayItems] = useState(userState.workers || []);

            
    const dispatch = useDispatch(); 
    const navigate = useNavigate();

    useEffect(()=>{
        Parse.Cloud.run('GetUserData', {}).then((result) => {				
            console.log('GetUSerData Result:', result);
            console.log("Workers GetUserData Result:", result);
            dispatch(updateUserInfo(result))
        })
        .then(()=> {
            Parse.Cloud.run('GetWorkersList', {}).then((result) => {				
                console.log('WorkersList Result:', result);
                dispatch(updateUserInfo({workers: result}));
                calculateTabCounts(result);
                setDisplayItems(result);
            })
            .catch((error) => {
                handleParseError(error, ()=> {
                    // token expired
                    console.log("GetWorkersList: User Login Expired");
                    Parse.User.logOut().then(()=> navigate('/sign-in', {replace: true}));
                }, (err)=> {console.log("GetWorkersList non-error Handler:", err)});            
            });
        })
        .catch((error) => {
            handleParseError(error, ()=>{
                // token expired
                console.log("GetUserData: User Login Expired");
                Parse.User.logOut().then(()=> navigate('/sign-in', {replace: true}));
            }, (err)=> {console.log("GetUserData non-error Handler:", err)});            
        });
    }, []);


    const calculateTabCounts = (workers) => {
        let active = 0;
        let inactive = 0;

        workers.forEach(worker => {
            if (worker.status === 'active') {
                active++;
            } else {
                inactive++;
            }
        });

        setActiveCount(active);
        setInactiveCount(inactive);
    }

    const changeTab = (tab) => { 
        setCurrentTab(tab);
        if (tab === 'All') {
            setDisplayItems(userState.workers);
        } else if (tab === 'Active') {
            setDisplayItems(userState.workers.filter(worker => worker.status === 'active'));
        } else if (tab === 'Inactive') {
            setDisplayItems(userState.workers.filter(worker => worker.status !== 'active'));
        }
    }

    const displayStatusTag = (status) => {
        return (
            <>
                {status === "active" && (
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        {status}
                    </span>
                )}

                {status === "pending" && (                                                    
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-orange-100 text-orange-700">
                        {status}
                    </span>
                )}

                {status === "issues" && (                                                    
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                        {status}
                    </span>
                )}

                {status === "invited" && (
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                        {status}
                    </span>
                )}
            </>
        )
    }

    const formatMoney = (value) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
        }).format(value);
    }

    return (
        <>            
            {/* Worker List */}
            <div>
                <div className="sm:hidden">
                    <label htmlFor="tabs" className="sr-only">
                        Select a tab
                    </label>
                    {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                    <select
                        id="tabs"
                        name="tabs"
                        className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                        defaultValue={Constants.tabs.find((tab) => tab.current).name}
                    >
                        <option>All</option>
                        <option>Active</option>
                        <option>Inactive</option>                        
                    </select>
                </div>

                <div className="hidden sm:block mt-10 text-left">
                    <div className="flex justify-between">
                        <div className="text-4xl text-gray-900">Workers</div>                        
                    </div>
                    <div className="border-b border-gray-200">
                        <div className='flex'>  { /*justify-between'> */}
                            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                                
                                {/* All Tab */}
                                <div                                    
                                    onClick={() => changeTab('All')}
                                    className={classNames(
                                        currentTab === 'All'
                                            ? 'border-blue-500 text-blue-600'
                                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                                        'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm'
                                    )}
                                    aria-current={currentTab === 'All' ? 'page' : undefined}
                                >
                                    All
                                    
                                    <span
                                        className={classNames(
                                            currentTab === 'All' ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-900',
                                            'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                                        )}
                                    >
                                        {activeCount + inactiveCount}
                                    </span>                                    
                                </div>
                                
                                {/* Active Tab */}
                                <div                                   
                                    onClick={() => changeTab('Active')}
                                    className={classNames(
                                        currentTab === 'Active'
                                            ? 'border-blue-500 text-blue-600'
                                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                                        'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm'
                                    )}
                                    aria-current={currentTab === 'Active' ? 'page' : undefined}
                                >
                                    Active
                                    
                                    <span
                                        className={classNames(
                                            currentTab === 'Active' ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-900',
                                            'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                                        )}
                                    >
                                        {activeCount}
                                    </span>                                    
                                </div>

                                {/* Inactive Tab */}
                                <div                                   
                                    onClick={() => changeTab('Inactive')}
                                    className={classNames(
                                        currentTab === 'Inactive'
                                            ? 'border-blue-500 text-blue-600'
                                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                                        'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm'
                                    )}
                                    aria-current={currentTab === 'Inactive' ? 'page' : undefined}
                                >
                                    Inactive
                                    
                                    <span
                                        className={classNames(
                                            currentTab === 'Inactive' ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-900',
                                            'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                                        )}
                                    >
                                        {inactiveCount}
                                    </span>                                    
                                </div>
                            </nav>
                            <div>
                                <button className="inline-flex items-center ml-4 px-3 py-2 border border-transparent text-sm leading-4 font-medium focus:outline-none"
                                    onClick={() => console.log("New Project Clicked")}>
                                    <PlusCircleIcon className="self-center flex-shrink-0 h-8 w-8 text-blue-500" />
                                    <div className="ml-1 font-bold text-blue-500"><Link to="/add-worker">Add New Worker</Link></div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Team Table */}
            <div className="mt-5 flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Name
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Contact
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-center text-xs font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Rate
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-right text-xs font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Invoices
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-right text-xs font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Total Paid
                                        </th>                                        
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-right text-xs font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Total Pending
                                        </th>           
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Status
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Tax Ready
                                        </th>
                                        {/* <th scope="col" className="relative px-6 py-3">
                                            <span className="sr-only">View Project</span>
                                        </th> */}
                                    </tr>
                                </thead>
                                <tbody>                                    
                                    {(userState.workers === undefined || userState.workers?.length === 0) && 
                                        <>                                            
                                            <tr>
                                                <td className="px-4 py-4 whitespace-nowrap text-left text-sm font-bold text-gray-900"> 
                                                    <button 
                                                        className="mt-1 ml-1 flex items-center justify-center px-4 py-2 rounded-md font-bold focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white bg-blue-600 text-white"
                                                        onClick={(e)=> {
                                                            navigate('/add-worker');
                                                        }}>
                                                        Invite Your First Worker
                                                    </button>
                                                </td>
                                            </tr>
                                        </>
                                    }

                                    {/* {userState.workers && userState.workers.length > 0 && userState.workers.map((worker, index) => ( */}
                                    {displayItems && displayItems.length > 0 && displayItems.map((worker, index) => (
                                        <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-blue-50'}>                                            
                                            <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium text-gray-900">{worker.name}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium text-gray-500">
                                                <div className="flex justify-center">
                                                    <div className="mr-2 w-6 h-6 text-blue-600"><a href={`mailto:${worker.email}`} target='_blank' rel='noreferrer'><EnvelopeIcon /></a></div>                                                
                                                    <div className="mr-2 w-6 h-6 text-blue-600"><a href={`tel:${worker.phone}`} target='_blank' rel='noreferrer'><PhoneIcon /></a></div>
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium text-gray-500">${worker.rate || 0}/hr</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium text-gray-500">{worker.invoicesCount || 0}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium text-gray-500">{formatMoney(worker.totalPaid || 0)}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium text-gray-500">{formatMoney(worker.totalPending || 0)}</td>
                                            {/* Status */}
                                            <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium text-gray-500">                                                
                                                {displayStatusTag(worker.status)}
                                            </td>
                                            {/* Tax Ready */}
                                            <td className="px-6 py-4 whitespace-nowrap flex justify-center text-sm font-medium">
                                                {worker.taxStatus === 'verified' && <CheckCircleIcon className="w-8 text-green-500" />} 
                                                {worker.taxStatus === 'unverified' && <ExclamationCircleIcon className="w-8 text-orange-500" />}
                                                {worker.taxStatus === 'issues' && <XCircleIcon className="w-8 text-red-700" />}
                                                {worker.taxStatus !== 'verified' && worker.taxStatus !== 'unverified' && worker.taxStatus !== 'issues' && <ExclamationCircleIcon className="w-8 text-orange-500" />}
                                            </td>         
                                            {/* Chevron */}
                                            {/* <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                <a href="#edit" className="text-blue-600 hover:text-blue-900">
                                                    <ChevronRightIcon className="w-5 h-5 text-blue-600" />
                                                </a>
                                            </td> */}
                                        </tr>                                        
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>            
        </>
    );
}