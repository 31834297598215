import { Fragment, useEffect, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { Link, useNavigate } from "react-router-dom";

import {loadSettings } from '../util/settingsStorage';

import { useSelector, useDispatch } from 'react-redux'
import { changeNavState } from '../app/NavigationSlice';
import { updateUserInfo } from '../app/UserSlice';

import {
	HomeIcon,
	InboxArrowDownIcon,
	UsersIcon,
	QuestionMarkCircleIcon,
	CurrencyDollarIcon,
	ClockIcon,
	CalendarDaysIcon,
} from '@heroicons/react/24/outline'

import Divider from './Divider';

import { ReactComponent as QurbieLogo} from '../assets/Qurbie.svg'

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}


export default function Navigation(props) {
    const navState = useSelector((state) => state.navState.value)
	const userState = useSelector((state) => state.userState);
	const dispatch = useDispatch(); 
	const navigate = useNavigate(); 
    const [appSettings, setAppSettings] = useState({});

    useEffect(() => {
        // load app settings
        setAppSettings(loadSettings());

        // check the url to see if it contains words that match the navState
        if (window.location.href.includes('dashboard')) {
            dispatch(changeNavState('Dashboard'));
        } else if (window.location.href.includes('payments-history')) {
            dispatch(changeNavState('PaymentsHistory'));
        } else if (window.location.href.includes('invoice-history')) {
            dispatch(changeNavState('InvoiceHistory'));
        } else if (window.location.href.includes('payments')) {
            dispatch(changeNavState('Payments'));
        } else if (window.location.href.includes('workers')) {
            dispatch(changeNavState('Workers'));
        } else if (window.location.href.includes('taxes')) {
            dispatch(changeNavState('Taxes'));
        } else if (window.location.href.includes('time')) {
            dispatch(changeNavState('RequestPay'));
        } else if (window.location.href.includes('help')) {
            dispatch(changeNavState('Help'));
        } else if (window.location.href.includes('request-pay')) {
            dispatch(changeNavState('RequestPay'));
        } else {
            // do nothing with the nav bar
        }
    }, []);


    return (        
        <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex flex-col flex-grow border-r border-gray-200 pt-5 bg-white overflow-y-auto">
                <div className="flex items-center flex-shrink-0 px-4">
                    <QurbieLogo className="h-12 w-auto" />							
                </div>
                <div className="mt-4 flex-grow flex flex-col">
                    <nav className="flex-1 px-2 pb-4 space-y-1">							

                        <div className='text-2xl font-bold'>
                            <span className='text-xs text-gray-400'>Welcome Back</span><br/>                            
                            {userState.business?.businessName?.trim() || (userState.firstName?.trim()) ||''}
                        </div>

                        <Divider />

                        {/* Dashboard */}
                        <Link className={classNames(
                                //item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                navState === 'Dashboard' ? 'bg-blue-100 text-gray-900' : 'text-gray-600 hover:bg-blue-50 hover:text-gray-900',
                                'group flex items-center px-2 py-2 text-sm font-medium rounded-md w-full'
                            )}
                            to='/dashboard'
                            onClick={() => dispatch(changeNavState('Dashboard'))}
                        >
                            <HomeIcon
                                className={classNames(
                                    navState === 'Dashboard' ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                                    'mr-3 flex-shrink-0 h-6 w-6'
                                )}
                                aria-hidden="true"
                            />
                            Dashboard
                        </Link>

                        {/* Payments */}
                        {appSettings?.accountType === 'company' &&  (
                            <Link className={classNames(
                                    //item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                    navState === 'Payments' ? 'bg-blue-100 text-gray-900 w-full' : 'text-gray-600 hover:bg-blue-50 hover:text-gray-900',
                                    'group flex items-center px-2 py-2 text-sm font-medium rounded-md w-full'
                                )}
                                to='/payments'
                                onClick={() => dispatch(changeNavState('Payments'))}
                            >
                                <CurrencyDollarIcon
                                    className={classNames(
                                        navState === 'Payments' ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                                        'mr-3 flex-shrink-0 h-6 w-6'
                                    )}
                                    aria-hidden="true"
                                />
                                Payments
                            </Link>
                        )}

                        {/* Workers */}
                        {appSettings?.accountType === 'company' &&  (
                            <Link className={classNames(
                                    //item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                    navState === 'Workers' ? 'bg-blue-100 text-gray-900 w-full' : 'text-gray-600 hover:bg-blue-50 hover:text-gray-900',
                                    'group flex items-center px-2 py-2 text-sm font-medium rounded-md w-full'
                                )}
                                to='/workers'
                                onClick={() => dispatch(changeNavState('Workers'))}
                            >
                                <UsersIcon
                                    className={classNames(
                                        navState === 'Workers' ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                                        'mr-3 flex-shrink-0 h-6 w-6'
                                    )}
                                    aria-hidden="true"
                                />
                                Workers
                            </Link>
                        )}

                        {/* Tax Filing */}
                        {appSettings?.accountType === 'company' &&  (
                            <Link className={classNames(
                                    //item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                    navState === 'Taxes' ? 'bg-blue-100 text-gray-900 w-full' : 'text-gray-600 hover:bg-blue-50 hover:text-gray-900',
                                    'group flex items-center px-2 py-2 text-sm font-medium rounded-md w-full'
                                )}
                                to='/taxes'
                                onClick={() => dispatch(changeNavState('Taxes'))}
                            >
                                <InboxArrowDownIcon
                                    className={classNames(
                                        navState === 'Taxes' ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                                        'mr-3 flex-shrink-0 h-6 w-6'
                                    )}
                                    aria-hidden="true"
                                />
                                Tax Filing
                            </Link>
                        )}
                                                

                        {/* Worker Time Tracking */}
                        {appSettings?.accountType !== 'company' &&  (
                            <Link className={classNames(
                                    //item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                    navState === 'RequestPay' ? 'bg-blue-100 text-gray-900 w-full' : 'text-gray-600 hover:bg-blue-50 hover:text-gray-900',
                                    'group flex items-center px-2 py-2 text-sm font-medium rounded-md w-full'
                                )}
                                to='/request-pay'
                                onClick={() => dispatch(changeNavState('RequestPay'))}
                            >
                                <ClockIcon
                                    className={classNames(
                                        navState === 'RequestPay' ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                                        'mr-3 flex-shrink-0 h-6 w-6'
                                    )}
                                    aria-hidden="true"
                                />
                                Request Payment
                            </Link>
                        )}

                        {/* Payment History */}
                        {appSettings?.accountType === 'company' &&  (    
                            <Link className={classNames(
                                    //item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                    navState === 'PaymentsHistory' ? 'bg-blue-100 text-gray-900 w-full' : 'text-gray-600 hover:bg-blue-50 hover:text-gray-900',
                                    'group flex items-center px-2 py-2 text-sm font-medium rounded-md w-full'
                                )}
                                to='/payments-history'
                                onClick={() => dispatch(changeNavState('PaymentsHistory'))}
                            >
                                <CalendarDaysIcon
                                    className={classNames(
                                        navState === 'PaymentsHistory' ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                                        'mr-3 flex-shrink-0 h-6 w-6'
                                    )}
                                    aria-hidden="true"
                                />
                                Payments History
                            </Link>
                        )}

                        {/* Invoice History */}
                        {appSettings?.accountType !== 'company' &&  (    
                            <Link className={classNames(                                    
                                    navState === 'InvoiceHistory' ? 'bg-blue-100 text-gray-900 w-full' : 'text-gray-600 hover:bg-blue-50 hover:text-gray-900',
                                    'group flex items-center px-2 py-2 text-sm font-medium rounded-md w-full'
                                )}
                                to='/invoice-history'
                                onClick={() => dispatch(changeNavState('InvoiceHistory'))}
                            >
                                <CalendarDaysIcon
                                    className={classNames(
                                        navState === 'InvoiceHistory' ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                                        'mr-3 flex-shrink-0 h-6 w-6'
                                    )}
                                    aria-hidden="true"
                                />
                                Invoice History
                            </Link>
                        )}

                        <Divider />                        

                        {/* Help */}
                        <Link className={classNames(
                                //item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                navState === 'Help' ? 'bg-blue-100 text-gray-900 w-full' : 'text-gray-600 hover:bg-blue-50 hover:text-gray-900',
                                'group flex items-center px-2 py-2 text-sm font-medium rounded-md w-full'
                            )}
                            to='/help'
                            onClick={() => dispatch(changeNavState('Help'))}
                        >
                            <QuestionMarkCircleIcon
                                className={classNames(
                                    navState === 'Help' ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                                    'mr-3 flex-shrink-0 h-6 w-6'
                                )}
                                aria-hidden="true"
                            />
                            Help
                        </Link>

                    </nav>
                </div>
            </div>
        </div>
    )
}