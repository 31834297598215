import { Fragment, useEffect, useState } from 'react'

import { loadStripe } from '@stripe/stripe-js';

import {
    BellIcon,
} from '@heroicons/react/24/outline'

import ModalDialog from '../components/ModalDialog';
import ModalIndeterminate from '../components/ModalIndeterminate';

import Parse from 'parse';

export default function PayAccountDemo(props) {
    const [alert, setAlert] = useState({ show: false });
    const [progress, setProgress] = useState({ show: false });
    const [clientIp, setClientIp] = useState("");
    const [refreshing, setRefreshing] = useState(false);

    const [businessId, setBusinessId] = useState("acct_1ORJsMQeADnmkFqF");
    const [businessFAId, setBusinessFAId] = useState("fa_1ORJvFQeADnmkFqFuZxHzXRx");
    const [businessPayId, setBusinessPayId] = useState("pm_1ORJwgQeADnmkFqFRnojPuUw");
    const [businessBalance, setBusinessBalance] = useState(0);
    const [businessInboundPending, setBusinessInboundPending] = useState(0);
    const [businessOutboundPending, setBusinessOutboundPending] = useState(0);
    const [businessTransactions, setBusinessTransactions] = useState([]);

    const [workerId, setWorkerId] = useState("acct_1ORJjZH6rX1Zu3QM");
    const [workerFAId, setWorkerFAId] = useState("fa_1ORJmeH6rX1Zu3QMM6dRj6zV");
    const [workerPayId, setWorkerPayId] = useState("pm_1ORJp5H6rX1Zu3QMJB8bz456");
    const [workerBalance, setWorkerBalance] = useState(0);
    const [workerInboundPending, setWorkerInboundPending] = useState(0);
    const [workerOutboundPending, setWorkerOutboundPending] = useState(0);
    const [workerTransactions, setWorkerTransactions] = useState([]);

            

    useEffect(() => {
        getUsersIp();

        // Get Business Account Balance
        // Get Worker Account Balance
        setProgress({ show: true, title: "Refreshing Data...", message: "Please wait..." });
        getData(() => {
            console.log("Refreshed Data");
            //setProgress({ show: false });
        });

        // Get Business Account Transactions
        getTransactions(() => {
            console.log("Refreshed Transactions");
            setProgress({ show: false });
        });
    }, []);

    const getUsersIp = async (callback) => {

        fetch('https://geolocation-db.com/json/').then((result) => {
            result.json().then(jsonData => {
                //console.log("Json Data:", jsonData);
                if (jsonData.IPv4) {
                    setClientIp(jsonData.IPv4);
                    console.log("Client IP:", jsonData.IPv4);

                    if (callback) {
                        callback(jsonData.IPv4);
                    }
                }
            })
        });
    }

    const getData = async (callback) => { 
            
        try {
            // Get Business Account Balance
            const result = await Parse.Cloud.run("GetFinancialAccount", { accountId: businessId, faAccountId: businessFAId });
            console.log("GetFinancialAccount Result:", result);
            setBusinessBalance(result.balance?.cash?.usd / 100 || 0);
            setBusinessInboundPending(result?.balance?.inboundPending?.usd / 100 || 0);
            setBusinessOutboundPending(result?.balance?.outboundPending?.usd / 100 || 0);

            // Get Worker Account Balance
            const result2 = await Parse.Cloud.run("GetFinancialAccount", { accountId: workerId, faAccountId: workerFAId });
            console.log("GetFinancialAccount Result:", result);
            setWorkerBalance(result2.balance?.cash?.usd / 100 || 0);
            setWorkerInboundPending(result2?.balance?.inboundPending?.usd / 100 || 0);
            setWorkerOutboundPending(result2?.balance?.outboundPending?.usd / 100 || 0);

            if (callback) {
                callback({businessResult: result, workerResult: result2});
            }
        } catch (error) {
            console.log("Error:", error);
        };
    }


    const getTransactions = async (callback) => {

        try {
            // Get Business Account Balance
            const result = await Parse.Cloud.run("ListTransactions", { accountId: businessId, faAccountId: businessFAId });
            console.log("Business ListTransactions Result:", result);
            setBusinessTransactions(result?.result?.data || []);

            // Get Worker Account Balance
            const result2 = await Parse.Cloud.run("ListTransactions", { accountId: workerId, faAccountId: workerFAId });
            console.log("Worker ListTransactions Result:", result);
            setWorkerTransactions(result2?.result?.data || []);

            if (callback) {
                callback({businessResult: result, workerResult: result2});
            }            
        } catch (error) {
            console.log("Error:", error);
        }
    }

    const timestampToDateString = (timestamp) => {
        if (!timestamp) {
            return "err";
        }

        const timestampMilliseconds = timestamp * 1000;
        const date = new Date(timestampMilliseconds);
        return date.toLocaleString();
    }


    return (
        <>
            <ModalDialog show={alert.show || false} title={alert.title} message={alert.message}
                confirmButtonTitle={alert.okTitle || "Ok"} cancelButtonTitle={alert.cancelTitle} icon={<BellIcon className="text-blue-500" />}
                confirmButtonFunc={() => setAlert({ showAlert: false })} />

            <ModalIndeterminate show={progress.show} title={progress.title || ''} message={progress.message || ''} />

            
            <div>Client IP: {clientIp}</div>
            <div className='my-4 flex justify-center'>
                <button
                    className="mt-1 ml-1 flex items-center justify-center px-4 py-2 rounded-md font-bold focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white bg-blue-600 text-white"
                    onClick={() => {
                        console.log("Refreshing Data...");                    
                        setProgress({ show: true, title: "Refreshing Data...", message: "Please wait..." });
                        getData(() => {
                            console.log("Refreshed Data");
                            setProgress({ show: false });
                        });
                    }}>
                    Refresh Data
                </button>
            </div>

            <div className="flex justify-around">
                {/* Business Account Info */}
                <div className="p-4 border border-gray-300"> 
                    <h3 className='mt-4 font-bold'>John's Software</h3>
                    <div>Balance: <span className='text-2xl'>${businessBalance}</span></div>
                    <div className='text-sm'>Inbound Pending: <span className='text-2xl'>${businessInboundPending}</span></div>
                    <div className='text-sm'>Outbound Pending: <span className='text-2xl'>${businessOutboundPending}</span></div>
                    <strong>Account Id:</strong>
                    <input
                        id="accountId"
                        name="accountId"
                        type="text"
                        autoComplete="accountId"
                        placeholder='Account Id'
                        required
                        className="appearance-none block w-96 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        value={businessId}
                        onChange={(e) => setBusinessId(e.target.value)}
                    />

                    <strong>Financial Account Id:</strong>
                    <input
                        id="faAccountId1"
                        name="faAccountId1"
                        type="text"
                        autoComplete="faAccountId1"
                        placeholder='F Account Id'
                        required
                        className="appearance-none block w-96 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        value={businessFAId}
                        onChange={(e) => setBusinessFAId(e.target.value)}
                    />

                    <strong>Payment Method Id:</strong>
                    <input
                        id="payMethodId1"
                        name="payMethodId1"
                        type="text"
                        autoComplete="payMethodId1"
                        placeholder='Pay Method Id'
                        required
                        className="appearance-none block w-96 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        value={businessPayId}
                        onChange={(e) => setBusinessPayId(e.target.value)}
                    />
                                                
                    
                    <button
                        className="mt-1 ml-1 flex items-center justify-center px-4 py-2 rounded-md font-bold focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white bg-blue-600 text-white"
                        onClick={() => {
                            console.log("Inbound Transfer");                            
                            Parse.Cloud.run("StartInboundTransfer", { accountId: businessId, paymentMethodId: businessPayId, financialAccountId: businessFAId }).then((result) => {
                                console.log("Inbound Transfer Result:", result);
                            }).catch((error) => {
                                console.log("Error:", error);
                            });                        
                        }}>Deposit Money (Inbound)</button>

                    <button
                        className="mt-1 ml-1 flex items-center justify-center px-4 py-2 rounded-md font-bold focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white bg-blue-600 text-white"
                        onClick={() => {
                            console.log("Paying Worker...");
                            Parse.Cloud.run("BeginOutboundPayment", {accountId: businessId, sourceFaId: businessFAId, destFaId: workerFAId }).then((result) => {
                                console.log("Outbound Payment Result:", result);
                            }).catch((error) => {
                                console.log("Error:", error);
                            });
                        }}>Pay Worker</button>
                    </div>



                <div className="p-4 border border-gray-300"> 
                    <h3 className='mt-4 font-bold'>Ben Freelancin (Worker)</h3>
                    <div>Balance: <span className='text-2xl'>${workerBalance}</span></div>
                    <div className='text-sm'>Inbound Pending: <span className='text-2xl'>${workerInboundPending}</span></div>
                    <div className='text-sm'>Outbound Pending: <span className='text-2xl'>${workerOutboundPending}</span></div>
                    <strong>Account Id:</strong>
                    <input
                        id="accountId"
                        name="accountId"
                        type="text"
                        autoComplete="accountId"
                        placeholder='F Account Id'
                        required
                        className="appearance-none block w-96 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        value={workerId}
                        onChange={(e) => setWorkerId(e.target.value)}
                    />

                    <strong>Financial Account Id:</strong>
                    <input
                        id="faAccountId2"
                        name="faAccountId2"
                        type="text"
                        autoComplete="faAccountId2"
                        placeholder='F Account Id'
                        required
                        className="appearance-none block w-96 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        value={workerFAId}
                        onChange={(e) => setWorkerFAId(e.target.value)}
                    />

                    <strong>Payment Method Id:</strong>
                    <input
                        id="payMethodId2"
                        name="payMethodId2"
                        type="text"
                        autoComplete="payMethodId2"
                        placeholder='Pay Method Id'
                        required
                        className="appearance-none block w-96 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        value={workerPayId}
                        onChange={(e) => setWorkerPayId(e.target.value)}
                    />

                </div>

            </div>

            {/* Transactions */}
            <div className="mt-4 flex justify-around">
                {/* Business Account Info */}
                <div className="p-4 border border-gray-300"> 
                    <h3 className='mt-4 font-bold'>John's Software Transactions</h3>
                    {businessTransactions.map((trans, index) => (
                        <div>
                            Index: <b>{index}</b> <br />
                            Amount: <b>${trans.amount / 100 || 0}</b> <br />
                            Date: <b>{timestampToDateString(trans.created)}</b> <br />
                            Description: <b>{trans.description}</b> <br />
                            Financial Account: <b>{trans.financial_account}</b> <br />
                            Flow: <b>{trans.flow}</b> <br />
                            Flow Type: <b>{trans.flow_type}</b> <br />
                            Status: <b>{trans.status}</b> <br />
                            <hr/>
                        </div>
                    ))}
                </div>

                <div className="p-4 border border-gray-300">
                    <h3 className='mt-4 font-bold'>Ben Freelancin (Worker) Transactions</h3>
                    {workerTransactions.map((trans, index) => (
                        <div>
                            Index: <b>{index}</b> <br />
                            Amount: <b>${trans.amount / 100 || 0}</b> <br />
                            Date: <b>{timestampToDateString(trans.created)}</b> <br />
                            Description: <b>{trans.description}</b> <br />
                            Financial Account: <b>{trans.financial_account}</b> <br />
                            Flow: <b>{trans.flow}</b> <br />
                            Flow Type: <b>{trans.flow_type}</b> <br />
                            Status: <b>{trans.status}</b> <br />
                            <hr/>
                        </div>
                    ))}
                </div>
            </div>   
        </>
    )
}