import { Fragment, useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import { Listbox, Transition, Switch, Dialog } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon, ClipboardDocumentListIcon, PaperClipIcon } from '@heroicons/react/20/solid'
import { PencilSquareIcon, TrashIcon, PlusIcon, ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { ReactComponent as QurbieLogo} from '../../assets/Qurbie.svg'
import NumberFormat from 'react-number-format'
import DropdownWorkers from './DropdownWorkers'
import Divider from '../Divider'
import { DateTime } from 'luxon'

import { StateOptions, CountryOptions } from '../../model/Constants';

const steps = [
	{ id: 'Step 1', name: 'Review Work', href: '#', status: 'current' },
	{ id: 'Step 2', name: 'Accept or Reject', href: '#', status: 'upcoming' },
	{ id: 'Step 3', name: 'Finalize', href: '#', status: 'upcoming' },
]

const workRequestData = {
	id: "MRNTTwv8vn",
	title: 'Duffek Website Refresh',
	description: "The Duffek website must be overhauled and better represent the Duffek brand as well as it's capabilities.",	
	dueDate: DateTime.now().plus({weeks: 1}),
	status: 'Proposed',	
	tasks: [
		{ id: '1', title: 'Complete Design', description: 'Finish designing the interface of the application.' },
		{ id: '2', title: 'Implement Interface', description: 'Implement the designs and build the UI in Tailwind CSS using the Tailwind UI components to make this process faster.' },
		{ id: '3', title: 'Develop MVP', description: 'Build out each component one at a time and test them to ensure they are working correctly. Then put them together in one nice designed web application.' },
	],
	workers: [
		{ id: 2, name: 'Chelsea Bill', rate: 65.00, estHours: 22, type: 'Designer' },
	],
	requester: {
		businessName: 'Duffek Mobile',
		firstName: 'David',
		lastName: 'Nelson',
		email: 'david@duffekmoile.com',
		phone: '5555555555'
	}
}

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

export default function WorkRequestAccept1(props) {	
	let params = useParams();

	// Scroll to top on first load
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return(
		<>
			{/* Work Request Accepted 1 - Account Signup / Sign In */}
			<div className="lg:max-w-4xl ml-auto mr-auto pb-20 pt-20">
				<div className="bg-white overflow-hidden shadow-lg sm:rounded-lg text-left rounded-lg">
					<div className="px-4 py-5 sm:p-6">																								
						
						<div>
							<QurbieLogo className="w-48 mt-20 ml-auto mr-auto" />

							<div className="px-4 py-5 sm:px-6 mx-auto mb-20 text-center">
								<h3 className="text-xl leading-6 font-semibold text-gray-900">Work Request is No Longer Available</h3>
								<p className="mt-1 text-lg text-gray-500">This work reqest is no longer available because it was accepted, rejected, or has expired.<br/>Please contact the requesting party for more information.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}