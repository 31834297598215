import { Fragment, useState, useEffect } from 'react'
import { Listbox, Transition, Switch } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon, ClipboardDocumentListIcon } from '@heroicons/react/20/solid'
import { PencilSquareIcon, TrashIcon, PlusIcon } from '@heroicons/react/24/outline'
import { ReactComponent as QurbieLogo} from '../../assets/Qurbie.svg'
import NumberFormat from 'react-number-format'
import DropdownWorkers from './DropdownWorkers'
import Divider from '../Divider'

import { StateOptions, CountryOptions } from '../../model/Constants';

const steps = [
	{ id: 'Step 1', name: 'Select Client', href: '#', status: 'complete' },
	{ id: 'Step 2', name: 'Project Details', href: '#', status: 'complete' },
	{ id: 'Step 3', name: 'Add Workers', href: '#', status: 'current' },
	{ id: 'Step 4', name: 'Send Rquest', href: '#', status: 'upcoming' },
]

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

export default function NewProject3Workers(props) {	
	const [totalHours, setTotalHours] = useState(0);
	const [totalCosts, setTotalCosts] = useState(0);
	const [selectedWorkers, setSelectedWorkers] = useState([
		{ id: 1, name: 'David Nelson', rate: 90.00, estHours: 50, type: 'Developer' },
		{ id: 2, name: 'Chelsea Bill', rate: 65.00, estHours: 22, type: 'Designer' },
	]);

	useEffect(() => {
		// get total hours
		var addedHours = 0.0;
		var addedCosts = 0.0;

		selectedWorkers.forEach( (worker) => {
			console.log("Running!");
			addedHours = addedHours + worker.estHours;
			addedCosts = addedCosts + (worker.rate * worker.estHours);
		})
		
		console.log("Total Hours", addedHours);
		setTotalHours(addedHours);
		setTotalCosts(addedCosts);
	}, [selectedWorkers]);

	// Scroll to top on first load
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return(
		<>
			<div className="lg:max-w-full mx-auto">
				<div className="bg-white overflow-hidden shadow-lg sm:rounded-lg text-left rounded-lg">
					<div className="px-4 py-5 sm:p-6 mx-10 my-10">						
						{/* <QurbieLogo className="w-48 mt-20 ml-auto mr-auto" /> */}
						
						<div className="px-4 py-5 sm:px-6 mx-auto text-center">
							<p className="text-3xl leading-6 font-semibold text-gray-700">Create Project: Add Workers</p>
							<p className="mt-4 text-md text-gray-500">Add existing workers to your project or a add a new one to your team</p>
						</div>

						{/* Progress Bar */}
						<div className="mt-20 lg:ml-20 lg:mr-20">
							<nav aria-label="Progress">
								<ol className="space-y-4 md:flex md:space-y-0 md:space-x-8">
									{steps.map((step) => (
										<li key={step.name} className="md:flex-1">
											{step.status === 'complete' ? (
												<a href={step.href} className="group pl-4 py-2 flex flex-col border-l-4 border-green-600 hover:border-green-800 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4">
													<span className="text-xs text-green-600 font-semibold tracking-wide uppercase group-hover:text-green-800">
														{step.id} | {step.name}
													</span>
													{/* <span className="text-sm font-medium text-green-600">{step.name}</span> */}
												</a>
											) : step.status === 'current' ? (
												<a href={step.href} className="pl-4 py-2 flex flex-col border-l-4 border-blue-600 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4" aria-current="step">
													<span className="text-xs text-blue-600 font-semibold tracking-wide uppercase">{step.id} | {step.name}</span>
													{/* <span className="text-sm font-medium">{step.name}</span> */}
												</a>
											) : (
												<a href={step.href} className="group pl-4 py-2 flex flex-col border-l-4 border-gray-200 hover:border-gray-300 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4">
													<span className="text-xs text-gray-500 font-semibold tracking-wide uppercase group-hover:text-gray-700">
														{step.id} | {step.name}
													</span>
													{/* <span className="text-sm font-medium">{step.name}</span> */}
												</a>
											)}
										</li>
									))}
								</ol>
							</nav>
						</div>						
						
						{/* Form Field */}
						<form className="space-y-8 divide-gray-200 lg:px-20 lg:py-20">
							<div className="space-y-8 divide-gray-200">								

								{/* Select Workers */}
								<div className="text-lg leading-6 font-medium text-gray-900 text-left">
									Add an Existing Worker to This Project
									<p className="mt-1 text-sm text-gray-500">Select from your existing workers or add a new one.</p>

									{/* Select a Client */}
									<div className="mt-5 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
										<div className="sm:col-span-full md:col-span-2 lg:col-span-3">
											<DropdownWorkers />
										</div>
										<div className="sm:col-span-1 lg:col-span-2">
											<input
												type="number"
												name="hours"
												id="hours"
												autoComplete="hours"
												placeholder="Est. Hours"
												className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md" />	
										</div>
										<div className="sm:col-span-1 lg:col-span-1">
											<button
												type="submit"
												className="w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
												Add
											</button>
										</div>
									</div>
								</div>

								<div className="flex flex-col">
									<div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
										<div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
											<div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
												<table className="min-w-full divide-y divide-gray-200">
													<thead className="bg-gray-50">
														<tr>
															<th scope="col"
																className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
																Name
															</th>
															<th scope="col"
																className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
																Skill Set
															</th>
															<th scope="col"
																className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
																Rate
															</th>
															<th scope="col"
																className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
																Est. Hours
															</th>
															<th scope="col"
																className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
																Total Cost
															</th>
															<th scope="col" className="relative px-6 py-3">
																<span className="sr-only">Edit</span>
															</th>
														</tr>
													</thead>
													<tbody>
														{selectedWorkers.map((worker, workerIndex) => (
															<tr key={worker.id} className={workerIndex % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
																<td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{worker.name}</td>
																<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{worker.type}</td>
																<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
																	<NumberFormat value={worker.rate} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale prefix={'$'} />
																</td>
																<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{worker.estHours}</td>
																<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
																	<NumberFormat value={(worker.estHours * worker.rate)} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale prefix={'$'} />
																</td>
																<td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
																	<a href="#edit" className="text-blue-600 hover:text-blue-900">
																		<TrashIcon className="h-4 text-blue-600" />
																	</a>
																</td>
															</tr>
														))}
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>

								<div className="col-span-full">
									<dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">										
										<div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
											<dt className="text-sm font-medium text-gray-500 truncate">TOTAL WORKERS</dt>
											<dd className="mt-1 text-3xl font-semibold text-gray-900">{selectedWorkers.length}</dd>
										</div>
										
										<div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
											<dt className="text-sm font-medium text-gray-500 truncate">PROJECT HOURS</dt>
											<dd className="mt-1 text-3xl font-semibold text-gray-900">{totalHours}</dd>
										</div>

										<div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
											<dt className="text-sm font-medium text-gray-500 truncate">EST. COSTS</dt>
											<dd className="mt-1 text-3xl font-semibold text-gray-900"><NumberFormat value={totalCosts} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale prefix={'$'} /></dd>
										</div>
									</dl>
								</div>

								<div className="col-span-full">
									<Divider />
								</div>								


								<div className="col-span-full">
									<Divider />
								</div>								
							</div>

							<div className="pt-5">
								<div className="flex justify-end">
									<button
										type="button"
										className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
										Back
									</button>
									<button
										type="submit"
										className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
										onClick={()=> props.nextCallback()}>
										Review Project
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>			
			</div>
		</>
	)
}