export default function Divider() {

    return(     
        <>   
            <div className="flex items-center mt-10 mb-10" aria-hidden="true">
                <div className="w-full border-t border-gray-300" />
            </div>  
        </>
    )
}

