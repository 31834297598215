import { Fragment, useState, useEffect } from 'react'
import { useParams, useNavigate, Link } from "react-router-dom";
import validator from 'validator';
import { ReactComponent as QurbieLogo } from '../assets/Qurbie.svg'
import ModalDialog from './ModalDialog';
import ModalIndeterminate from './ModalIndeterminate';
import { BellIcon } from '@heroicons/react/24/outline';
import Parse from 'parse';

export default function SignUpAccount() {
	const navigate = useNavigate();
	const [email, setEmail] = useState();
	const [firstName, setFirstName] = useState();
	const [lastName, setLastName] = useState();
	const [password, setPassword] = useState();
	const [confirmPassword, setConfirmPassword] = useState();

	const [alert, setAlert] = useState({showAlert: false});
	const [progress, setProgress] = useState({show: false});

	const handleCreateAccount = () => {
		if (!email || !validator.isEmail(email)) {
			setAlert({showAlert: true, title: 'Invalid Email', message: 'Please enter a valid email and try again.'});
			return;
		}

		if ((!firstName || firstName.length <= 0) || (!lastName || lastName?.length <= 0)) {
			setAlert({showAlert: true, title: 'Full Name Required', message: 'Please enter your first and last name. Then try again.'});
			return;
		}

		if (!password || !confirmPassword || password !== confirmPassword || password.length < 6) {
			setAlert({showAlert: true, title: 'Password Error', message: 'Please enter a password and confirm it. Your passwords must match and be at least 6 characters long. Please try again.'});
			return;
		}		

		const newUser = new Parse.User();
		newUser.set('email', email);
		newUser.set('username', email);
		newUser.set('password', confirmPassword);
		newUser.set('firstName', firstName);
		newUser.set('lastName', lastName);
		
		setProgress({show: true, title: 'Creating Account'});
		newUser.signUp().then((savedUser) => {
			console.log("Created Account!");
			setProgress({show:false});
			navigate('/getting-started', {replace: false});
		}).catch(error => {
			setProgress({show:false});
			console.log("Error!", error);
			setAlert({showAlert: true, title: 'Unable to Create Account', message: 'Unable to create an account.' + error});
		});			
	}

	return (
		<>
			<ModalDialog show={alert.showAlert || false} title={alert.title} message={alert.message}
				confirmButtonTitle={alert.okTitle || "Ok"} cancelButtonTitle={alert.cancelTitle} icon={<BellIcon className="text-blue-500" />}
				confirmButtonFunc={() => setAlert({showAlert:false})} />

			<ModalIndeterminate show={progress.show} title={progress.title || ''} message={progress.message || ''} />

			<div className="bg-white min-h-full w-1/2 flex flex-col justify-center p-20 mx-auto my-20 rounded-2xl">	

				<div className="sm:mx-auto sm:w-full sm:max-w-md">
					<QurbieLogo className="mx-auto h-12 w-auto" />
					<h2 className="mt-6 text-center text-2xl font-extrabold text-gray-700">Create an Account</h2>
					<p className="mt-2 mb-10 text-center text-sm text-gray-600">
						or {' '}
						<a href="/sign-in" className="font-medium text-blue-600 hover:text-blue-500">
							Sign In {' '}
						</a>
						if you already have an account
					</p>
				</div>

				<form className="space-y-8 divide-y divide-gray-200">
					<div className="space-y-8 divide-y divide-gray-200">						
						<div className="pt-8">							
							<div>
								<h3 className="text-lg leading-6 font-medium text-gray-900">Account Information</h3>
								<p className="mt-1 text-sm text-gray-500">Enter your name, email, and password to create your account</p>
							</div>

							<div className="my-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
								{/* Email Input Field */}
								<div className="sm:col-span-full">
									<label htmlFor="email" className="block text-sm font-medium text-gray-700">
										Email address
									</label>
									<div className="mt-1">
										<input
											id="email"
											name="email"
											type="email"
											autoComplete="email"
											className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
											onChange={e => setEmail(e.target.value)}
										/>
									</div>
								</div>
							</div>

							{/* User Name */}
							<div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 ">
								<div className="sm:col-span-3">
									<label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
										First name
									</label>
									<div className="mt-1">
										<input
											type="text"
											name="first-name"
											id="first-name"
											autoComplete="given-name"
											className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
											onChange={e => setFirstName(e.target.value)}
										/>
									</div>
								</div>

								<div className="sm:col-span-3">
									<label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
										Last name
									</label>
									<div className="mt-1">
										<input
											type="text"
											name="last-name"
											id="last-name"
											autoComplete="family-name"
											className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
											onChange={e => setLastName(e.target.value)}
										/>
									</div>
								</div>
							</div>

							<div className="my-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">							

								{/* Password Input Field */}
								<div className="sm:col-span-3">
									<label htmlFor="password" className="block text-sm font-medium text-gray-700">
										Password
									</label>
									<div className="mt-1">
										<input
											id="password"
											name="password"
											type="password"
											autoComplete="password"
											className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
											onChange={e => setPassword(e.target.value)}
										/>
									</div>
								</div>

								{/* Confirm Password Field */}
								<div className="sm:col-span-3">
									<label htmlFor="confirm-password" className="block text-sm font-medium text-gray-700">
										Confirm Password
									</label>
									<div className="mt-1">
										<input
											id="confirm-password"
											name="confirm-password"
											type="password"
											autoComplete="confirm-password"
											className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
											onChange={e => setConfirmPassword(e.target.value)}
										/>
									</div>
								</div>
							
								{/* Service Agreement */}
								<div className="col-span-full">
									<div className="text-gray-900 lg:mr-20">
										By registering your account, you agree to the <a href="/terms-privacy" className="font-bold text-blue-500" target="_bank" rel="noreferrer">Qurbie Services Agreement</a> and our financial partner, <a href="https://stripe.com/connect-account/legal/full"  className="font-bold text-blue-500" target="_blank" rel="noreferrer">Stripe's Connected Account Agreement</a>.
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="pt-5">
						<div className="flex justify-end">
							<button
								type="button"
								className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
								onClick={ e => {
									e.preventDefault();
									window.location.href="https://qurbie.com"
								}}
							>
								Cancel
							</button>
							<button
								type="submit"
								className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
								onClick={ e => {
									e.preventDefault();
									
									handleCreateAccount();
								}}>
								Create Account
							</button>
						</div>
					</div>
				</form>
			</div>
		</>
	)
}