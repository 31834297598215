import { Fragment, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { Outlet, Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { changeNavState } from '../app/NavigationSlice';

import {
	BellIcon,
	CogIcon,
	FolderIcon,
	HomeIcon,
	InboxArrowDownIcon,
	Bars3BottomLeftIcon,
	UsersIcon,
	XMarkIcon,
	QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline'

import DashboardStats from '../components/Old/DashboardStats';
import DashboardWorkers from '../components/DashboardWorkers';
import DashboardInvoices from '../components/Old/DashboardInvoices';
import DashboardSettings from '../components/DashboardSettings';

import * as Constants from '../model/index';
import DashboardProjects from '../components/Old/DashboardProjects';
import NewProjectView from './NewProjectView';
import AddNewWorker from '../components/AddNewWorker';
import AddNewWorkerReview from '../components/AddNewWorkerReview';
import Divider from '../components/Divider';

import { ReactComponent as QurbieLogo} from '../assets/Qurbie.svg'

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

function getDashboardTitle(props) {
	if (props.teamView) {
		return "Team Stats"
	} else if (props.projectsView) {
		return "Projects Overview"
	} else if (props.invoicesView) { 
		return "Invoices Overview"
	} else if (props.settingsView) { 
		return "Account Settings"
	} else if (props.newProjectView) {
		return ""
	} else if (props.addWorkerView) {
		return ""
	} else if (props.addWorkerReviewView) {	
		return ""
	} else {
		return "Dashboard Overview"
	}
}

function getDashboardView(props) {
	if (props.teamView) {
		return <DashboardWorkers />
	} else if (props.projectsView) {
		return <DashboardProjects />
	} else if (props.invoicesView) { 
		return <DashboardInvoices />
	} else if (props.settingsView) { 
		return <DashboardSettings />
	} else if (props.newProjectView) {
		return <NewProjectView />
	} else if (props.addWorkerView) {
		return <AddNewWorker />
	} else if (props.addWorkerReviewView) {	
		return <AddNewWorkerReview />
	} else {
		return <DashboardStats />
	}
}

export default function Dashboard(props) {
	const [sidebarOpen, setSidebarOpen] = useState(false)

	const navState = useSelector((state) => state.navState.value)
	const dispatch = useDispatch()
  
	const navigate = useNavigate();
	
	return (
		<>
			<div>
				{/* Sidebar Mobile */}
				<Transition.Root show={sidebarOpen} as={Fragment}>
					<Dialog as="div" className="fixed inset-0 flex z-40 md:hidden" onClose={setSidebarOpen}>
						<Transition.Child
							as={Fragment}
							enter="transition-opacity ease-linear duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="transition-opacity ease-linear duration-300"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
						</Transition.Child>
						<Transition.Child
							as={Fragment}
							enter="transition ease-in-out duration-300 transform"
							enterFrom="-translate-x-full"
							enterTo="translate-x-0"
							leave="transition ease-in-out duration-300 transform"
							leaveFrom="translate-x-0"
							leaveTo="-translate-x-full"
						>
							<div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
								<Transition.Child
									as={Fragment}
									enter="ease-in-out duration-300"
									enterFrom="opacity-0"
									enterTo="opacity-100"
									leave="ease-in-out duration-300"
									leaveFrom="opacity-100"
									leaveTo="opacity-0"
								>
									<div className="absolute top-0 right-0 -mr-12 pt-2">
										<button
											type="button"
											className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
											onClick={() => setSidebarOpen(false)}
										>
											<span className="sr-only">Close sidebar</span>
											<XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
										</button>
									</div>
								</Transition.Child>
								<div className="flex-shrink-0 flex items-center px-4">
									<QurbieLogo className="h-8 w-auto" />									
								</div>
								<div className="mt-5 flex-1 h-0 overflow-y-auto">
									<nav className="px-2 space-y-1">
										{Constants.navigation.map((item) => (
											<Link 
												key={item.name}
												className={classNames(
													item.current
														? 'bg-blue-300 text-gray-900'
														: 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
													'group flex items-center px-2 py-2 text-base font-medium rounded-md'
												)}
												to={item.href}>
													<item.icon
													className={classNames(
														item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
														'mr-4 flex-shrink-0 h-6 w-6'
													)}
													aria-hidden="true"
												/>
												{item.name}
											</Link> 											
										))}
									</nav>
								</div>
							</div>
						</Transition.Child>
						<div className="flex-shrink-0 w-14" aria-hidden="true">
							{/* Dummy element to force sidebar to shrink to fit close icon */}
						</div>
					</Dialog>
				</Transition.Root>


				{/* Sidebar Desktop */}
				<div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
					{/* Sidebar component, swap this element with another sidebar if you like */}
					<div className="flex flex-col flex-grow border-r border-gray-200 pt-5 bg-white overflow-y-auto">
						<div className="flex items-center flex-shrink-0 px-4">
							<QurbieLogo className="h-12 w-auto" />
							{/* <img className="h-8 w-auto"
								src="https://getprofitr.com/wp-content/uploads/2021/08/Qurbie.svg"
								alt="Workflow"
							/> */}
						</div>
						<div className="mt-5 flex-grow flex flex-col">
							<nav className="flex-1 px-2 pb-4 space-y-1">							

								{/* Dashboard */}
								<Link className={classNames(
										//item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
										navState === 'Dashboard' ? 'bg-blue-100 text-gray-900' : 'text-gray-600 hover:bg-blue-50 hover:text-gray-900',
										'group flex items-center px-2 py-2 text-sm font-medium rounded-md w-full'
									)}
									to='/'
									onClick={() => dispatch(changeNavState('Dashboard'))}
								>
									<HomeIcon
										className={classNames(
											navState === 'Dashboard' ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
											'mr-3 flex-shrink-0 h-6 w-6'
										)}
										aria-hidden="true"
									/>
									Dashboard
								</Link>

								{/* Team */}
								<Link className={classNames(
										//item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
										navState === 'Team' ? 'bg-blue-100 text-gray-900 w-full' : 'text-gray-600 hover:bg-blue-50 hover:text-gray-900',
										'group flex items-center px-2 py-2 text-sm font-medium rounded-md w-full'
									)}
									to='/workers'
									onClick={() => dispatch(changeNavState('Team'))}
								>
									<UsersIcon
										className={classNames(
											navState === 'Team' ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
											'mr-3 flex-shrink-0 h-6 w-6'
										)}
										aria-hidden="true"
									/>
									Team
								</Link>

								{/* Projects */}
								<Link className={classNames(
										//item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
										navState === 'Projects' ? 'bg-blue-100 text-gray-900 w-full' : 'text-gray-600 hover:bg-blue-50 hover:text-gray-900',
										'group flex items-center px-2 py-2 text-sm font-medium rounded-md w-full'
									)}
									to='/projects'
									onClick={() => dispatch(changeNavState('Projects')) }
								>
									<FolderIcon
										className={classNames(
											navState === 'Projects' ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
											'mr-3 flex-shrink-0 h-6 w-6'
										)}
										aria-hidden="true"
									/>
									Projects
								</Link>

								{/* Invoices */}
								<Link className={classNames(
										//item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
										navState === 'Invoices' ? 'bg-blue-100 text-gray-900 w-full' : 'text-gray-600 hover:bg-blue-50 hover:text-gray-900',
										'group flex items-center px-2 py-2 text-sm font-medium rounded-md w-full'
									)}
									to='/invoices'
									onClick={() => dispatch(changeNavState('Invoices'))}
								>
									<InboxArrowDownIcon
										className={classNames(
											navState === 'Invoices' ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
											'mr-3 flex-shrink-0 h-6 w-6'
										)}
										aria-hidden="true"
									/>
									Invoices
								</Link>

								<Divider />

								{/* Settings */}
								<Link className={classNames(
										//item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
										navState === 'Settings' ? 'bg-blue-100 text-gray-900 w-full' : 'text-gray-600 hover:bg-blue-50 hover:text-gray-900',
										'group flex items-center px-2 py-2 text-sm font-medium rounded-md w-full'
									)}
									to='/settings'
									onClick={() => dispatch(changeNavState('Settings'))}
								>
									<CogIcon
										className={classNames(
											navState === 'Settings' ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
											'mr-3 flex-shrink-0 h-6 w-6'
										)}
										aria-hidden="true"
									/>
									Settings
								</Link>

								{/* Help */}
								<Link className={classNames(
										//item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
										navState === 'Settings' ? 'bg-blue-100 text-gray-900 w-full' : 'text-gray-600 hover:bg-blue-50 hover:text-gray-900',
										'group flex items-center px-2 py-2 text-sm font-medium rounded-md w-full'
									)}
									to='/help'
									onClick={() => dispatch(changeNavState('Settings'))}
								>
									<QuestionMarkCircleIcon
										className={classNames(
											navState === 'Settings' ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
											'mr-3 flex-shrink-0 h-6 w-6'
										)}
										aria-hidden="true"
									/>
									Help
								</Link>

							</nav>
						</div>
					</div>
				</div>
				<div className="md:pl-64 flex flex-col flex-1">
					<div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
						<button
							type="button"
							className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 md:hidden"
							onClick={() => setSidebarOpen(true)}
						>
							<span className="sr-only">Open sidebar</span>
							<Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
						</button>
						<div className="flex-1 px-4 flex justify-between">
							<div className="flex-1 flex">

							</div>
							<div className="ml-4 flex items-center md:ml-6">
								<button
									type="button"
									className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
								>
									<span className="sr-only">View notifications</span>
									<BellIcon className="h-6 w-6" aria-hidden="true" />
								</button>

								{/* Profile dropdown */}
								<Menu as="div" className="ml-3 relative">
									<div>
										<Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
											<span className="sr-only">Open user menu</span>
											<img
												className="h-8 w-8 rounded-full"
												src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
												alt=""
											/>
										</Menu.Button>
									</div>
									<Transition
										as={Fragment}
										enter="transition ease-out duration-100"
										enterFrom="transform opacity-0 scale-95"
										enterTo="transform opacity-100 scale-100"
										leave="transition ease-in duration-75"
										leaveFrom="transform opacity-100 scale-100"
										leaveTo="transform opacity-0 scale-95"
									>
										{/* Profile sub menu */}
										<Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
											{Constants.userNavigation.map((item) => (
												<Menu.Item key={item.name}>
													{({ active }) => (
														<a
															href={item.href}
															className={classNames(
																active ? 'bg-blue-100' : '',
																'block px-4 py-2 text-sm text-gray-700'
															)}
														>
															{item.name}
														</a>
													)}
												</Menu.Item>
											))}
										</Menu.Items>
									</Transition>
								</Menu>
							</div>
						</div>
					</div>

					<main className="flex-1">
						<div className="py-6">
							<div className="max-w-full m-10 px-4 sm:px-6 md:px-8">
								<h1 className="text-4xl font-semibold text-gray-900 text-left mb-10">
									{ getDashboardTitle(props) }
								</h1>
							</div>
							<div className="max-w-full m-10 px-4 sm:px-6 md:px-8">
								{/* Replace with your content */}

								{ getDashboardView(props) }

								{/* 
								<div className="py-4">
									<div className="border-4 border-dashed border-gray-200 rounded-lg h-96" />
								</div> */}
								{/* /End replace */}
							</div>
						</div>
					</main>
				</div>
			</div>			
		</>
	)
}