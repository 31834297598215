import { Fragment, useEffect, useState } from 'react'

import { PlayIcon, PauseIcon, ArrowPathIcon, ArrowDownOnSquareIcon } from '@heroicons/react/20/solid';

import { useSelector, useDispatch } from 'react-redux'
import { changeClockTimeSaved } from '../app/ClockTimeSavedSlice';


import Parse from 'parse';
import TimeSaver from './TimeSaver';

export default function Timer() {

    const [isActive, setIsActive] = useState(false);
    const [isPaused, setIsPaused] = useState(true);
    const [seconds, setSeconds] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [hours, setHours] = useState(0);
    
    const [showTimeSaver, setShowTimeSaver] = useState(false);

    const clockTimeSavedState = useSelector((state) => state.clockTimeSavedState);
    
    const dispatch = useDispatch();

    useEffect(() => {
        let interval = null;

        if (isActive && isPaused === false) {
            console.log("Started interval");
            interval = setInterval(() => {
                
                setSeconds((secs) => {
                    if (secs + 1 > 59) {                        
                        setMinutes((mins) => {
                            if (mins + 1 > 59) {
                                setHours((hours) => hours + 1);
                                return 0;
                            }

                            return mins + 1;
                        });

                        return 0;
                    } 

                    return secs + 1;
                });

            }, 10);
        } else {
            clearInterval(interval);
        }
        
        return () => {
            clearInterval(interval);
        };

    }, [isActive, isPaused]);

    const handleStart = () => {
        setIsActive(true);
        setIsPaused(false);
    };

    const handlePauseResume = () => {
        setIsPaused(!isPaused);
    };

    const handleReset = () => {
        setIsActive(false);
        setSeconds(0);
        setMinutes(0);
        setHours(0);
    };


    const handleSaveTime = () => {
        // pause clock
        setIsActive(false);

        // save to state for other components to do things with it
        const timeInMins = (hours * 60) + minutes;
        const dateString = new Date().toLocaleDateString("en-US");
        console.log("Total Time in Mins: ", timeInMins)
        dispatch(changeClockTimeSaved({minutes: timeInMins, date: dateString}));
    }


    return (
        <>
            <div className='w-full bg-black px-4 pt-8 pb-0 font-["DSEG7Classic"] text-green-400 text-4xl text-center'>                
                {hours < 10 ? `0${hours}` : `${hours}`}:{minutes < 10 ? `0${minutes}` : `${minutes}`}:{seconds < 10 ? `0${seconds}` : `${seconds}`}
                <div className='flex justify-center mt-2'>
                    {(!isActive || isPaused ) &&
                        <div className='flex-col mb-4 mx-4'>                        
                            <button                            
                                onClick={() => handleStart()}>
                                <PlayIcon className='text-green-600 hover:text-green-300 w-8 h-8' />                        
                            </button>
                            <div className='text-xs text-green-600 font-mono font-bold'>Start</div>
                        </div>
                    }
                    {(isActive && !isPaused) &&
                        <div className='flex-col mb-4 mx-4'>
                            <button                        
                                onClick={() => handlePauseResume()}>
                                <PauseIcon className='text-blue-600 hover:text-blue-300 w-8 h-8' />
                            </button>
                            <div className='text-xs text-blue-600 font-mono font-bold'>Pause</div>
                        </div>
                    }
                    <div className='flex-col mb-4 mx-4'>                    
                        <button                        
                            onClick={() => handleReset()}>
                            <ArrowPathIcon className='text-red-600 hover:text-red-300 w-8 h-8' />
                        </button>
                        <div className='text-xs text-red-600 font-mono font-bold'>Reset</div>
                    </div>
                    <div className='flex-col mb-4 mx-4'>                    
                        <button                        
                            onClick={() => handleSaveTime()}>
                            <ArrowDownOnSquareIcon className='text-yellow-600 hover:text-red-300 w-8 h-8' alt='Save Time' />
                        </button>
                        <div className='text-xs text-yellow-600 font-mono font-bold'>Save</div>
                    </div>
                </div>                
            </div>   

            {/* <TimeSaver show={showTimeSaver} hours={19} rate={75} date={'3/11/22'} callback={() => { */}
                {/* setShowTimeSaver(false); */}
            {/* }} /> */}
        </>
    );
}