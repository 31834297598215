
import { Fragment, useState, useEffect } from 'react'
import { useParams, useNavigate } from "react-router-dom";
import validator from 'validator';

import { ReactComponent as QurbieLogo } from '../assets/Qurbie.svg'
import ModalOverlay from './ModalOverlay';

import NumberFormat from 'react-number-format'

import Parse from 'parse';

import ModalDialog from './ModalDialog';
import ModalIndeterminate from './ModalIndeterminate';
import { BellIcon } from '@heroicons/react/24/outline';
import { ArrowSmallUpIcon, ClockIcon, CurrencyDollarIcon } from '@heroicons/react/20/solid';


function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

export default function PaymentRequest(props) {
	let {requestId} = useParams();
	let navigate = useNavigate();
	const [alert, setAlert] = useState({show: false});
	const [progress, setProgress] = useState({show: false});
	const [paymentRequest, setPaymentRequest] = useState();

	// get request details	
	useEffect(() => {
		// grab requestId from url via react-router
		if (!requestId) {
			console.log("Request Id Not Found");
			return;
		}

		getPaymentRequest(requestId);
		
	}, [requestId]);

	const getPaymentRequest = (requestId) => {
		console.log("Running getPaymentRequestWithId", requestId);
		Parse.Cloud.run('getPaymentRequestWithId', {requestId: requestId})
			.then((result) => {			
				if (result?.payeeId) {					
					console.log("getPaymentRequestWithId result:", result);
					setPaymentRequest(result);					
				} else {
					console.log('getPaymentRequestWithId error:', result);

					setAlert({
						show: true, 
						title: 'Payment Request Not Found', 
						message: "This payment request is invalid or has already been completed. If this is an error, please review your 'Payments' tab in the Dashboard.", 
						//confirmFunc: (()=>navigate("/", {replace: true}))
					})
				}
			})
			.catch((error) => {
				console.log('getPaymentRequestWithId error:', error);
				setAlert({
					show: true, 
					title: 'Payment Request Not Found', 
					message: "This payment request is invalid or has already been completed. If this is an error, please review your 'Payments' tab on the Dashboard.", 
					//confirmFunc: (()=>navigate("/", {replace: true}))
				})
			});		
	}


	const handleCompletePayment = () => {
		Parse.Cloud.run("CompletePayment", {paymentId: paymentRequest.paymentId})
			.then((result) => {		
				console.log("CompletePayment Result:", result);		
				setAlert({
					show: true,
					title: 'Payment Completed',
					message: "This payment has been posted and will be deposited in the worker's account within 1-3 days.",
					confirmFunc: (() => {
						getPaymentRequest(requestId); // refresh to view the completed status
						setAlert({show: false});						
					})
				});
			})
			.catch((error) => {
				console.log("Error completing payment:", error);
				setAlert({
					show: true,
					title: 'Payment Request Not Found',
					message: `The payment was not able to be completed. Please contact support for assitance on this payment. Error: ${error}`					
				});
			})
	}


	return (
		<>
			<ModalDialog show={alert.show || false} title={alert.title} message={alert.message}	
				confirmButtonTitle={alert.okTitle || "Ok"} cancelButtonTitle={alert.cancelTitle} icon={<BellIcon className="text-blue-500" />}
				confirmButtonFunc={alert.confirmFunc || (() => setAlert({showAlert:false}))} />

			<ModalIndeterminate show={progress.show} title={progress.title || ''} message={progress.message || ''} />

			<div className="bg-white min-h-full w-1/2 flex flex-col justify-center p-0 mx-auto my-20 rounded-2xl">	
				<div className="sm:mx-auto sm:w-full sm:max-w-md">
					<QurbieLogo className="mx-auto h-12 w-auto" />
					{paymentRequest?.status === 'Completed' ? 
						<h2 className="mt-6 text-center text-2xl font-extrabold text-gray-700">Payment <span className='text-green-500'>Completed</span> for {paymentRequest?.payeeName || ''} </h2>
						:
						<h2 className="mt-6 text-center text-2xl font-extrabold text-gray-700">Payment Requested by {paymentRequest?.payeeName || ''} </h2>
					}

				</div>

				{/* Payment Request Information */}
				<form className="space-y-8 divide-y divide-gray-200">
					<div className="space-y-8 divide-y divide-gray-200">
						<div className="pt-10">
							
							<div>
								<h3 className="text-lg leading-6 font-medium text-gray-900">Payment Request Details</h3>
								
								<p className="mt-1 text-sm text-gray-500">Please review the following payment request. Then approve the payment or contact the worker.</p>
							</div>						
							
							<div className="my-6 grid grid-cols-1 lg:grid-cols-2 gap-y-6 gap-x-4">

								{/* Total Hours */}
								<div className="col-span-1 bg-white pt-5 px-4 pb-5 sm:pt-6 sm:px-6 shadow-md rounded-lg overflow-hidden">
									<dt>
										<div className="absolute bg-blue-500 rounded-md p-3">
											<ClockIcon className="h-6 w-6 text-white" aria-hidden="true" />
										</div>
										<p className="ml-16 text-sm font-medium text-left text-gray-500 truncate">Hours Worked</p>
									</dt>
									<dd className="ml-16 flex items-baseline">
										<p className="text-2xl font-semibold text-gray-900">{paymentRequest?.hours || ''}</p>									
									</dd>
								</div>

								{/* Amount Requested */}
								<div className="col-span-1 bg-white pt-5 px-4 pb-5 sm:pt-6 sm:px-6 shadow-md rounded-lg overflow-hidden">
									<dt>
										<div className="absolute bg-blue-500 rounded-md p-3">
											<CurrencyDollarIcon className="h-6 w-6 text-white" aria-hidden="true" />
										</div>
										<p className="ml-16 text-sm font-medium text-left text-gray-500 truncate">Total {paymentRequest?.status === "Completed" ? 'Paid' : 'Requested'}</p>
									</dt>
									<dd className="ml-16 flex items-baseline">
										<p className="text-2xl font-semibold text-gray-900">											
											{parseFloat(paymentRequest?.amountRequested).toLocaleString('en-US', {style: 'currency', currency: 'USD'}) || ''}
										</p>
									</dd>
								</div>
								
							</div>
							

							<div className="w-full mt-8">
								<p className="font-bold">Summary of the Work:</p> 
								{paymentRequest?.title || ''}								
							</div>

							{/* TODO: Show full work log of all items that go into this payment request. */}							
							{/* <div>View Full Work Log</div> */}

							<div className="mt-8 flex justify-end">
								<a href="/payments" className=' text-blue-400 font-bold'>View All Payments and Requests</a>
							</div>
						</div>
					</div>
					<div className="pt-5">
						<div className="flex justify-end">
							<button
								type="button"
								className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"								
							>
								Contact {paymentRequest?.payeeName || ''}
							</button>

							{paymentRequest?.status === "Completed" &&
								<button
									type="submit"
									className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
									onClick={e => {
										e.preventDefault();
										navigate('/dashboard');
									}}									
								>
									Payment Completed
								</button>
							}

							{paymentRequest?.status !== "Completed" &&
								<button
									type="submit"
									className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"									
									onClick={e => {
										e.preventDefault();

										handleCompletePayment();
									}}
								>
									Approve Payment
								</button>
							}
						</div>
					</div>										
				</form>
			</div>
		</>
	)
}