import { Fragment, useState, useEffect } from 'react'
import { Listbox, Transition, Switch } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon, ClipboardDocumentListIcon, PaperClipIcon } from '@heroicons/react/20/solid'
import { PencilSquareIcon, TrashIcon, PlusIcon } from '@heroicons/react/24/outline'
import { ReactComponent as QurbieLogo} from '../../assets/Qurbie.svg'
import NumberFormat from 'react-number-format'
import DropdownWorkers from './DropdownWorkers'
import Divider from '../Divider'
import { DateTime } from 'luxon'

import { StateOptions, CountryOptions } from '../../model/Constants';

const steps = [
	{ id: 'Step 1', name: 'Select Client', href: '#', status: 'complete' },
	{ id: 'Step 2', name: 'Project Details', href: '#', status: 'complete' },
	{ id: 'Step 3', name: 'Add Workers', href: '#', status: 'complete' },
	{ id: 'Step 4', name: 'Send Rquest', href: '#', status: 'current' },
]

const project = {
	title: 'Duffek Website Refresh',
	description: "The Duffek website must be overhauled and better represent the Duffek brand as well as it's capabilities.",
	dueDate: DateTime.now().plus({weeks: 1}),
	estimate: 2404,
	estimateHours: 345,
	status: 'On Track',
	owner: 'David Nelson',
	tasks: [
		{ id: '1', title: 'Complete Design', description: 'Finish designing the interface of the application.' },
		{ id: '2', title: 'Implement Interface', description: 'Implement the designs and build the UI in Tailwind CSS using the Tailwind UI components to make this process faster.' },
		{ id: '3', title: 'Develop MVP', description: 'Build out each component one at a time and test them to ensure they are working correctly. Then put them together in one nice designed web application.' },
	],
	workers: [
		{ id: 1, name: 'David Nelson', rate: 90.00, estHours: 50, type: 'Developer' },
		{ id: 2, name: 'Chelsea Bill', rate: 65.00, estHours: 22, type: 'Designer' },
	],
	client: {
		businessName: 'Duffek Mobile',
		email: 'david@duffekmobile.com',
		phone: '8123714370',
		contactFirstName: 'David',
		contactLastName: 'Nelson',
		billingRate: 125,
		taxId: '123456789',	
		address1: '404 S Roan St',
		address2: 'Suite 44',
		city: 'Johnson City',
		state: 'TN',
		zip: '37601',
		country: 'USA',
		sendW9: true
	}
}




function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

export default function NewProject4Review(props) {	
	const [w9SwitchEnabled, setW9SwitchEnabled] = useState(project.client.sendW9);

	// Scroll to top on first load
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return(
		<>
			<div className="lg:max-w-full mx-auto">
				<div className="bg-white overflow-hidden shadow-lg sm:rounded-lg text-left rounded-lg">
					<div className="px-4 py-5 sm:p-6 mx-10 my-10">
						{/* Review of Project */}
						<div>
							{/* <QurbieLogo className="w-48 mt-20 ml-auto mr-auto" /> */}
							
							<div className="px-4 py-5 sm:px-6 mx-auto text-center">
								<p className="text-3xl leading-6 font-semibold text-gray-700">Create Project: Review</p>
								<p className="mt-4 text-md text-gray-500">Please check the information below and submit the project when you are ready</p>
							</div>

							{/* Progress Bar */}
							<div className="mt-10 mb-10 lg:ml-20 lg:mr-20">
								<nav aria-label="Progress">
									<ol className="space-y-4 md:flex md:space-y-0 md:space-x-8">
										{steps.map((step) => (
											<li key={step.name} className="md:flex-1">
												{step.status === 'complete' ? (
													<a href={step.href} className="group pl-4 py-2 flex flex-col border-l-4 border-green-600 hover:border-green-800 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4">
														<span className="text-xs text-green-600 font-semibold tracking-wide uppercase group-hover:text-green-800">
															{step.id} | {step.name}
														</span>
														{/* <span className="text-sm font-medium text-green-600">{step.name}</span> */}
													</a>
												) : step.status === 'current' ? (
													<a href={step.href} className="pl-4 py-2 flex flex-col border-l-4 border-blue-600 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4" aria-current="step">
														<span className="text-xs text-blue-600 font-semibold tracking-wide uppercase">{step.id} | {step.name}</span>
														{/* <span className="text-sm font-medium">{step.name}</span> */}
													</a>
												) : (
													<a href={step.href} className="group pl-4 py-2 flex flex-col border-l-4 border-gray-200 hover:border-gray-300 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4">
														<span className="text-xs text-gray-500 font-semibold tracking-wide uppercase group-hover:text-gray-700">
															{step.id} | {step.name}
														</span>
														{/* <span className="text-sm font-medium">{step.name}</span> */}
													</a>
												)}
											</li>
										))}
									</ol>
								</nav>
							</div>


							{/* Project Details */}
							<div className="border-t border-gray-200 px-4 py-5 sm:px-6">
								<dt className="text-lg font-medium text-gray-900 mb-5">Project Details</dt>
								<dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
									<div className="sm:col-span-full">
										<dt className="text-sm font-medium text-gray-500">Project Title</dt>
										<dd className="mt-1 text-sm text-gray-900">{project.title}</dd>
									</div>
									<div className="sm:col-span-full">
										<dt className="text-sm font-medium text-gray-500">Project Description</dt>
										<dd className="mt-1 text-sm text-gray-900">{project.description}</dd>
									</div>
									<div className="sm:col-span-1">
										<dt className="text-sm font-medium text-gray-500">Estimated Hours</dt>
										<dd className="mt-1 text-3xl font-bold text-blue-500">{project.estimateHours}</dd>
									</div>
									<div className="sm:col-span-1">
										<dt className="text-sm font-medium text-gray-500">Project Estimate</dt>
										<dd className="mt-1 text-3xl font-bold text-blue-500">
											<NumberFormat 
												value={project.estimate} 
												displayType={'text'} 
												thousandSeparator={true} 
												decimalScale={2} 
												fixedDecimalScale 
												prefix={'$'} />
										</dd>
									</div>
									<div className="sm:col-span-1">
										<dt className="text-sm font-medium text-gray-500">Estimated Completion</dt>
										<dd className="mt-1 text-3xl font-bold text-blue-500">{project.dueDate.toLocaleString({month: 'short', day: 'numeric', year: 'numeric'})}</dd>
									</div>
									<div className="sm:col-span-1">
										<dt className="text-sm font-medium text-gray-500">Billing Rate</dt>
										<dd className="mt-1 text-3xl font-bold text-blue-500">
											<NumberFormat 
												value={project.client.billingRate} 
												displayType={'text'} 
												thousandSeparator={true} 
												decimalScale={2} 
												fixedDecimalScale 
												prefix={'$'} /><span className="text-sm">/hr</span>
										</dd>
									</div>
								</dl>
							</div>


							<div className="border-t border-gray-200 px-4 py-5 sm:px-6">
								<dt className="text-lg font-medium text-gray-900 mb-5">Project Tasks</dt>
								<div className="sm:col-span-full">
									{/* Project Task List */}
									<div className="mt-10 mb-10">
										<div className="flow-root">
											<ul className="-mb-8">
												{project.tasks.map((task, taskIndex) => (
													<li key={task.id}>
														<div className="relative pb-8">
													
															{taskIndex !== project.tasks.length - 1 ? (
																<span className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
															) : null}

															<div className="relative flex justify-between">
																<div className="relative flex items-start space-x-3">
																	<div className="relative w-10 h-10 ring-2 bg-white ring-blue-500 rounded-full flex justify-center items-center">
																		<ClipboardDocumentListIcon 
																			className="h-6 w-6 text-blue-500 text-center" />
																	</div>
																	<div className="min-w-0 flex-1">
																		<div>
																			<div className="text-md font-semibold text-gray-600">																		
																				{task.title}																		
																			</div>
																			<p className="mt-0.5 text-sm text-gray-500">{task.description}</p>
																		</div>
																	</div>
																</div>																
															</div>
														</div>
													</li>
												))}
											</ul>
										</div>
									</div>
								</div>
							</div>
									
							
							{/* Workers On Project */}
							<div className="border-t border-gray-200 px-4 py-8 sm:px-6">
								<dt className="text-lg font-medium text-gray-900 mb-5">Workers Requested</dt>
								<div className="flex flex-col">
									<div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
										<div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
											<div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
												<table className="min-w-full divide-y divide-gray-200">
													<thead className="bg-gray-50">
														<tr>
															<th scope="col"
																className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
																Name
															</th>
															<th scope="col"
																className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
																Skill Set
															</th>
															<th scope="col"
																className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
																Rate
															</th>
															<th scope="col"
																className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
																Est. Hours
															</th>
															<th scope="col"
																className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
																Total Cost
															</th>
														</tr>
													</thead>
													<tbody>
														{project.workers.map((worker, workerIndex) => (
															<tr key={worker.id} className={workerIndex % 2 === 0 ? 'bg-blue-50' : 'bg-white'}>
																<td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{worker.name}</td>
																<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{worker.type}</td>
																<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
																	<NumberFormat value={worker.rate} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale prefix={'$'} />
																</td>
																<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{worker.estHours}</td>
																<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
																	<NumberFormat value={(worker.estHours * worker.rate)} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale prefix={'$'} />
																</td>																
															</tr>
														))}
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>


							{/* Client Information */}
							<div className="border-t border-gray-200 px-4 py-5 sm:px-6">
								<dt className="text-lg font-medium text-gray-900 mb-5">Client Information</dt>
								<dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
									<div className="sm:col-span-full">
										<dt className="text-sm font-medium text-gray-500">Client's Business Name</dt>
										<dd className="mt-1 text-sm text-gray-900">{project.client.businessName}</dd>
									</div>
									<div className="sm:col-span-1">
										<dt className="text-sm font-medium text-gray-500">Contact First Name</dt>
										<dd className="mt-1 text-sm text-gray-900">{project.client.contactFirstName}</dd>
									</div>
									<div className="sm:col-span-1">
										<dt className="text-sm font-medium text-gray-500">Contact Last Name</dt>
										<dd className="mt-1 text-sm text-gray-900">{project.client.contactLastName}</dd>
									</div>
									<div className="sm:col-span-1">
										<dt className="text-sm font-medium text-gray-500">Contact Phone Number</dt>
										<dd className="mt-1 text-sm text-gray-900">
											<NumberFormat 
												value={project.client.phone} 
												displayType={'text'} 
												format="(###) ###-####"/>											
										</dd>
									</div>
									<div className="sm:col-span-1">
										<dt className="text-sm font-medium text-gray-500">Contact Email</dt>
										<dd className="mt-1 text-sm text-gray-900">{project.client.email}</dd>
									</div>
									<div className="sm:col-span-1">
										<dt className="text-sm font-medium text-gray-500">Client Address</dt>
										<dd className="mt-1 text-sm text-gray-900">
											{project.client.address1} <br/>
											{project.client.address2 ? project.client.address2 : ''} {project.client.address2 ? <br/> : ''}
											{project.client.city}, {project.client.state} {project.client.zip}
										</dd>
									</div>	
									<div className="sm:col-span-1">
										<dt className="text-sm font-medium text-gray-500">Send Signed W9</dt>
										<dd className="mt-5 ml-5 text-sm text-gray-900">											
											<Switch
												checked={w9SwitchEnabled}
												onChange={setW9SwitchEnabled}
												className={classNames(
													w9SwitchEnabled ? 'bg-blue-600' : 'bg-gray-400',
													'relative inline-flex flex-shrink-0 h-8 w-14 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
												)}>
												<span
													aria-hidden="true"
													className={classNames(
														w9SwitchEnabled ? 'translate-x-6' : 'translate-x-0',
														'pointer-events-none inline-block h-7 w-7 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
													)} />
											</Switch>																					
										</dd>
									</div>								
								</dl>
							</div>


							{/* Bottom Buttons */}
							<div className="pt-5">
								<div className="flex justify-end">
									<button
										type="button"
										className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
										Back
									</button>
									<button
										type="submit"
										className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
										onClick={()=> props.nextCallback()}>
										Approve and Create Project
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>			
			</div>
		</>
	)
}