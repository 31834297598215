import { Fragment, useState, useEffect } from 'react'
import { useParams, useNavigate, Link } from "react-router-dom";
import Parse from 'parse';
import ModalDialog from './ModalDialog';
import ModalIndeterminate from './ModalIndeterminate';
import { BellIcon } from '@heroicons/react/24/outline';
import { ReactComponent as QurbieLogo } from '../assets/Qurbie.svg'
import { BallTriangle } from 'react-loader-spinner'

export default function AccountReauth(props) {
    const [alert, setAlert] = useState({ showAlert: false });
    const [progress, setProgress] = useState({ show: false });

    useEffect(() => {
        // first make sure we have a user signed in
        if (!Parse.User.current()) {
            props.navigate('/sign-in');
            return;
        }

        //now get a new verification link for the account via cloud code
        Parse.Cloud.run('GetStripeReauthLink').then((result) => {
            console.log("Result:", result);
            if (result?.verification?.url) {
                window.location.href = result.verification.url;
            }
        }).catch((error) => {
            console.log("Error!", error);
        });

    }, []);

    return (
        <>
            <div>
                <ModalDialog show={alert.showAlert || false} title={alert.title} message={alert.message}
                    confirmButtonTitle={alert.okTitle || "Ok"} cancelButtonTitle={alert.cancelTitle} icon={<BellIcon className="text-blue-500" />}
                    confirmButtonFunc={() => setAlert({ showAlert: false })} />

                <ModalIndeterminate show={progress.show} title={progress.title || ''} message={progress.message || ''} />

                {/*  */}

                <div className="bg-white min-h-full w-1/2 flex flex-col justify-center p-20 mx-auto my-20 rounded-2xl">

                    <div className="sm:mx-auto sm:w-full sm:max-w-md">
                        <QurbieLogo className="mx-auto h-12 w-auto" />                        
                        <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-700">Initializing Verification</h2>
                        <p className="mt-2 mb-10 text-center text-sm text-gray-600">                                                        
                            We're getting everything ready to begin the verification process...
                        </p>                        
                        <div className="mt-2 flex justify-center">
                            <BallTriangle color="#00BFFF" height={60} width={60} />
                        </div>
                    </div>
                </div>

                    {/*  */}
                </div>
            </>
            )
}