import { Fragment, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { changeClockTimeSaved } from '../app/ClockTimeSavedSlice';
import { loadSettings } from '../util/settingsStorage';

import {
    ClockIcon,
    CreditCardIcon
} from '@heroicons/react/24/outline'

import { ArrowSmallUpIcon, ArrowSmallDownIcon, PlusCircleIcon } from '@heroicons/react/20/solid'

import * as Constants from '../model/index';
import { Link } from 'react-router-dom';
import Timer from './Timer';
import TimeLog from './TimeLog';

import Parse from 'parse';
import TimeSaver from './TimeSaver';
import { changeGeneralState } from '../app/GeneralSlice';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


export default function WorkerPayRequest() {

    const dispatch = useDispatch();
    const clockTimeSavedState = useSelector((state) => state.clockTimeSavedState);    
    const generalState = useSelector((state) => state.generalState);
    const userState = useSelector((state) => state.userState);
    const shouldUpdateWorkLog = useSelector((state) => state.generalState.shouldUpdateWorkLog);    

    const [currentWorkLog, setCurrentWorkLog] = useState([]);
    const [totalHours, setTotalHours] = useState(0);
    const [totalToBePaid, setTotalToBePaid] = useState(0.00);
    const [selectedBusinessId, setSelectedBusinessId] = useState(userState.workingFor[0] || null);
    const [appSettings, setAppSettings] = useState(loadSettings());

    useEffect(()=> {
        if (generalState.shouldUpdateWorkLog) {
            retrieveWorkLog();
            dispatch(changeGeneralState({shouldUpdateWorkLog: false}));
        }
    }, [shouldUpdateWorkLog]);

    // get data on load
    useEffect(()=> {
        retrieveWorkLog();

        //console.log("userState.workingFor:", userState.workingFor);
        if (userState.workingFor?.length > 0) {
            dispatch(changeGeneralState({workLogBusiness: userState.workingFor[0]}));
        }
    }, []);


    const retrieveWorkLog = () => {
        console.log("Retrieving work log from db...");

        if (!appSettings || !appSettings.accountCompany) {
            console.log("Error - no appSettings account comapany.");
            return;
        }

        console.log(`Sending: ${appSettings.accountCompany.id}, ${Parse.User.current().id}`);

        Parse.Cloud.run('GetWorkLogEntries', {businessId: appSettings.accountCompany.id}).then((results) => {
            //console.log("GetWorkLogEntries Results:", results);
            setCurrentWorkLog(results);

            // wait a bit for the state to update
            calcTodaysHours(results);

        }).catch((error) => {
            console.log("Error getting work log entries:", error);
        });
    }

    
    const calcTodaysHours = (workLog) => {
        //console.log("Current Work Log:", workLog);
        let totalHours = 0;
        let toBePaid = 0;
        workLog?.forEach(item => {
            if (item.status === 'unpaid') {
                //console.log(`Item: ${item.workHours/60} hours at $${item.workRate} per hour.`);
                totalHours = totalHours + item.workHours/60;
                let paymentAmount = ((item.workHours.toFixed(2)/60).toFixed(2) * item.workRate);
                
                //console.log(`hours: ${item.workHours/60} * rate: ${item.workRate} = ${paymentAmount}`);
                toBePaid = toBePaid + paymentAmount;
            }
        })

        setTotalHours(totalHours);        
        setTotalToBePaid(toBePaid);
    }

    return (
        <>
            <div>
                <div className='my-4 pl-0 text-xl font-semibold text-gray-900'>
                    Pay Requests and Time Tracking for: 
                    <select className='ml-4 rounded-md border-gray-300 text-xl pl-8 pr-16'
                        onChange={(e) => setSelectedBusinessId(e.target.value)}>
                        {userState.workingFor.map((item, i) => (
                            <option key={i} value={item.id}>{item.businessName}</option>
                        ))}                                        
                    </select>
                </div>

                <h3 className="text-lg leading-6 font-medium text-gray-900">Last 30 days</h3>

                <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">

                    {/* Stat 1 - Today's Hours */}
                    <div className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
                        <dt>
                            <div className="absolute bg-blue-500 rounded-md p-3">
                                <ClockIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </div>
                            <p className="ml-16 text-sm font-medium text-gray-500 truncate">Today's Hours</p>
                        </dt>
                        <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                            <p className="text-2xl font-semibold text-gray-900">
                                {totalHours.toFixed(2)}
                            </p>
                            <p
                                className={classNames(
                                    "increase" === 'increase' ? 'text-green-600' : 'text-red-600',
                                    'ml-2 flex items-baseline text-sm font-semibold'
                                )}
                            >
                                {/* {"increase" === 'increase' ? (
                                    <ArrowSmallUpIcon className="self-center flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
                                ) : (
                                    <ArrowSmallDownIcon className="self-center flex-shrink-0 h-5 w-5 text-red-500" aria-hidden="true" />
                                )}

                                <span className="sr-only">{"increase" === 'increase' ? 'Increased' : 'Decreased'} by</span>
                                {"5.4%"} */}
                            </p>
                            <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
                                <div className="text-sm">
                                    {/* <a href="#adsf" className="font-medium text-blue-600 hover:text-blue-500">
                                        {' '}
                                        View all<span className="sr-only">Today's Hours</span>
                                    </a> */}
                                </div>
                            </div>
                        </dd>
                    </div>

                    {/* Stat 2 - Total to be Paid */}
                    <div className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
                        <dt>
                            <div className="absolute bg-blue-500 rounded-md p-3">
                                <CreditCardIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </div>
                            <p className="ml-16 text-sm font-medium text-gray-500 truncate">Total To Be Paid</p>
                        </dt>
                        <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                            <p className="text-2xl font-semibold text-gray-900">                                    
                                ${totalToBePaid.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                            </p>
                            <p
                                className={classNames(
                                    "increase" === 'increase' ? 'text-green-600' : 'text-red-600',
                                    'ml-2 flex items-baseline text-sm font-semibold'
                                )}
                            >
                                {/* {"increase" === 'increase' ? (
                                    <ArrowSmallUpIcon className="self-center flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
                                ) : (
                                    <ArrowSmallDownIcon className="self-center flex-shrink-0 h-5 w-5 text-red-500" aria-hidden="true" />
                                )}
                                
                                <span className="sr-only">{"increase" === 'increase' ? 'Increased' : 'Decreased'} by</span>
                                {"5.4%"} */}
                            </p>
                            <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
                                <div className="text-sm">
                                    {/* <a href="#adsf" className="font-medium text-blue-600 hover:text-blue-500">
                                        {' '}
                                        View all<span className="sr-only">Total Paid YTD stats</span>
                                    </a> */}
                                </div>
                            </div>
                        </dd>
                    </div>

                    {/* Time Tracker */}
                    <div className="relative bg-white pt-0 px-0 pb-0 sm:pt-0 sm:px-0 shadow rounded-lg overflow-hidden">
                        
                        <Timer />
                    </div>
                </dl>
            </div>            
            
            {/* Show Time Saver - a dialog to input info about time tracked, if time has been saved */}
            {(clockTimeSavedState.minutes || clockTimeSavedState.manualEntry) &&
                <div className='mt-8 w-full max-w-full'>
                    <TimeSaver />
                </div>
            }
            
            {/* Time Log - lists all time events */}
            <div className='mt-8 w-full max-w-full'>
                <TimeLog items={currentWorkLog} />
            </div>
        </>
    );
}