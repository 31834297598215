import { createSlice } from '@reduxjs/toolkit'

const NAV_STATE = {
    Dashboard:  'Dashboard',
    Workers:    'Workers',
    Payments:   'Payments',
    Invoices:   'Invoices',
    Settings:   'Settings'
};

const initialState = {
    value: NAV_STATE.Dashboard,
}

export const navigationSlice = createSlice({
    name: 'navState',
    initialState,
    reducers: {       
        // increment: (state) => {
        //     state.value += 1
        // },
        // decrement: (state) => {
        //     state.value -= 1
        // }, 
        changeNavState: (state, action) => {
            state.value = action.payload
        },
    },
})

export const { changeNavState } = navigationSlice.actions

export default navigationSlice.reducer