// TODO: must add this to our terms of use and info
// The customer’s permission to your initiating a payment or a series of payments on their behalf
// The anticipated frequency of payments (that is, one-time or recurring)
// How the payment amount will be determined

import { Fragment, useEffect, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { Link, useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from 'react-redux'
import { changeNavState } from '../app/NavigationSlice';
import { updateUserInfo } from '../app/UserSlice';

import {loadStripe} from '@stripe/stripe-js';

import {
	BellIcon,
	CogIcon,
	FolderIcon,
	HomeIcon,
	InboxArrowDownIcon,
	Bars3BottomLeftIcon,
	UsersIcon,
	XMarkIcon,
	QuestionMarkCircleIcon,
	CurrencyDollarIcon,
	ClockIcon,
	CalendarDaysIcon,
} from '@heroicons/react/24/outline'

import DashboardTeam from '../components/DashboardWorkers';
import DashboardSettings from '../components/DashboardSettings';

import * as Constants from '../model/index';
import { saveSettings, loadSettings, clearSettings } from '../util/settingsStorage';


import AddNewWorker from '../components/AddNewWorker';
import AddNewWorkerReview from '../components/AddNewWorkerReview';
import Divider from '../components/Divider';

import { ReactComponent as QurbieLogo} from '../assets/Qurbie.svg'
import DashboardTeamStats from '../components/DashboardTeamStats';
import ModalDialog from '../components/ModalDialog';
import ModalIndeterminate from '../components/ModalIndeterminate';

import Parse from 'parse';
import DashboardPayments from '../components/DashboardPayments';
import WorkerPayRequest from '../components/WorkerPayRequest';
import DashboardWorkers from '../components/DashboardWorkers';
import SignUpGuide from '../components/SignUpGuide';
import TaxFilingOverview from '../components/TaxFilingOverview';
import PaymentsHistory from '../components/PaymentsHistory';
import Nav from '../components/Navigation';
import NavigationMobile from '../components/NavigationMobile';
import Navigation from '../components/Navigation';
import SelectAccountType from '../components/SelectAccountType';
import InvoiceHistory from '../components/InvoiceHistory';
import DashboardWorkerStats from '../components/DashboardWorkerStats';

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}


export default function Dashboard(props) {
	const [appSettings, setAppSettings] = useState({});
	const [sidebarOpen, setSidebarOpen] = useState(false)
	const [alert, setAlert] = useState({show: false});
	const [progress, setProgress] = useState({show: false});
	const [clientIp, setClientIp] = useState();
	const [stripeAccountId, setStripeAccountId] = useState();
	const [financialAccount, setFinancialAccount] = useState();
	const [finAccountFeatures, setFinAccountFeatures] = useState();
	const [clientSecret, setClientSecret] = useState();
	const [currentStatus, setCurrentStatus] = useState();
	const [paymentMethodId, setPaymentMethodId] = useState();


	const navState = useSelector((state) => state.navState.value)
	const userState = useSelector((state) => state.userState);

	const dispatch = useDispatch(); 
	const navigate = useNavigate(); 
	
	// get logged in user data
	useEffect(()=> {
		// is user logged in?
		if (!Parse.User.current()) {
			navigate("/sign-in", {replace: true});
			return;
		}

		// load settings from local storage
		const appSettings = loadSettings();
		if (appSettings) {
			setAppSettings(loadSettings());
		} else {
			// get them from user if needed
			navigate("/select-account");
		}

		Parse.Cloud.run('GetUserData', {}).then((result) => {				
			console.log('GetUSerData Result:', result);
			dispatch(updateUserInfo(result))
		})
		.catch((error) => {
			if (error.code === 209) { // invalid session
				// token expired
				console.log("User Login Expired");
				Parse.User.logOut().then(()=> navigate('/sign-in', {replace: true}));
			} else {
				console.log("Error with getUserData:", error);
				setAlert({show: true, title: 'Error', message: 'Error with call:' + error})
			}
		});		
	}, [])

	const getUsersIp = async (callback) => {

		fetch('https://geolocation-db.com/json/').then((result) => {
			result.json().then(jsonData => {
				//console.log("Json Data:", jsonData);
				if (jsonData.IPv4) {
					setClientIp(jsonData.IPv4);
					console.log("Client IP:", jsonData.IPv4);

					if (callback) {
						callback(jsonData.IPv4);
					}
				}
			})
		});
	}


	const inboundTransferTest = async () => {
		console.log("Inbound - stripeAccountId:", stripeAccountId);
		console.log("Inbound - financialAccount:", financialAccount);
		console.log("Inbound - Payment MethodId:", paymentMethodId);

		Parse.Cloud.run('InboundTransfer', {stripeAccountId, paymentMethodId, financialAccount})
			.then((response) => console.log("Inbound Transfer response:", response))
			.catch((error) => console.log("Error:", error));
	}

	const getFinAccountFeatures = async () => {
		const finAccountId = Parse.User.current().get("financialAccount");
		const stripeAccountId = Parse.User.current().get("stripeAccount");
			
		Parse.Cloud.run('GetFinFeatures', {stripeAccountId, finAccountId}).then((response) => {
			//setFinAccountFeatures(response);
			console.log("Got features for account:", finAccountId);
			console.log("Response:", response);

		}).catch(error => console.log("error:", error));		
	}

	const addBankAccount = async (secret, accountId) => {
		try {			
			const stripe = await loadStripe('pk_test_LHMnD6LqfZDemm64RgqfZpok', {
				stripeAccount: accountId,
			  });

			try {
				var response = await stripe.collectBankAccountForSetup({
					clientSecret: `${secret}`,
					params: {
						payment_method_type: 'us_bank_account',
					  	payment_method_data: {
						billing_details: {
							name: 'David Nelson', 
							email: 'test@duffekmobile.com'},
					  	},
					},
				}, {stripeAccount: accountId});

				console.log("AddBankAccount Response:", response);
				
				console.log("Client_secret:", response.setupIntent.client_secret);
				setCurrentStatus(response.setupIntent.status);
				var response2 = await stripe.confirmUsBankAccountSetup(response.setupIntent.client_secret, {});

				console.log("ConfirmUSBankAccountSetup:", response2);
				setCurrentStatus(response2.setupIntent.status);

				setPaymentMethodId(response2.setupIntent.payment_method);

			} catch (error) {
				console.log("AddBankAccount2 Error:", error);				
			}

		} catch(error) {
			console.log("Error adding account:- ", error);
		}
	}


	return (
		<>
			<ModalDialog show={alert.show || false} title={alert.title} message={alert.message}
				confirmButtonTitle={alert.okTitle || "Ok"} cancelButtonTitle={alert.cancelTitle} icon={<BellIcon className="text-blue-500" />}
				confirmButtonFunc={() => setAlert({showAlert:false})} />

			<ModalIndeterminate show={progress.show} title={progress.title || ''} message={progress.message || ''} />

			<div>
				{/* Sidebar Mobile Nav */}
				<NavigationMobile />

				{/* Sidebar Desktop */}
				<Navigation />
				
				<div className="md:pl-64 flex flex-col flex-1">
					<div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
						<button
							type="button"
							className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 md:hidden"
							onClick={() => setSidebarOpen(true)}
						>
							<span className="sr-only">Open sidebar</span>
							<Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
						</button>
						<div className="flex-1 px-4 flex justify-between">
							<div className="flex-1 flex">

							</div>
							<div className="ml-4 flex items-center md:ml-6">
								<button
									type="button"
									className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
								>
									<span className="sr-only">View notifications</span>
									<BellIcon className="h-6 w-6" aria-hidden="true" />
								</button>

								{/* Profile dropdown */}
								<Menu as="div" className="ml-3 relative">
									<div>
										<Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
											<span className="sr-only">Open user menu</span>
											<img
												className="h-8 w-8 rounded-full"
												src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
												alt=""
											/>
										</Menu.Button>
									</div>
									<Transition
										as={Fragment}
										enter="transition ease-out duration-100"
										enterFrom="transform opacity-0 scale-95"
										enterTo="transform opacity-100 scale-100"
										leave="transition ease-in duration-75"
										leaveFrom="transform opacity-100 scale-100"
										leaveTo="transform opacity-0 scale-95"
									>
										{/* Profile sub menu */}
										<Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
											{/* { name: 'Your Profile', href: '#' },
											{ name: 'Settings', href: '#' },
											{ name: 'Sign out', href: '#' }, */}
										
											{/* Your Profile */}
											<Menu.Item>												
												<a
													href={'/profile'}
													className={classNames(
														//active ? 'bg-blue-100' : '',
														'block px-4 py-2 text-sm text-gray-700'
													)}
												>
													Your Profile
												</a>												
											</Menu.Item>

											{/* Settings */}
											<Menu.Item>												
												<a
													href={'/settings'}
													className={classNames(
														//active ? 'bg-blue-100' : '',
														'block px-4 py-2 text-sm text-gray-700'
													)}
												>
													Settings
												</a>												
											</Menu.Item>
											
											{/* Sign Out */}
											<Menu.Item>												
												<div													
													onClick={e => {
														Parse.User.logOut().then(e => {
															console.log("Signed Out");
															clearSettings();
															navigate('/sign-in')
														}).catch(error => {
															console.log('error', error)
														});
													}}
													className={classNames(
														false ? 'bg-blue-100' : '',
														'block px-4 py-2 text-sm text-gray-700 cursor-pointer'
													)}
												>
													Sign Out
												</div>												
											</Menu.Item>
										</Menu.Items>
									</Transition>
								</Menu>
							</div>
						</div>
					</div>

					<main className="flex">
						{/* Dashboard View */}
						{props.dashboardView && 
							<div className='flex-1'>
								<div className="max-w-full m-10 px-4 sm:px-6 md:px-8">
									<h1 className="text-4xl font-semibold text-gray-900 text-left mb-10">
										Account Overview
									</h1>
								</div>								
								<div className="max-w-full m-10 px-4 sm:px-6 md:px-8">
									
									{appSettings && appSettings.accountType === "company" &&
										<>
											<DashboardTeamStats />
											<DashboardWorkers />									
										</>	
									}

									{appSettings && appSettings.accountType === "worker" &&
										<>
											<DashboardWorkerStats totalContractors={0} totalPayments={0} pendingPayments={0} />
											<InvoiceHistory showLimitedItems />									
										</>	
									}
									
								</div>								
							</div>
						}

						{/* Payments View */}
						{props.paymentsView && 
							<div className='flex-1'>
								<div className="max-w-full m-10 px-4 sm:px-6 md:px-8">
									<h1 className="text-4xl font-semibold text-gray-900 text-left mb-10">
										Payments Overview
									</h1>
								</div>
								<div className="max-w-full m-10 px-4 sm:px-6 md:px-8">
									<DashboardPayments />
								</div>								
							</div>
						}

						{/* Team View */}
						{props.teamView && 
							<div className='flex-1'>
								<div className="max-w-full mt-4 px-4 sm:px-6 md:px-8">								
									<DashboardWorkers />
								</div>
							</div>
						}

						{/* Tax Filing */}
						{props.taxes && 
							<div className='flex-1'>
								<div className="max-w-full m-10 px-4 sm:px-6 md:px-8">
									<h1 className="text-4xl font-semibold text-gray-900 text-left mb-10">
										Tax Filing Overview
									</h1>
								</div>								
								<div className="max-w-full m-10 px-4 sm:px-6 md:px-8">
									<TaxFilingOverview />
								</div>								
							</div> 
						}

						{/* Profile */}
						{props.profileView &&
							<>
								<div className="max-w-full m-10 px-4 sm:px-6 md:px-8">
									<h1 className="text-4xl font-semibold text-gray-900 text-left mb-10">
										Account Profile 
									</h1>
								</div>								
								<div className="max-w-full m-10 px-4 sm:px-6 md:px-8">
									
								</div>
							</>
						}

						{/* Settings */}
						{props.settingsView &&
							<>
								<div className="max-w-full m-10 px-4 sm:px-6 md:px-8">
									<h1 className="text-4xl font-semibold text-gray-900 text-left mb-10">
										Account Settings
									</h1>
								</div>								
								<div className="max-w-full m-10 px-4 sm:px-6 md:px-8">
									
								</div>
							</>
						}

						{/* Help */}
						{props.helpView &&
							<>
								<div className="max-w-full m-10 px-4 sm:px-6 md:px-8">
									<h1 className="text-4xl font-semibold text-gray-900 text-left mb-10">
										Account Help
									</h1>
								</div>								
								<div className="max-w-full m-10 px-4 sm:px-6 md:px-8">
									
								</div>
							</>
						}

						{/* Add Team View */}
						{props.addWorkerView && 						
							<div className="max-w-full mt-4 px-4 sm:px-6 md:px-8">
								<AddNewWorker />
							</div>								
						}

						{/* Add Team Review */}
						{props.addWorkerReviewView && 
							<div className="max-w-full mt-4 px-4 sm:px-6 md:px-8">
								<AddNewWorkerReview />
							</div>								
						}	

						{/* Worker Pay Request */}
						{props.workerPayRequest && 
							<div className="max-w-full w-full  mt-4 px-4 sm:px-6 md:px-8">
								<WorkerPayRequest />
							</div>								
						}										

						{/* Invoice History for the Worker */}
						{props.invoiceHistory && 
							<div className='flex-1'>
								<div className="max-w-full m-10 px-4 sm:px-6 md:px-8">
									<h1 className="text-4xl font-semibold text-gray-900 text-left mb-10">
										Your Invoice History
									</h1>
								</div>								
								<div className="max-w-full m-10 px-4 sm:px-6 md:px-8">																	
									<InvoiceHistory />
								</div>
							</div>								
						}										

						{/* Payment History for the Company */}
						{props.paymentsHistory && 
							<div className='flex-1'>
								<div className="max-w-full m-10 px-4 sm:px-6 md:px-8">
									<h1 className="text-4xl font-semibold text-gray-900 text-left mb-10">
										Your Payments History
									</h1>
								</div>								
								<div className="max-w-full m-10 px-4 sm:px-6 md:px-8">								
									<PaymentsHistory />									
								</div>
							</div>								
						}			

					</main>							

					<div className="mx-20 my-8">
						{/* get ip */}
						<button 
							className="mt-1 ml-1 flex items-center justify-center px-4 py-2 rounded-md font-bold focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white bg-blue-600 text-white"
							onClick={(e)=> {
								//get client ip address for stripe
								getUsersIp((usersIp) => {
									if (usersIp) { 
										setClientIp(usersIp);
										console.log("Client IP is:", usersIp);
									}
								});
							}}>
							Get IP
						</button>						

						{/* Clear Account Settings */}
						<button 
							className="mt-1 ml-1 flex items-center justify-center px-4 py-2 rounded-md font-bold focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white bg-blue-600 text-white"
							onClick={(e)=> {								
								clearSettings();
							}}>
							Clear Settings
						</button>
						

					</div>
					<div className="mx-20 my-2">
						<p>Client IP: {clientIp || 'n/a'}</p>
						<p>Current Status: {currentStatus || 'n/a'}</p>
					</div>
					
					{/* <div className='w-full bg-gray-200'>
						<SignUpGuide />
					</div>	 */}
				</div> 	
			</div>		
			
			
		</>
	)
}