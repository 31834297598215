// ipUtils.js
const getUsersIp = async () => {
    console.log("Getting client IP...");

    let usersIp = await getIpWithIpAPI();

    if (!usersIp) {
        usersIp = await getIpWithIPIFY();
    }

    console.log("Client IP:", usersIp);
    return usersIp;
};

const getIpWithIpAPI = async () => {
    console.log("Using IP-API");

    try {
        const result = await fetch('http://ip-api.com/json/');
        const resultJson = await result.json();
        return resultJson.query;
    } catch (error) {
        console.error("IP-API: Unable to get IP", error);
        return null;
    }
};

const getIpWithIPIFY = async () => {
    console.log("Using IP-IFY");

    try {
        const result = await fetch('https://api.ipify.org?format=json');
        const resultJson = await result.json();
        return resultJson.ip;
    } catch (error) {
        console.error("IP-IFY: Unable to get IP", error);
        return null;
    }
};

export { getUsersIp, getIpWithIpAPI, getIpWithIPIFY };