import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    firstName: '',
    lastName: '',
    name: '',
    setupStagesCompleted: {basic: true, business: false, banking: false, verify: false},    
    business: {},
    businessId: null,
    businessPayments: [],
    invoices: [],
    paymentHistory: [],  
    workers: [],
    workingFor: [],        
};

export const userSlice = createSlice({
    name: 'userState',
    initialState,
    reducers: {       
        updateUserInfo: (state, action) => {
            action.payload.firstName && (state.firstName = action.payload.firstName);
            action.payload.lastName && (state.lastName = action.payload.lastName);
            (action.payload.firstName && action.payload.firstName) && (state.name = action.payload.firstName + ' ' + action.payload.lastName);

            action.payload.setupStagesCompleted && (state.setupStagesCompleted = action.payload.setupStagesCompleted);

            action.payload.business && (state.business = action.payload.business);
            action.payload.businessId && (state.businessId = action.payload.businessId);
            action.payload.invoices && (state.invoices = action.payload.invoices);
            action.payload.businessPayments && (state.businessPayments = action.payload.businessPayments);
            action.payload.paymentHistory && (state.paymentHistory = action.payload.paymentHistory);
            action.payload.workers && (state.workers = action.payload.workers);
            action.payload.workingFor && (state.workingFor = action.payload.workingFor);
        },
        updateSetupStages: (state, action) => {
            if (!state.userState) {
                console.log('userState is undefined - is this a refresh?');
                state.userState = { setupStagesCompleted: initialState.setupStagesCompleted }; 
            }
            state.userState.setupStagesCompleted = action.payload;
        },
    },
});

export const { updateUserInfo, updateSetupStages } = userSlice.actions

export default userSlice.reducer