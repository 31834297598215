import { Fragment, useState } from 'react'

import {
	ChevronRightIcon,
} from '@heroicons/react/24/outline'

import { ArrowSmallDownIcon, ArrowSmallUpIcon, PlusCircleIcon } from '@heroicons/react/20/solid'

import * as Constants from '../../model/index';

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}


export default function DashboardStats() {
    return (
        <>
            {/* <div className="grid grid-cols-4 gap-4">
                <div className="bg-green-200">1</div>
                <div className="bg-blue-200">2</div>
                <div className="bg-red-200">3</div>
                <div className="bg-purple-200">4</div>
            </div> */}

            {/* Primary Stats */}
            <div>
                <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                    {Constants.stats.map((item) => (
                        <div
                            key={item.id}
                            className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
                        >
                            <dt>
                                <div className="absolute bg-blue-500 rounded-md p-3">
                                    <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
                                </div>
                                <p className="ml-16 text-sm font-medium text-left text-gray-500 truncate">{item.name}</p>
                            </dt>
                            <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                                <p className="text-2xl font-semibold text-gray-900">{item.stat}</p>
                                <p
                                    className={classNames(
                                        item.changeType === 'increase' ? 'text-green-600' : 'text-red-600',
                                        'ml-2 flex items-baseline text-sm font-semibold'
                                    )}
                                >
                                    {item.changeType === 'increase' ? (
                                        <ArrowSmallUpIcon className="self-center flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
                                    ) : (
                                        <ArrowSmallDownIcon className="self-center flex-shrink-0 h-5 w-5 text-red-500" aria-hidden="true" />
                                    )}

                                    <span className="sr-only">{item.changeType === 'increase' ? 'Increased' : 'Decreased'} by</span>
                                    {item.change}
                                </p>
                                <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
                                    <div className="text-sm text-left">
                                        <a href="#view" className="font-bold text-blue-600 hover:text-blue-500">
                                            {' '}
                                            View Details
                                        </a>
                                    </div>
                                </div>
                            </dd>
                        </div>
                    ))}
                </dl>
            </div>

            { /* Secondary Stats */}
            <div>
                <dl className="mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-4 md:divide-y-0 md:divide-x">
                    {Constants.secondaryStats.map((item) => (
                        <div key={item.name} className="px-4 py-5 sm:p-6 text-left">
                            <dt className="text-base font-normal text-gray-900">{item.name}</dt>
                            <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                                <div className="flex items-baseline text-2xl font-semibold text-blue-600">
                                    {item.stat}
                                </div>

                                <div
                                    className={classNames(
                                        item.changeType === 'increase' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                                        'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                                    )}
                                >
                                    {item.changeType === 'increase' ? (
                                        <ArrowSmallUpIcon
                                            className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                                            aria-hidden="true"
                                        />
                                    ) : (
                                        <ArrowSmallDownIcon
                                            className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                                            aria-hidden="true"
                                        />
                                    )}

                                    <span className="sr-only">{item.changeType === 'increase' ? 'Increased' : 'Decreased'} by</span>
                                    {item.change}
                                </div>
                            </dd>
                        </div>
                    ))}
                </dl>
            </div>


            {/* Pojects Table Header */}
            <div>
                <div className="sm:hidden">
                    <label htmlFor="tabs" className="sr-only">
                        Select a tab
                    </label>
                    {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                    <select
                        id="tabs"
                        name="tabs"
                        className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                        defaultValue={Constants.tabs.find((tab) => tab.current).name}
                    >
                        {Constants.tabs.map((tab) => (
                            <option key={tab.id}>{tab.name}</option>
                        ))}
                    </select>
                </div>

                <div className="hidden sm:block mt-10 text-left">
                    <div className="flex justify-start">
                        <div className="text-4xl text-gray-900">Projects</div>
                        <div className="flex">
                            <button className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium focus:outline-none"
                                onClick={() => console.log("New Project Clicked")}>
                                <PlusCircleIcon className="self-center flex-shrink-0 h-8 w-8 text-blue-500" />
                                <div className="ml-1 font-bold text-blue-500">New Project</div>
                            </button>
                        </div>
                    </div>
                    <div className="border-b border-gray-200">
                        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                            {Constants.tabs.map((tab) => (
                                <a
                                    key={tab.id}
                                    href="#projects"
                                    className={classNames(
                                        tab.current
                                            ? 'border-blue-500 text-blue-600'
                                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                                        'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm'
                                    )}
                                    aria-current={tab.current ? 'page' : undefined}
                                >
                                    {tab.name}
                                    {tab.count ? (
                                        <span
                                            className={classNames(
                                                tab.current ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-900',
                                                'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                                            )}
                                        >
                                            {tab.count}
                                        </span>
                                    ) : null}
                                </a>
                            ))}
                        </nav>
                    </div>
                </div>
            </div>

            {/* Projects Table */}
            <div className="mt-5 flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Due Date
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Title
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Owner
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Revenue
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Costs
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Profitability
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Status
                                        </th>
                                        <th scope="col" className="relative px-6 py-3">
                                            <span className="sr-only">View Project</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* Due Date Title Owner Revenue Costs Profitability Status */}
                                    {Constants.projects.map((project, index) => (
                                        <tr key={project.email} className={index % 2 === 0 ? 'bg-white' : 'bg-blue-50'}>
                                            <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium text-gray-900">{project.dueDate.toLocaleString({ month: 'short', day: 'numeric', year: 'numeric' })}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium text-gray-900">{project.title}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-left text-sm text-gray-500">{project.owner}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-left text-sm text-gray-500">${project.revenue.toFixed(2)}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-left text-sm text-gray-500">(${project.costs.toFixed(2)})</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-left text-sm text-gray-500">${(project.revenue - project.costs).toFixed(2)}</td>

                                            <td className="px-6 py-4 whitespace-nowrap text-center text-sm text-gray-500">
                                                {(project.status === "On Track" || project.status === "Completed") ?
                                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                        {project.status}
                                                    </span>
                                                    :
                                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                                        {project.status}
                                                    </span>
                                                }
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                <a href="#edit" className="text-blue-600 hover:text-blue-900">
                                                    <ChevronRightIcon className="w-5 h-5 text-blue-600" />
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}