import { Fragment, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import NumberFormat from 'react-number-format'

import ModalDialog from './ModalDialog';
import ModalIndeterminate from './ModalIndeterminate';
import { BellIcon } from '@heroicons/react/24/outline';

const steps = [
	{ id: 'Step 1', name: 'Worker Details', href: '#', status: 'complete' },
	{ id: 'Step 3', name: 'Worker Added', href: '#', status: 'current' },	
]


function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

export default function AddNewWorkerReview(props) {	
	const navigate = useNavigate();
	const location = useLocation();
	const { state } = location || {};
	const { firstName, lastName, email, hourlyRate  } = state || {};
    const [showAlert, setShowAlert] = useState({showAlert:false});
    const [showProgress, setShowProgress] = useState({showAlert:false});
	
	// Scroll to top on first load
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return(
		<>
			<ModalDialog show={showAlert.showAlert || false} title={showAlert.title} message={showAlert.message}
				confirmButtonTitle={showAlert.okTitle || "Ok"} cancelButtonTitle={showAlert.cancelTitle} icon={<BellIcon className="text-blue-500" />}
				confirmButtonFunc={showAlert.confirmFunc}
				cancelButtonFunc={() => setShowAlert({showAlert: false})} />

			<ModalIndeterminate show={showProgress.showAlert || false} title={showProgress.title || ''} message={showProgress.message || ''} />

			<div className="lg:max-w-full">
				<div className="overflow-hidden text-left">
					<div className="px-4 py-5 sm:p-6">																								
						{/* Review of Project */}
						<div>
							{/* <QurbieLogo className="w-48 mt-20 ml-auto mr-auto" /> */}
							
							<div className="px-4 py-5 sm:px-6 ml-auto mr-auto text-center">
								<h3 className="text-3xl leading-6 font-semibold text-gray-900">Review Your New Worker</h3>
								<p className="mt-4 text-sm text-gray-500">Please check the information below and submit the worker when you are ready</p>
							</div>

							{/* Progress Bar */}
							<div className="mt-10 mb-10 lg:ml-8 lg:mr-8">
								<nav aria-label="Progress">
									<ol className="space-y-4 md:flex md:space-y-0 md:space-x-8">
										{steps.map((step) => (
											<li key={step.name} className="md:flex-1">
												{step.status === 'complete' ? (
													<a href={step.href} className="group pl-4 py-2 flex flex-col border-l-4 border-green-600 hover:border-green-800 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4">
														<span className="text-xs text-green-600 font-semibold tracking-wide uppercase group-hover:text-green-800">
															{step.id} | {step.name}
														</span>
														{/* <span className="text-sm font-medium text-green-600">{step.name}</span> */}
													</a>
												) : step.status === 'current' ? (
													<a href={step.href} className="pl-4 py-2 flex flex-col border-l-4 border-blue-600 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4" aria-current="step">
														<span className="text-xs text-blue-600 font-semibold tracking-wide uppercase">{step.id} | {step.name}</span>
														{/* <span className="text-sm font-medium">{step.name}</span> */}
													</a>
												) : (
													<a href={step.href} className="group pl-4 py-2 flex flex-col border-l-4 border-gray-200 hover:border-gray-300 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4">
														<span className="text-xs text-gray-500 font-semibold tracking-wide uppercase group-hover:text-gray-700">
															{step.id} | {step.name}
														</span>
														{/* <span className="text-sm font-medium">{step.name}</span> */}
													</a>
												)}
											</li>
										))}
									</ol>
								</nav>
							</div>


							{/* Project Details */}
							<div className="border-t border-gray-200 px-4 py-5 sm:px-6">
								<dt className="text-lg font-medium text-gray-600 mb-5">Worker Details</dt>
								<dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
									<div className="sm:col-span-1">
										<dt className="text-sm font-medium text-gray-500">First Name</dt>
										<dd className="mt-1 text-base font-bold text-gray-600">{firstName}</dd>
									</div>
									<div className="sm:col-span-1">
										<dt className="text-sm font-medium text-gray-500">Last Name</dt>
										<dd className="mt-1 text-base font-bold text-gray-600">{lastName}</dd>
									</div>
									<div className="sm:col-span-1">
										<dt className="text-sm font-medium text-gray-500">E-mail Address</dt>
										<dd className="mt-1 text-base font-bold text-gray-600">{email}</dd>
									</div>
									{/* <div className="sm:col-span-1">
										<dt className="text-sm font-medium text-gray-500">Phone Number</dt>
										<dd className="mt-1 text-base font-bold text-gray-600">
											<NumberFormat 
												value={worker.phoneNumber} 
												displayType={'text'} 
												format="(###) ###-####"/>
										</dd>
									</div>									 */}
									{/* <div className="sm:col-span-1">
										<dt className="text-sm font-medium text-gray-500">Address</dt>
										<dd className="mt-1 text-base font-bold text-gray-600">
											{worker.streetAddress1}<br/>
											{worker.streetAddress2 && 
												<div>{worker.streetAddress2}<br/></div>
											}
											{worker.city}, {worker.state} {worker.zipcode}
										</dd>
									</div> */}
									<div className="sm:col-span-1">
										<dt className="text-sm font-medium text-gray-500">Billing Rate</dt>
										<dd className="mt-1 text-base font-bold text-gray-600">
											<NumberFormat 
												value={hourlyRate} 
												displayType={'text'} 
												thousandSeparator={true} 
												decimalScale={2} 
												fixedDecimalScale 
												prefix={'$'} /><span className="text-sm">/hr</span>
										</dd>
									</div>
									<div className="sm:col-span-1">
										<dt className="text-sm font-medium text-gray-500">Auto-Collect Tax Info</dt>
										<dd className="mt-1 text-base font-bold text-gray-600">
											{"True"}
										</dd>
									</div>
									<div className="sm:col-span-1">
										<dt className="text-sm font-medium text-gray-500">Auto-Collect Payment Info</dt>
										<dd className="mt-1 text-base font-bold text-gray-600">
											{"True"}
										</dd>
									</div>
																		
									{/* <div className="sm:col-span-1">
										{ !worker.autoCollectTax && 
											<>
												<dt className="text-sm font-medium text-gray-500">Tax Information</dt>
												<dd className="mt-1 text-base font-bold text-gray-600">											
													Tax ID: {new Array(worker.taxId.length - 4 + 1).join('x') + worker.taxId.slice(-4)}
													<br/>
													Tax Type: {worker.taxType}
												</dd>									
											</>
										}
									</div> */}
															
									{/* <div className="sm:col-span-1">
									{ !worker.autoCollectPay &&
										<>
										<dt className="text-sm font-medium text-gray-500">Pay Info</dt>
										<dd className="mt-1 text-base font-bold text-gray-600">
											Routing: {worker.payRouting}<br/>
											Account: {worker.payAccount}<br/>
											Type: {worker.payType}
										</dd>
										</>
									}
									</div> */}

									{/* {(worker.autoCollectPay || worker.autoCollectTax) &&
										<div className="sm:col-span-2">
											<p className="text-sm font-medium text-gray-500"><strong>NOTE:</strong> Qurbie will reach out to your worker at the email provided to collect neccessary information.<br/>You will be notified when this worker's on-boarding has completed.</p>
										</div>
									} */}
								</dl>
							</div>

														
							{/* Bottom Buttons */}
							<div className="pt-5">
								<div className="flex justify-end">
									<button
										type="button"
										className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
										onClick={e => navigate('/add-worker', {replace: true})}>
										Add Another Worker
									</button>
									<button
										type="submit"
										className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
										onClick={e => {
											navigate("/", {replace: true});												
										}}>
										Return to Dashboard
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>			
			</div>
		</>
	)
}