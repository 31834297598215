import { DateTime } from 'luxon'

import {
    ChartBarIcon,
    FolderIcon,
    HomeIcon,
    InboxArrowDownIcon,
    UsersIcon,
    CurrencyDollarIcon,
    ChartPieIcon,
    CheckBadgeIcon,
    RectangleStackIcon,
    ClockIcon,
    CogIcon,
    ColorSwatchIcon
} from '@heroicons/react/24/outline'

export const navigation = [
    { name: 'Dashboard', href: '/', icon: HomeIcon, current: true },
    { name: 'Payments', href: '/payments', icon: CurrencyDollarIcon, current: false },
    { name: 'Team', href: '/workers', icon: UsersIcon, current: false },
    { name: 'Settings', href: '/settings', icon: CogIcon, current: false },
]

export const userNavigation = [
    { name: 'Your Profile', href: '#' },
    { name: 'Settings', href: '#' },
    { name: 'Sign out', href: '#' },
]

export const stats = [
    { id: 111, name: 'Company Profitability', stat: '$88,000', icon: CurrencyDollarIcon, change: '$22,000', changeType: 'increase' },
    { id: 112, name: 'Profit Margin YTD', stat: '72.16%', icon: ChartPieIcon, change: '5.4%', changeType: 'increase' },
    { id: 113, name: 'Avg. Win Rate', stat: '84%', icon: CheckBadgeIcon, change: '3.2%', changeType: 'decrease' },
]

export const secondaryStats = [
    { id: 221, name: 'Total Revenue YTD', stat: '$88,000', icon: ChartBarIcon, previousStat: '$64,000', change: '$22,000', changeType: 'increase' },
    { id: 222, name: 'Contractor Payments', stat: '72.16%', icon: UsersIcon, change: '5.4%', changeType: 'increase' },
    { id: 223, name: 'Total Projects', stat: '33', icon: RectangleStackIcon, change: '5.4%', changeType: 'increase' },
    { id: 224, name: 'Total Hours Worked', stat: '84%', icon: ClockIcon, change: '3.2%', changeType: 'decrease' },
]

export const tabs = [
    { id: 2345, name: 'All Projects', href: '#', count: '4', current: true },
    { id: 2365, name: 'Active Projects', href: '#', count: '3', current: false },
    { id: 2388, name: 'Past Projects', href: '#', count: '22', current: false },
]

export const projects = [
    { dueDate: DateTime.now().plus({ weeks: 1 }), title: 'Qurbie Pay', owner: 'David Nelson', email: 'asdf@asdf.com', revenue: 312.88, costs: 220.10, status: 'On Track' },
    { dueDate: DateTime.now().plus({ weeks: 2 }), title: 'Bristol Jobs Website', owner: 'Chelsea Bill', email: 'asdf2@asdf.com', revenue: 12500.00, costs: 1500.00, status: 'On Track' },
    { dueDate: DateTime.now().plus({ weeks: 3 }), title: 'Mugz', owner: 'Andrew Johnson', email: 'asdf3@asdf.com', revenue: 250, costs: 0, status: 'Delayed' },
    { dueDate: DateTime.now(), title: 'EBG Holiday Cards', owner: 'David Nelson', email: 'asdf4@asdf.com', revenue: 600, costs: 0, status: 'Completed' },
]

export const teamTabs = [
    { name: 'All', href: '#', count: '3', current: true },
    { name: 'Active', href: '#', count: '2', current: false },
    { name: 'Inactive', href: '#', count: '1', current: false },
]

export const teamList = [
    { firstName: "Chelsea", lastName: "Bill", email: "cbill@cbill.com", phone: "(555) 456-7890", rate: 65, projectCount: 12, totalPaid: 8400, taxReady: 'ready', status: "Active" },
    { firstName: "Sam", lastName: "Altman", email: "sam@duffekmobile.com", phone: "(555) 456-4566", rate: 85, projectCount: 12, totalPaid: 3452, taxReady: 'pending', status: "Active" },
    { firstName: "Bill", lastName: "Chiley", email: "bill@chiley.com", phone: "(555) 456-6788", rate: 25, projectCount: 12, totalPaid: 1788, taxReady: 'problem', status: "Active" },
    // { firstName: "Andrew", lastName: "Bennington", email: "ab@benning.com", phone: "(555) 345-3453", rate: 125, projectCount: 12, totalPaid: 23465, taxReady: 'ready', status: "Active" },
    // { firstName: "Chris", lastName: "Stanson", email: "chris@therealchris.com", phone: "(555) 432-0787", rate: 35, projectCount: 12, totalPaid: 5233, taxReady: 'ready', status: "Inactive" },
    // { firstName: "Jenny", lastName: "Farkes", email: "jfarkes@farkesinc.com", phone: "(555) 345-4567", rate: 66, projectCount: 12, totalPaid: 1235, taxReady: 'ready', status: "Active" },
    // { firstName: "Stan", lastName: "Welsh", email: "csten@stenson.com", phone: "(555) 324-4563", rate: 45, projectCount: 12, totalPaid: 5345, taxReady: 'ready', status: "Active" },
    // { firstName: "Sam", lastName: "Altman", email: "sam@duffekmobile.com", phone: "(555) 456-7890", rate: 65, projectCount: 12, totalPaid: 800, taxReady: 'ready', status: "Active" },
    // { firstName: "Bill", lastName: "Chiley", email: "bill@chiley.com", phone: "(555) 456-7890", rate: 65, projectCount: 12, totalPaid: 8400, taxReady: 'ready', status: "Active" },
]

export const entityTypes = [
    { id: 0, title: 'Single-Member LLC', value: 'single_member_llc' },
    { id: 1, title: 'Multi-Member LLC', value: 'multi_member_llc' },
    { id: 2, title: 'Individual / Sole proprietor', value: 'sole_proprietorship' },
    { id: 3, title: 'Priavte Corportation (C-Corp)', value: 'private_corporation' },
    { id: 4, title: 'Private Corporation (S-Corp)', value: 'private_corporation' },
    { id: 5, title: 'Limited Liability Partnership (LLP)', value: 'limited_liability_partnership' },
    { id: 6, title: 'Incorporated Non-Profit', value: 'incorporated_non_profit'},    
]


export const statesList = [
    {
        "name": "Alabama",
        "code": "AL"
    },
    {
        "name": "Alaska",
        "code": "AK"
    },
    {
        "name": "Arizona",
        "code": "AZ"
    },
    {
        "name": "Arkansas",
        "code": "AR"
    },
    {
        "name": "California",
        "code": "CA"
    },
    {
        "name": "Colorado",
        "code": "CO"
    },
    {
        "name": "Connecticut",
        "code": "CT"
    },
    {
        "name": "Delaware",
        "code": "DE"
    },
    {
        "name": "Florida",
        "code": "FL"
    },
    {
        "name": "Georgia",
        "code": "GA"
    },
    {
        "name": "Hawaii",
        "code": "HI"
    },
    {
        "name": "Idaho",
        "code": "ID"
    },
    {
        "name": "Illinois",
        "code": "IL"
    },
    {
        "name": "Indiana",
        "code": "IN"
    },
    {
        "name": "Iowa",
        "code": "IA"
    },
    {
        "name": "Kansas",
        "code": "KS"
    },
    {
        "name": "Kentucky",
        "code": "KY"
    },
    {
        "name": "Louisiana",
        "code": "LA"
    },
    {
        "name": "Maine",
        "code": "ME"
    },
    {
        "name": "Maryland",
        "code": "MD"
    },
    {
        "name": "Massachusetts",
        "code": "MA"
    },
    {
        "name": "Michigan",
        "code": "MI"
    },
    {
        "name": "Minnesota",
        "code": "MN"
    },
    {
        "name": "Mississippi",
        "code": "MS"
    },
    {
        "name": "Missouri",
        "code": "MO"
    },
    {
        "name": "Montana",
        "code": "MT"
    },
    {
        "name": "Nebraska",
        "code": "NE"
    },
    {
        "name": "Nevada",
        "code": "NV"
    },
    {
        "name": "New Hampshire",
        "code": "NH"
    },
    {
        "name": "New Jersey",
        "code": "NJ"
    },
    {
        "name": "New Mexico",
        "code": "NM"
    },
    {
        "name": "New York",
        "code": "NY"
    },
    {
        "name": "North Carolina",
        "code": "NC"
    },
    {
        "name": "North Dakota",
        "code": "ND"
    },
    {
        "name": "Ohio",
        "code": "OH"
    },
    {
        "name": "Oklahoma",
        "code": "OK"
    },
    {
        "name": "Oregon",
        "code": "OR"
    },
    {
        "name": "Pennsylvania",
        "code": "PA"
    },
    {
        "name": "Rhode Island",
        "code": "RI"
    },
    {
        "name": "South Carolina",
        "code": "SC"
    },
    {
        "name": "South Dakota",
        "code": "SD"
    },
    {
        "name": "Tennessee",
        "code": "TN"
    },
    {
        "name": "Texas",
        "code": "TX"
    },
    {
        "name": "Utah",
        "code": "UT"
    },
    {
        "name": "Vermont",
        "code": "VT"
    },
    {
        "name": "Virginia",
        "code": "VA"
    },
    {
        "name": "Washington",
        "code": "WA"
    },
    {
        "name": "West Virginia",
        "code": "WV"
    },
    {
        "name": "Wisconsin",
        "code": "WI"
    },
    {
        "name": "Wyoming",
        "code": "WY"
    }
]