import { Fragment, useEffect, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { Link, useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from 'react-redux'
import { changeNavState } from '../app/NavigationSlice';
import { updateUserInfo, updateSetupStages } from '../app/UserSlice';

import {loadStripe} from '@stripe/stripe-js';


import {
	BellIcon,
	CogIcon,
	HomeIcon,
	InboxArrowDownIcon,
	Bars3BottomLeftIcon,
	UsersIcon,
	XMarkIcon,
	QuestionMarkCircleIcon,
	CurrencyDollarIcon,
	ClockIcon,
	ArrowRightOnRectangleIcon,
	UserCircleIcon,
} from '@heroicons/react/24/outline'

import Parse from 'parse';

import * as Constants from '../model/index';
import AddNewWorker from '../components/AddNewWorker';
import AddNewWorkerReview from '../components/AddNewWorkerReview';
import Divider from '../components/Divider';

import ModalDialog from '../components/ModalDialog';
import ModalIndeterminate from '../components/ModalIndeterminate';

import SignUpGuide from '../components/SignUpGuide';

import { ReactComponent as QurbieLogo} from '../assets/Qurbie.svg'
import SignUp2BusinessInfo from '../components/OldSignUp2BusinessInfo';
import SignUp3Verify from '../components/OldSignUp3Verify';
import SignUpGuideBusinessInfo from '../components/SignUpGuideBusinessInfo';
import SignUpGuideBankingInfo from '../components/SignUpGuideBankingInfo';
import SignUpGuideVerifyInfo from '../components/SignUpGuideVerifyInfo';
import { current } from '@reduxjs/toolkit';
import { clearSettings } from '../util/settingsStorage';


function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

export default function GettingStartedDash(props) {
	const [alert, setAlert] = useState({show: false});
	const [progress, setProgress] = useState({show: false});

	const [clientIp, setClientIp] = useState();

	const navState = useSelector((state) => state.navState.value)
	const userState = useSelector((state) => state.userState);

	const dispatch = useDispatch(); 
	const navigate = useNavigate();
	
	// get logged in user data
	// useEffect(()=> {
	// 	// is user logged in?
	// 	if (!Parse.User.current()) {
	// 		navigate("/sign-in", {replace: true});
	// 		return;
	// 	}

	// 	//TODO: setProgress({show: true, title: 'Updating', message: ''});
	// 	Parse.Cloud.run('GetUserData', {})
	// 	.then((result) => {				
	// 		setProgress({show: false});
	// 		console.log('result:', result);
	// 		dispatch(updateUserInfo(result));
			
	// 		let currentStages = userState && userState.setupStagesCompleted ? { ...userState.setupStagesCompleted } : { basic: true, business: false, banking: false, verify: false };

	// 		console.log("Current Stages:", currentStages);

	// 		if (result?.business?.businessName) {
	// 			currentStages.basic = true;
	// 			currentStages.business = true;
	// 			currentStages.banking = true;				
	// 		} 

	// 		if (result?.business?.financialAccountId) {
	// 			currentStages.verify = true;
	// 		}
		  
	// 		dispatch(updateSetupStages(currentStages));
	// 	})
	// 	.catch((error) => {
	// 		setProgress({show: false});
	// 		if (error.code === 209) { // invalid session
	// 			// token expired
	// 			console.log("User Login Expired");
	// 			Parse.User.logOut().then(()=> navigate('/sign-in', {replace: true}));
	// 		} else {
	// 			console.log("Error with getUserData:", error);
	// 			setAlert({show: true, title: 'Error', message: 'Error with call:' + error})
	// 		}
	// 	});		
	// }, []);

	const getUsersIp = async (callback) => {
		fetch('https://geolocation-db.com/json/').then((result) => {
			result.json().then(jsonData => {
				//console.log("Json Data:", jsonData);
				if (jsonData.IPv4) {
					setClientIp(jsonData.IPv4);
					console.log("Client IP:", jsonData.IPv4);

					if (callback) {
						callback(jsonData.IPv4);
					}
				}
			})
		});
	}


	return (
		<>
			<ModalDialog show={alert.show || false} title={alert.title} message={alert.message}
				confirmButtonTitle={alert.okTitle || "Ok"} cancelButtonTitle={alert.cancelTitle} icon={<BellIcon className="text-blue-500" />}
				confirmButtonFunc={() => setAlert({showAlert:false})} />

			<ModalIndeterminate show={progress.show} title={progress.title || ''} message={progress.message || ''} />

			<div>
				{/* Sidebar Desktop */}
				<div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
					{/* Sidebar component, swap this element with another sidebar if you like */}
					<div className="flex flex-col flex-grow border-r border-gray-200 pt-5 bg-white overflow-y-auto">
						<div className="flex items-center flex-shrink-0 px-4">
							<QurbieLogo className="h-12 w-auto" />
						</div>
						<div className="mt-4 flex-grow flex flex-col">
							<nav className="flex-1 px-2 pb-4 space-y-1">							

								<div className='text-2xl font-bold text-gray-800'>
									<span className='text-xs text-gray-400'>Welcome</span><br/>									
									{userState?.name?.length > 0 ? userState.name : ''}									
								</div>

								<Divider />
								<br/>

								{/* Setup Account */}
								<button className='text-gray-600 bg-blue-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md w-full'>
									<UserCircleIcon
										className={classNames(
											navState === 'Help' ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
											'mr-3 flex-shrink-0 h-6 w-6'
										)}
										aria-hidden="true"
									/>
									Setup Account
								</button>

								{/* Logout */}
								<button className='text-gray-600 hover:bg-blue-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md w-full'									
									onClick={() => {
										console.log("Logout Pressed!")
										clearSettings();
									}}
								>
									<ArrowRightOnRectangleIcon
										className={classNames(
											navState === 'Help' ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
											'mr-3 flex-shrink-0 h-6 w-6'
										)}
										aria-hidden="true"
									/>
									Log Out
								</button>

							</nav>
						</div>
					</div>
				</div>				

				<div className="md:pl-64 flex flex-col flex-1">
					<div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
						<button
							type="button"
							className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 md:hidden"
							// onClick={() => setSidebarOpen(true)}
						>
							<span className="sr-only">Open sidebar</span>
							<Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
						</button>
						<div className="flex-1 px-4 flex justify-between">
							<div className="flex-1 flex">

							</div>
							<div className="ml-4 flex items-center md:ml-6">
								<button
									type="button"
									className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
								>
									<span className="sr-only">View notifications</span>
									<BellIcon className="h-6 w-6" aria-hidden="true" />
								</button>

								{/* Profile dropdown */}
								<Menu as="div" className="ml-3 relative">
									<div>
										<Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
											<span className="sr-only">Open user menu</span>
											<img
												className="h-8 w-8 rounded-full"
												src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
												alt=""
											/>
										</Menu.Button>
									</div>
									<Transition
										as={Fragment}
										enter="transition ease-out duration-100"
										enterFrom="transform opacity-0 scale-95"
										enterTo="transform opacity-100 scale-100"
										leave="transition ease-in duration-75"
										leaveFrom="transform opacity-100 scale-100"
										leaveTo="transform opacity-0 scale-95"
									>
										{/* Profile sub menu */}
										<Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">										
											{/* Your Profile */}
											<Menu.Item>												
												<a
													href={'/profile'}
													className={classNames(
														//active ? 'bg-blue-100' : '',
														'block px-4 py-2 text-sm text-gray-700'
													)}
												>
													Your Profile
												</a>												
											</Menu.Item>

											{/* Settings */}
											<Menu.Item>												
												<a
													href={'/settings'}
													className={classNames(
														//active ? 'bg-blue-100' : '',
														'block px-4 py-2 text-sm text-gray-700'
													)}
												>
													Settings
												</a>												
											</Menu.Item>
											
											{/* Sign Out */}
											<Menu.Item>												
												<div													
													onClick={e => {
														Parse.User.logOut().then(e => {
															console.log("Signed Out");
															navigate('/sign-in')
														}).catch(error => {
															console.log('error', error)
														});
													}}
													className={classNames(
														false ? 'bg-blue-100' : '',
														'block px-4 py-2 text-sm text-gray-700 cursor-pointer'
													)}
												>
													Sign Out
												</div>												
											</Menu.Item>
										</Menu.Items>
									</Transition>
								</Menu>
							</div>
						</div>
					</div>

					<main className="flex">											
						<div className='w-full bg-gray-200 min-h-screen'>
							{/* Getting Started View */}
							{props.start &&		
								<>
									{window.scrollTo(0, 0)}
									<SignUpGuide />						
								</>
							}

							{/* Get Business Info */}
							{props.businessInfo && 
								<>
									{window.scrollTo(0, 0)}
									<SignUpGuideBusinessInfo />
								</>
							}							

							{/* Verify Identity */}
							{props.verifyInfo && 
								<>
									{window.scrollTo(0, 0)}
									<SignUpGuideVerifyInfo />
								</>
							}
						
							{/* Settings */}
							{props.settingsView &&
								<>								
									{window.scrollTo(0, 0)}
									<div className="max-w-full m-10 px-4 sm:px-6 md:px-8">
										<h1 className="text-4xl font-semibold text-gray-900 text-left mb-10">
											Account Settings
										</h1>
									</div>								
									<div className="max-w-full m-10 px-4 sm:px-6 md:px-8">
										
									</div>
								</>
							}

							{/* Help */}
							{props.helpView &&
								<>
									{window.scrollTo(0, 0)}
									<div className="max-w-full m-10 px-4 sm:px-6 md:px-8">
										<h1 className="text-4xl font-semibold text-gray-900 text-left mb-10">
											Account Help
										</h1>
									</div>								
									<div className="max-w-full m-10 px-4 sm:px-6 md:px-8">
										
									</div>
								</>
							}
						</div>
					</main>
				</div> 	
			</div>		
		</>
	)
}