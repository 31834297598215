import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    minutes: null,
    date: null,
    manualEntry: false,
};

export const clockTimeSavedSlice = createSlice({
    name: 'timeSavedState',
    initialState,
    reducers: {       
        changeClockTimeSaved: (state, action) => {
            state.minutes = action.payload.minutes;
            state.date = action.payload.date;
            state.manualEntry = action.payload.manualEntry || false;
        },
    },
});

export const { changeClockTimeSaved } = clockTimeSavedSlice.actions

export default clockTimeSavedSlice.reducer