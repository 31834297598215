import { Fragment, useEffect, useState } from 'react'

import { loadStripe } from '@stripe/stripe-js';

import {
    BellIcon,
} from '@heroicons/react/24/outline'

import ModalDialog from '../components/ModalDialog';
import ModalIndeterminate from '../components/ModalIndeterminate';

import Parse from 'parse';

export default function PayProto(props) {
    const [alert, setAlert] = useState({ show: false });
    const [progress, setProgress] = useState({ show: false });
    const [clientIp, setClientIp] = useState("");
    const [businessId, setBusinessId] = useState("");
    const [workerId, setWorkerId] = useState("");
    const [topUpResult, setTopUpResult] = useState("");
    const [transferResult, setTransferResult] = useState("");
    const [logResult, setLogResult] = useState("");

    const [financialAccountId, setFinancialAccountId] = useState("");
    const [verifyLink, setVerifyLink] = useState("");
    const [accountId, setAccountId] = useState("");
    const [intentSecret, setIntentSecret] = useState("");
    const [paymentMethodId, setPaymentMethodId] = useState("");
    const [verifyFinAccount, setVerifyFinAccount] = useState("");
    const [inboundId, setInboundId] = useState("");
    const [obAccountId, setObAccountId] = useState("");
    const [obSourceFaId, setObSourceFaId] = useState("");
    const [obDestFaId, setObDestFaId] = useState("");   


    useEffect(() => {
        getUsersIp();
    }, []);

    const getUsersIp = async (callback) => {

        fetch('https://geolocation-db.com/json/').then((result) => {
            result.json().then(jsonData => {
                //console.log("Json Data:", jsonData);
                if (jsonData.IPv4) {
                    setClientIp(jsonData.IPv4);
                    console.log("Client IP:", jsonData.IPv4);

                    if (callback) {
                        callback(jsonData.IPv4);
                    }
                }
            })
        });
    }


    const addBankAccount = async (accountId) => {
        
        if (!accountId) {
            console.log("No Account Id");
            return;
        }

        try {
            // initializing stripe            
            console.log("Loading stripe account")
            const stripe = await loadStripe('pk_test_LHMnD6LqfZDemm64RgqfZpok', {
                stripeAccount: accountId,
            });
 

            let clientSecret = null;

            console.log("get the client initialization");            
            const result = await Parse.Cloud.run("CreateSetupIntent", { accountId: accountId });
            console.log("Bank Account Intent Result:", result);
            clientSecret = result.client_secret;
            setIntentSecret(clientSecret); // TODO: for debug only - remove later
            console.log("Client Secret:", clientSecret);

            // only continue if we receive a token back
            if (!clientSecret) {
                console.log("Unable to Initialize Client");
                return;
            }

            

            console.log("Collecting bank account...");
            try {
                var response = await stripe.collectBankAccountForSetup({
                    clientSecret: `${clientSecret}`,
                    params: {
                        payment_method_type: 'us_bank_account',
                        payment_method_data: {
                            billing_details: {
                                name: 'Andrew Johnson',
                                email: 'test@duffekmobile.com'
                            },
                        },
                    },
                }, { stripeAccount: accountId });

                console.log("AddBankAccount Response:", response);

                console.log("Client_secret:", response.setupIntent.client_secret);
                //setCurrentStatus(response.setupIntent.status);
                console.log("Status", response.setupIntent.status);
                var response2 = await stripe.confirmUsBankAccountSetup(response.setupIntent.client_secret, {});

                console.log("ConfirmUSBankAccountSetup:", response2);
                //setCurrentStatus(response2.setupIntent.status);
                console.log("Status", response2.setupIntent.status);

                setPaymentMethodId(response2.setupIntent.payment_method);

                // TODO: Can't we just do this server side?
                Parse.Cloud.run("SaveBankAccount", { accountId: accountId, paymentMethodId: response2.setupIntent.payment_method }).then((result) => {
                    console.log("Result:", result);
                }).catch((error) => {
                    console.log("Error:", error);
                });

            } catch (error) {
                console.log("AddBankAccount2 Error:", error);
            }

        } catch (error) {
            console.log("Error adding account:- ", error);
        }
    }


    return (
        <>
            <ModalDialog show={alert.show || false} title={alert.title} message={alert.message}
                confirmButtonTitle={alert.okTitle || "Ok"} cancelButtonTitle={alert.cancelTitle} icon={<BellIcon className="text-blue-500" />}
                confirmButtonFunc={() => setAlert({ showAlert: false })} />

            <ModalIndeterminate show={progress.show} title={progress.title || ''} message={progress.message || ''} />

            <div>
                <div>Client IP: {clientIp}</div>

                {/* Create Stripe Account */}
                <button
                    className="mt-1 ml-1 flex items-center justify-center px-4 py-2 rounded-md font-bold focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white bg-blue-600 text-white"
                    onClick={() => {
                        console.log("Create Stripe Account");
                        Parse.Cloud.run("QurbieCreateStripeAccount", { email: "test@testing.com", clientIp: clientIp }).then((result) => {
                            console.log("Result:", result);
                            if (result.verification?.url) {
                                setVerifyLink(result.verification.url);
                                setAccountId(result.accountId);
                            }
                        }).catch((error) => {
                            console.log("Error:", error);
                        })
                    }}>Create Stripe Account</button>

                {verifyLink && <div>Verify Link: <a href={verifyLink} target="_blank" rel='noreferrer'>https://connect.stripe.com/setup/c/acct_1OPR2sH5QFiypaaw/ZviKUAA5dZO0</a></div>}

                {accountId && <div>Account ID: {accountId}</div>}



                {/* CreateFinancialAccount */}
                <button
                    className="mt-1 ml-1 flex items-center justify-center px-4 py-2 rounded-md font-bold focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white bg-blue-600 text-white"
                    onClick={() => {
                        console.log("CreateFinancialAccount");
                        Parse.Cloud.run("CreateFinancialAccount", { accountId: accountId }).then((result) => {
                            console.log("Result:", result);
                            console.log("FA Account Id:", result.financialAccount.id);
                            setFinancialAccountId(result.financialAccount.id || "");
                            setVerifyFinAccount(result.verification.url)
                        }).catch((error) => {
                            console.log("Error:", error);
                        })
                    }}>CreateFinancialAccount</button>

                {financialAccountId && <div>financialAccountId: {financialAccountId}</div>}
                {verifyFinAccount && <div>Verify Financial Link: <a href={verifyFinAccount} target="_blank" rel='noreferrer'>{verifyFinAccount}</a></div>}


                {/* Add Bank Account */}
                <button
                    className="mt-1 ml-1 flex items-center justify-center px-4 py-2 rounded-md font-bold focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white bg-blue-600 text-white"
                    onClick={() => {
                        console.log("Initiate Bank Account");
                        addBankAccount(accountId);
                    }}>Add Bank Account</button>
                
                {intentSecret && <div>Intent Secret: {intentSecret}</div>}
                {paymentMethodId && <div>Payment Method Id: {paymentMethodId}</div>}


                {/* Inbound Transfer */}

                <button
                    className="mt-1 ml-1 flex items-center justify-center px-4 py-2 rounded-md font-bold focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white bg-blue-600 text-white"
                    onClick={() => {
                        console.log("Inbound Transfer");
                        console.log("Account Id:", accountId);
                        console.log("Payment Method Id:", paymentMethodId);
                        console.log("Financial Account Id:", financialAccountId);

                        Parse.Cloud.run("StartInboundTransfer", { accountId: accountId, paymentMethodId: paymentMethodId, financialAccountId: financialAccountId }).then((result) => {
                            console.log("Inbound Transfer Result:", result);
                        }).catch((error) => {
                            console.log("Error:", error);
                        });
                    }}>Inbound Bank Transfer</button>





                {/* Account Settings */}
                <h3 className='mt-4 font-bold'>Account Settings</h3>
                <input
                    id="accountId"
                    name="accountId"
                    type="text"
                    autoComplete="accountId"
                    placeholder='Account Id'
                    required
                    className="appearance-none block w-1/2 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    value={accountId}
                    onChange={(e) => setAccountId(e.target.value)}
                />
                <input
                    id="fAccountId"
                    name="fAccountId"
                    type="text"
                    autoComplete="fAccountId"
                    placeholder='Financial Account Id'
                    required
                    className="appearance-none block w-1/2 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    value={financialAccountId}
                    onChange={(e) => setFinancialAccountId(e.target.value)}
                />
                <input
                    id="payId"
                    name="payId"
                    type="text"
                    autoComplete="payId"
                    placeholder='Payment Id'
                    required
                    className="appearance-none block w-1/2 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    value={paymentMethodId}
                    onChange={(e) => setPaymentMethodId(e.target.value)}
                />


                {/* Data Functions */}
                <h3 className='mt-4 font-bold'>Data Functions</h3>

                {/* List Payment Methods for Account Id */}
                <button
                    className="mt-1 ml-1 flex items-center justify-center px-4 py-2 rounded-md font-bold focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white bg-blue-600 text-white"
                    onClick={() => {
                        console.log("List Payment Methods for Account Id: ", accountId);
                        Parse.Cloud.run("ListPaymentMethods", { accountId: accountId }).then((result) => {
                            console.log("List Payment Methods", result);
                        }).catch((error) => {
                            console.log("Error:", error);
                        });
                    }}>List Payment Methods</button>








                {/* Inbound Success */}
                <button
                    className="mt-1 ml-1 flex items-center justify-center px-4 py-2 rounded-md font-bold focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white bg-blue-600 text-white"
                    onClick={() => {
                        console.log("Initiate Bank Account");
                        Parse.Cloud.run("SucceedInboundTransfer", { inboundId: inboundId }).then((result) => {
                            console.log("Inbound Success Result:", result);
                        }).catch((error) => {
                            console.log("Error:", error);
                        });
                    }}>Inbound: Approval</button>

                {inboundId && <div>Inbound Id: {inboundId}</div>}

                <input
                    id="inboundId"
                    name="indboundId"
                    type="text"
                    autoComplete="inboundId"
                    placeholder='Inbound Id'
                    required
                    className="appearance-none block w-1/2 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    value={inboundId}
                    onChange={(e) => setInboundId(e.target.value)}
                />

                {/* List Inbound */}
                <button
                    className="mt-1 ml-1 flex items-center justify-center px-4 py-2 rounded-md font-bold focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white bg-blue-600 text-white"
                    onClick={() => {
                        console.log("List Inbound");
                        Parse.Cloud.run("ListInbound", { faAccountId: financialAccountId }).then((result) => {
                            console.log("Inbound List Result:", result);
                        }).catch((error) => {
                            console.log("Error:", error);
                        });
                    }}>List Inbound for FA Account</button>

            </div>

            <hr/>

            <h3 className='mt-8 font-bold'>Outbound Transfer</h3>
            <input
                id="obAccountId"
                name="obAccountId"
                type="text"
                autoComplete="obAccountId"
                placeholder='Source Account Id'
                required
                className="appearance-none block w-1/2 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                value={obAccountId}
                onChange={(e) => setObAccountId(e.target.value)}
            />

            <input
                id="obFinAccountId"
                name="obFinAccountId"
                type="text"
                autoComplete="obFinAccountId"
                placeholder='Source: Fin Account Id'
                required
                className="appearance-none block w-1/2 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                value={obSourceFaId}
                onChange={(e) => setObSourceFaId(e.target.value)}
            />

            <input
                id="obPaymentMethodId"
                name="obPaymentMethodId"
                type="text"
                autoComplete="obPaymentMethodId"
                placeholder='Dest: FA ID'
                required
                className="appearance-none block w-1/2 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                value={obDestFaId}
                onChange={(e) => setObDestFaId(e.target.value)}
            />

            
                        
            <button
                className="mt-1 ml-1 flex items-center justify-center px-4 py-2 rounded-md font-bold focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white bg-blue-600 text-white"
                onClick={() => {
                    console.log("Begin Outbound Payment");
                    Parse.Cloud.run("BeginOutboundPayment", {accountId: obAccountId, sourceFaId: obSourceFaId, destFaId: obDestFaId }).then((result) => {
                        console.log("Outbound Payment Result:", result);
                    }).catch((error) => {
                        console.log("Error:", error);
                    });
                }}>Do Outbound Transfer</button>




            <button
                className="mt-1 ml-1 flex items-center justify-center px-4 py-2 rounded-md font-bold focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white bg-blue-600 text-white"
                onClick={() => {
                    console.log("List Transactions");
                    Parse.Cloud.run("ListTransactions", {accountId: obAccountId, faAccountId: obSourceFaId}).then((result) => {
                        console.log("List Transactions Result:", result);
                    }).catch((error) => {
                        console.log("Error:", error);
                    });
                }}>List Transactions</button>

            
            
            <button
                className="mt-1 ml-1 flex items-center justify-center px-4 py-2 rounded-md font-bold focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white bg-blue-600 text-white"
                onClick={() => {
                    console.log("GetFinancialAccount");
                    Parse.Cloud.run("GetFinancialAccount", {accountId: obAccountId, faAccountId: obSourceFaId}).then((result) => {
                        console.log("GetFinancialAccount Result:", result);
                    }).catch((error) => {
                        console.log("Error:", error);
                    });
                }}>GetFinancialAccount</button>

        </>
    )
}