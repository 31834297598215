import { Fragment, useState } from 'react'
import { Link } from "react-router-dom";

import {
	ChevronRightIcon,
    UsersIcon,
    CurrencyDollarIcon,
    CheckCircleIcon,
    XCircleIcon
} from '@heroicons/react/24/outline'

import { ArrowSmallDownIcon, ArrowSmallUpIcon, PlusCircleIcon } from '@heroicons/react/20/solid'

import * as Constants from '../../model/index';


function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

export default function DashboardProjects() {
    return (
        <>
            {/* Team Stats */}
            <div>
                <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                    {/* Team Number */}
                    <div className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
                        <dt>
                            <div className="absolute bg-blue-500 rounded-md p-3">
                                <UsersIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </div>
                            <p className="ml-16 text-sm font-medium text-left text-gray-500 truncate">Total Projects</p>
                        </dt>
                        <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                            <p className="text-2xl font-semibold text-gray-900">9</p>
                            <p
                                className={classNames(
                                    'text-green-600', // : 'text-red-600',
                                    'ml-2 flex items-baseline text-sm font-semibold'
                                )}>
                                <ArrowSmallUpIcon className="self-center flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
                                {/*<ArrowSmallDownIcon className="self-center flex-shrink-0 h-5 w-5 text-red-500" aria-hidden="true" />*/}
                                10%
                            </p>
                            <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
                                <div className="text-sm text-left">
                                    <a href="#teamDeets" className="font-bold text-blue-600 hover:text-blue-500">
                                        {' '}
                                        View Details
                                    </a>
                                </div>
                            </div>
                        </dd>
                    </div>

                    {/* Pay Amount */}
                    <div className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
                        <dt>
                            <div className="absolute bg-blue-500 rounded-md p-3">
                                <CurrencyDollarIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </div>
                            <p className="ml-16 text-sm font-medium text-left text-gray-500 truncate">Total Project Revenue</p>
                        </dt>
                        <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                            <p className="text-2xl font-semibold text-gray-900">$188,000</p>
                            <p
                                className={classNames(
                                    //'text-green-600', // : 
                                    'text-red-600',
                                    'ml-2 flex items-baseline text-sm font-semibold'
                                )}>
                                {/* <ArrowSmallUpIcon className="self-center flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" /> */}
                                <ArrowSmallDownIcon className="self-center flex-shrink-0 h-5 w-5 text-red-500" aria-hidden="true" />
                                9%
                            </p>
                            <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
                                <div className="text-sm text-left">
                                    <a href="#projectDeets" className="font-bold text-blue-600 hover:text-blue-500">
                                        {' '}
                                        View Details
                                    </a>
                                </div>
                            </div>
                        </dd>
                    </div>
                                  
                </dl>
            </div>


            {/* Team List */}
            <div>
                <div className="sm:hidden">
                    <label htmlFor="tabs" className="sr-only">
                        Select a tab
                    </label>
                    {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                    <select
                        id="tabs"
                        name="tabs"
                        className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                        defaultValue={Constants.tabs.find((tab) => tab.current).name}
                    >
                        {Constants.tabs.map((tab) => (
                            <option key={tab.name}>{tab.name}</option>
                        ))}
                    </select>
                </div>

                <div className="hidden sm:block mt-10 text-left">
                    <div className="flex justify-start">
                        <div className="text-4xl text-gray-900">Projects List</div>
                        <div className="flex">
                            <button className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium focus:outline-none"
                                onClick={() => console.log("New Project Clicked")}>
                                <PlusCircleIcon className="self-center flex-shrink-0 h-8 w-8 text-blue-500" />
                                <div className="ml-1 font-bold text-blue-500"><Link to="/new-project">New Project</Link></div>
                            </button>
                        </div>
                    </div>

                    <div className="border-b border-gray-200">
                        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                            {Constants.teamTabs.map((tab) => (
                                <a
                                    key={tab.name}
                                    href="#projects"
                                    className={classNames(
                                        tab.current
                                            ? 'border-blue-500 text-blue-600'
                                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                                        'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm'
                                    )}
                                    aria-current={tab.current ? 'page' : undefined}
                                >
                                    {tab.name}
                                    {tab.count ? (
                                        <span
                                            className={classNames(
                                                tab.current ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-900',
                                                'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                                            )}
                                        >
                                            {tab.count}
                                        </span>
                                    ) : null}
                                </a>
                            ))}
                        </nav>
                    </div>
                </div>
            </div>

            {/* Team Table */}
            <div className="mt-5 flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Project Name
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Details 1
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Details 2
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Details 3
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Details 4
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Details 5
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Status
                                        </th>
                                        <th scope="col" className="relative px-6 py-3">
                                            <span className="sr-only">View Project</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* Due Date Title Owner Revenue Costs Profitability Status */}
                                    {/* {firstName: "Sam", lastName: "Altman", email: "cbill@cbill.com", phone: "(555) 456-7890", rate: 65, projectCount: 12, totalPaid: 8400, taxReady: true, status: "ready"}, */}
                                    {Constants.teamList.map((worker, index) => (
                                        <tr key={worker.email} className={index % 2 === 0 ? 'bg-white' : 'bg-blue-50'}>
                                            <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium text-gray-900">{worker.firstName} {worker.lastName}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium text-gray-500">{worker.email}<br/>{worker.phone}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium text-gray-500">${worker.rate}/hr</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium text-gray-500">{worker.projectCount}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium text-gray-500">${worker.totalPaid.toFixed(2)}</td>
                                            <td className="px-6 py-4 whitespace-nowrap flex justify-center text-sm font-medium text-green-500">{worker.taxReady ? <CheckCircleIcon className="w-8" /> : <XCircleIcon />}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium text-gray-500">
                                                {worker.status === "Active" ?
                                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                        {worker.status}
                                                    </span>
                                                    :
                                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                                        {worker.status}
                                                    </span>
                                                }
                                            </td>                                            
                                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                <a href="#edit" className="text-blue-600 hover:text-blue-900">
                                                    <ChevronRightIcon className="w-5 h-5 text-blue-600" />
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}