
import { Fragment, useState, useEffect } from 'react'
import { useParams, useNavigate } from "react-router-dom";
import validator from 'validator';
import NumberFormat from 'react-number-format'

import { ReactComponent as QurbieLogo } from '../assets/Qurbie.svg'
import ModalOverlay from './ModalOverlay';

import Parse from 'parse';

import ModalDialog from './ModalDialog';
import ModalIndeterminate from './ModalIndeterminate';
import { BellIcon, PhoneIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/outline';

const progressList = [
	{ id: '1', name: 'Create Account', href: '#', status: 'current' },
	{ id: '2', name: 'Setup Payment', href: '#', status: 'upcoming' },
	{ id: '3', name: 'Setup Tax Info', href: '#', status: 'upcoming' },
	{ id: '4', name: 'Start Working', href: '#', status: 'upcoming' },
]


export default function SignUpWorker1Account(props) {
	let { requestId } = useParams();
	let navigate = useNavigate();
	const [alert, setAlert] = useState({ show: false });
	const [progress, setProgress] = useState({ show: false });
	const [workerRequest, setWorkerRequest] = useState();
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [password, setPassword] = useState();
	const [confirmPassword, setConfirmPassword] = useState();
	const [clientIp, setClientIp] = useState();

	useEffect(() => {		
		getUsersIp();
	}, []);

	const getUsersIp = async () => {
		console.log("Getting client IP...");
	
		let usersIp = await getIpWithIpAPI();
		
		if (!usersIp) {
			usersIp = await getIpWithIPIFY();
		}
		
		setClientIp(usersIp);
		console.log("Client IP:", usersIp);
	}
	
	const getIpWithIpAPI = async () => {
		console.log("Using IP-API");
	
		try {
			const result = await fetch('http://ip-api.com/json/');
			console.log("Result:", result);
			const resultJson = await result.json();
			const ip = resultJson.query;
			return ip;
		} catch (error) {
			console.log("IP-API: Unable to get IP", error);
		}
	}
	
	const getIpWithIPIFY = async () => {
		console.log("Using IP-IFY");
	
		try {
			const result = await fetch('https://api.ipify.org?format=json');
			console.log("Result:", result);
			const resultJson = await result.json();
			const ip = resultJson.ip;
			return ip;
		} catch (error) {
			console.log("IP-IFY: Unable to get IP", error);
		}
	}

	

	

	

	// get request details	
	useEffect(() => {
		// grab requestId from url via react-router
		if (!requestId) {
			console.log("Request Id Not Found");
			return;
		}

		setProgress({ show: true, title: 'Loading Request' });

		console.log("Running getWorkerRequestWithId", requestId);
		Parse.Cloud.run('getWorkerRequestWithId', { requestId: requestId })
			.then((result) => {
				if (result?.email) {
					setWorkerRequest(result);
					setEmail(result?.email || '');
					setFirstName(result?.firstName || '');
					setLastName(result?.lastName || '');
					setProgress({ show: false });
				} else {
					setProgress({ show: false });
					setAlert({
						show: true,
						title: 'Work Request Not Found',
						message: 'This work request is invalid or has already been completed. If this is an error, please reach out to the company that requested you as a worker.',
						//confirmFunc: (()=>navigate("/", {replace: true}))
					})
				}
			})
			.catch((error) => {
				setProgress({ show: false });
				setAlert({
					show: true,
					title: 'Work Request Not Found',
					message: 'This work request is invalid or has already been completed. If this is an error, please reach out to the company that requested you as a worker.',
					//confirmFunc: (()=>navigate("/", {replace: true}))
				});
			});
	}, [requestId]);

	const createWorkerObject = async (user) => {
		// todo: create worker object from:
		const newWorker = new Parse.Object("Workers");

		try {
			const businessId = workerRequest.businessId;

			const businessQuery = new Parse.Query("Business");
			const business = await businessQuery.get(businessId);

			const hourlyRate = workerRequest?.hourlyRate.replace('$', '') || '0.00';

			// user account
			newWorker.set('userAccountId', user.id);
			newWorker.set('userAccount', user);
			newWorker.set('businessId', businessId);
			newWorker.set('business', business);
			newWorker.set('firstName', firstName);
			newWorker.set('lastName', lastName);
			newWorker.set('email', email);
			newWorker.set('rate', hourlyRate);
			newWorker.set('phone', phone);
			const finalWorker = await newWorker.save();

			return finalWorker;

		} catch (error) {
			console.log("Error Creating Worker Object:", error);
			return error;
		}
	}

	const handleCreateWorkerAccount = async () => {
		if (!clientIp) {
			setAlert({ show: true, title: 'Unable to Create Account', message: 'Unable to get your IP address. We must retrieve this value for security purposes. If you are private browsing, behind a VPN, or have a plug-in that would block access to this value you must enable this during account creation. Please check your system and connection and try again.' });
			return;
		}

		if (!firstName || !lastName) {
			setAlert({ show: true, title: 'Needed Information', message: 'Please enter your first and last name.' });
			return;
		}

		if (!password || !confirmPassword || password !== confirmPassword) {
			setAlert({ show: true, title: 'Password Issue', message: 'Please enter a valid password and make sure the password matches the confirm password field.' });
			return;
		}

		if (!validator.isEmail(email)) {
			setAlert({ show: true, title: 'Invalid Email', message: 'Please enter a valid email and try again.' });
			return;
		}

		try {

			// create user account
			const newUser = new Parse.User();
			newUser.set('email', email);
			newUser.set('firstName', firstName);
			newUser.set('lastName', lastName);
			newUser.set('phone', phone);
			newUser.set('username', email);
			newUser.set('password', confirmPassword);
			newUser.set('isWorker', true);

			// TODO: This should likely be done on the server side to prevent any malicious issues			
			newUser.set('workerFor', [`${workerRequest.businessId}`])

			setProgress({ show: true, title: 'Creating Account' });
			const savedUser = await newUser.signUp();
			console.log("Worker Account Created!");

			// create worker object
			const worker = await createWorkerObject(savedUser);
			console.log("Worker Object Created:", worker);
			//console.log("Worker Id:", worker.id);

			// create connect account for worker
			const stripeResult = await setupConnect(savedUser, worker);
			console.log("Stripe Result:", stripeResult);

			// update the worker object
			await worker.fetch();

			// save worker id to local storage
			localStorage.setItem('workerSignUpId', worker.id);

			setProgress({ show: false });

			const verifyLink = worker.get("stripeVerifyLink");
			document.location.href = verifyLink;

		} catch (error) {
			setProgress({ show: false });
			console.log("Error:", error);
			setTimeout(() => setAlert({ show: true, title: 'Unable to Create Account', message: `Unable to create an account. ${error}` }), 200);
		}
	}

	const setupConnect = async (savedUser, worker) => {
		try {
			if (!worker) {
				console.log("No worker!");
				return;
			}

			if (!savedUser || !savedUser.get("email")) {
				console.log("No saved user");
				return { error: 'No User Found' };
			}

			if (!clientIp) {
				console.log("No ClientIp!");
			}

			// now create stripe account
			const result = await Parse.Cloud.run("QurbieCreateStripeWorkerAccount", { email: savedUser.get("email"), workerId: worker.id, clientIp: clientIp });

			console.log('Result:', result);

			if (result.rawType) {
				setAlert({ show: true, title: 'An Error Occured', message: `Something went wrong when trying to finalize your account: ${result.raw.message}` });
				return;
			}

			console.log("Worker Stripe Account Created:", result);
			return result;

		} catch (error) {
			setProgress({ show: false });
			console.log(error);
			return error;
		}
	}


	return (
		<>
			<ModalDialog show={alert.show || false} title={alert.title} message={alert.message}
				confirmButtonTitle={alert.okTitle || "Ok"} cancelButtonTitle={alert.cancelTitle} icon={<BellIcon className="text-blue-500" />}
				confirmButtonFunc={alert.confirmFunc || (() => setAlert({ showAlert: false }))} />

			<ModalIndeterminate show={progress.show} title={progress.title || ''} message={progress.message || ''} />

			<div className="bg-white min-h-full w-1/2 flex flex-col justify-center p-0 mx-auto my-20 rounded-2xl">
				<div className="sm:mx-auto sm:w-full sm:max-w-md">
					<QurbieLogo className="mx-auto h-12 w-auto" />
					<h2 className="mt-6 text-center text-2xl font-extrabold text-gray-700">Create an Account</h2>
					<h3 className="mt-4 text-center text-base text-gray-500"><strong>{workerRequest?.businessName || 'A Company '} Has Requested to Add You to Their Qurbie Payments Account.</strong><br /><br />With Qurbie, you submit work hours, and get paid faster. Paystubs and taxes are also always avaiable on your account.
						Already have an account? <a href="/sign-up" className="font-bold text-sm text-blue-500 hover:text-blue-700"> Sign In.</a>
					</h3>
				</div>

				<div className="w-full">
					{/* Progress Bar */}
					<div className="mt-20 mb-10 lg:ml-0 lg:mr-0">
						<nav aria-label="Progress">
							<ol className="space-y-4 md:flex md:space-y-0 md:space-x-8">
								{progressList.map((step) => (
									<li key={step.name} className="md:flex-1">
										{step.status === 'complete' ? (
											<a href={step.href} className="group pl-4 py-2 flex flex-col border-l-4 border-green-600 hover:border-green-800 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4">
												<span className="text-xs text-green-600 font-semibold tracking-wide uppercase group-hover:text-green-800">
													{step.id} | {step.name}
												</span>
												{/* <span className="text-sm font-medium text-green-600">{step.name}</span> */}
											</a>
										) : step.status === 'current' ? (
											<a href={step.href} className="pl-4 py-2 flex flex-col border-l-4 border-blue-600 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4" aria-current="step">
												<span className="text-xs text-blue-600 font-semibold tracking-wide uppercase">{step.id} | {step.name}</span>
												{/* <span className="text-sm font-medium">{step.name}</span> */}
											</a>
										) : (
											<a href={step.href} className="group pl-4 py-2 flex flex-col border-l-4 border-gray-200 hover:border-gray-300 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4">
												<span className="text-xs text-gray-500 font-semibold tracking-wide uppercase group-hover:text-gray-700">
													{step.id} | {step.name}
												</span>
												{/* <span className="text-sm font-medium">{step.name}</span> */}
											</a>
										)}
									</li>
								))}
							</ol>
						</nav>
					</div>
				</div>


				{/* Account Information */}
				<form className="space-y-8 divide-y divide-gray-200">
					<div className="space-y-8 divide-y divide-gray-200">
						<div className="pt-10">

							<div>
								<h3 className="text-lg leading-6 font-medium text-gray-900">Account Information</h3>
								<p className="mt-1 text-sm text-gray-500">Please create a password for your account.</p>
							</div>

							<div className="my-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
								{/* Email Input Field */}
								<div className="sm:col-span-full">
									<label htmlFor="email" className="block text-sm font-medium text-gray-700">
										Email address
									</label>
									<div className="mt-1">
										<input
											id="email"
											name="email"
											type="email"
											// disabled
											autoComplete="email"
											value={email}
											className="shadow-sm bg-gray-100 focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
											onChange={(e) => setEmail(e.target.value)}
										/>
									</div>
								</div>

								{/* First Name Field */}
								<div className="sm:col-span-3">
									<label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
										First Name
									</label>
									<div className="mt-1">
										<input
											id="firstName"
											name="firstName"
											type="text"
											autoComplete="firstName"
											value={firstName}
											className="shadow-sm bg-white focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
											onChange={(e) => setFirstName(e.target.value)}
										/>
									</div>
								</div>

								{/* Last Name Field */}
								<div className="sm:col-span-3">
									<label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
										Last Name
									</label>
									<div className="mt-1">
										<input
											id="lastName"
											name="lastName"
											type="text"
											autoComplete="lastName"
											value={lastName}
											className="shadow-sm bg-white focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
											onChange={(e) => setLastName(e.target.value)}
										/>
									</div>
								</div>

								{/* Phone Input Field */}
								<div className="sm:col-span-full">
									<label htmlFor="password" className="block text-sm font-medium text-gray-700">
										<div className="flex justify-start items-center">
											<div>Phone</div>
											<QuestionMarkCircleIcon className="ml-2 h-4 w-4 text-blue-700" aria-hidden="true" />
										</div>
									</label>
									<div className="mt-1">
										<NumberFormat
											placeholder={"(___)-___-____"}
											format={"(###)-###-####"}
											mask="_"
											className="mt-1 shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
											onChange={(e) => setPhone(e.target.value)}
										/>
									</div>
								</div>

								{/* Password Input Field */}
								<div className="sm:col-span-3">
									<label htmlFor="password" className="block text-sm font-medium text-gray-700">
										Password
									</label>
									<div className="mt-1">
										<input
											id="password"
											name="password"
											type="password"
											autoComplete="password"
											className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
											onChange={(e) => setPassword(e.target.value)}
										/>
									</div>
								</div>

								{/* Confirm Password Field */}
								<div className="sm:col-span-3">
									<label htmlFor="confim-password" className="block text-sm font-medium text-gray-700">
										Confirm Password
									</label>
									<div className="mt-1">
										<input
											id="confirm-password"
											name="confirm-password"
											type="password"
											autoComplete="confirm-password"
											className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
											onChange={(e) => setConfirmPassword(e.target.value)}
										/>
									</div>
								</div>

							</div>
						</div>
					</div>
					<div className="pt-5">
						<div className="flex justify-end">
							<button
								type="button"
								className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
							>
								Contact Company
							</button>
							<button
								type="submit"
								className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
								onClick={e => {
									e.preventDefault();

									// create worker account 
									handleCreateWorkerAccount();
								}}
							>
								Create Account
							</button>
						</div>
					</div>
				</form>				
				<div className="mt-8 text-xs text-gray-300 text-center">
					Client IP: {clientIp} - Logged for Security Purposes
				</div>
			</div>			
		</>
	)
}