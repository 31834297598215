import { Fragment, useState, useEffect } from 'react'
import { PlusCircleIcon } from '@heroicons/react/20/solid'
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { updateUserInfo } from '../app/UserSlice';

import { loadSettings } from '../util/settingsStorage';

import Parse from 'parse';
import handleParseError from '../util/handleParseError';

import * as Constants from '../model/index';
import { ChevronRightIcon } from '@heroicons/react/24/outline';

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

function formattedDate(dateString) {
    let date = new Date(dateString).toLocaleDateString("en-US");
    return date;
}

export default function InvoiceHistory(props) {   
    const [appSettings, setAppSettings] = useState(loadSettings()); 
    const [currentTab, setCurrentTab] = useState('Pending');
    const [allCount, setAllAcount] = useState(0);
    const [pendingCount, setPendingCount] = useState(0);
    const [issuesCount, setIssuesCount] = useState(0);
    const [completedCount, setCompletedCount] = useState(0);
    const [displayItems, setDisplayItems] = useState([]);
    const [editIndex, setEditIndex] = useState(-1);
    const [editedTitle, setEditedTitle] = useState('');

    const userState = useSelector((state) => state.userState);
        
    const dispatch = useDispatch(); 
    const navigate = useNavigate();


    useEffect(()=>{
        getUserData();   
    }, []);
    
    useEffect(()=>{
        changeTab(currentTab);
    }, [userState.paymentHistory]);

    const getUserData = async () => {
        Parse.Cloud.run('GetUserData', {}).then((result) => {				
            console.log('GetUserData Result:', result);
            processTabCounts(result.paymentHistory);
            changeTab('Pending');
            
            dispatch(updateUserInfo(result))
        })
        .catch((error) => {
            handleParseError(error, ()=>{
                // token expired
                console.log("GetUserData: User Login Expired");
                Parse.User.logOut().then(()=> navigate('/sign-in', {replace: true}));
            });            
        });	
    }

    const getTotalPaymentHistory = (tabName) => {
        console.log("Showing Total Items: ", userState.paymentHistory.length);
        let filteredPaymentHistory = userState.paymentHistory;
        
        if (tabName === 'Pending') {
            filteredPaymentHistory = userState.paymentHistory.filter(payment => payment.status === 'Pending');
        } else if (tabName === 'Completed') {
            filteredPaymentHistory = userState.paymentHistory.filter(payment => payment.status === 'Completed');
        } else if (tabName === 'Issues') {
            filteredPaymentHistory = userState.paymentHistory.filter(payment => payment.status === 'Issues');
        }

        return (props.showLimitedItems ? filteredPaymentHistory.slice(0,5) : filteredPaymentHistory);
    }

    const changeTab = (tabText) => {
        if (tabText === 'All') {
            setCurrentTab('All');
            setDisplayItems(getTotalPaymentHistory());
        } else if (tabText === 'Pending') {
            setCurrentTab('Pending');
            setDisplayItems(getTotalPaymentHistory('Pending'));
        } else if (tabText === 'Completed') {
            console.log("Setting current tab to completed");
            console.log("Tital items completed:", getTotalPaymentHistory().filter(payment => payment.status === 'Completed'))
            setCurrentTab('Completed');
            setDisplayItems(getTotalPaymentHistory('Completed'));
        } else if (tabText === 'Issues') {
            setCurrentTab('Issues');
            setDisplayItems(getTotalPaymentHistory('Issues'));
        } else {
            setCurrentTab('All');
            setDisplayItems(getTotalPaymentHistory('All'));
        }

    }    


    const processTabCounts = (payments) => {
        if (!payments) return;

        setAllAcount(payments?.length || 0);

        let totalPending = 0;
        let totalCompleted = 0; 
        let totalIssues = 0;

        payments?.forEach(payment => {
            if (payment.status === "Pending") {                                
                totalPending++;
            } else if (payment.status === "Completed") {
                totalCompleted++;
            } else if (payment.status === "Issues") {
                totalIssues++;
            }
        });

        setPendingCount(totalPending);
        setCompletedCount(totalCompleted);            
        setIssuesCount(totalIssues);

        setCurrentTab(currentTab);
    }

    const saveEditedTitle = async (index) => {
        if (!editedTitle) {
            console.log("Title not edited");            
            return;
        }

        const payment = userState.paymentHistory[index];        
        
        if (!payment || !payment.id) {
            console.log("Unable to find payment id");
            return;
        }
        
        Parse.Cloud.run('UpdatePaymentTitle', {paymentId: payment.id, newTitle: editedTitle}).then((result) => {
            console.log('UpdatePaymentTitle Result:', result);
            getUserData();            
            setEditIndex(null);
            setEditedTitle('');
        })
        .catch((error) => {
            console.log("UpdatePaymentTitle Error:", error);            
        });
    }

    return (
        <>            
            {/* Payments List */}
            <div>
                <div className="sm:hidden">
                    <label htmlFor="tabs" className="sr-only">
                        Select a tab
                    </label>
                    {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                    <select
                        id="tabs"
                        name="tabs"
                        className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                        defaultValue={Constants.tabs.find((tab) => tab.current).name}
                    >                        
                        <option>Pending</option>
                        <option>Completed</option>
                        <option>Issues</option>
                        <option>All</option>
                    </select>
                </div>

                <div className="hidden sm:block mt-10 text-left">
                    <div className="flex justify-between">
                        {/* <div className="text-4xl text-gray-900">Contractors</div> */}                        
                    </div>
                    <div className="border-b border-gray-200">
                        <div className='flex'>
                            <nav className="-mb-px flex space-x-8" aria-label="Tabs">                                

                                {/* Pending Tab */}
                                <div
                                    className={classNames(
                                        currentTab === 'Pending'
                                            ? 'border-blue-500 text-blue-600'
                                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                                        'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm cursor-pointer'
                                    )}
                                    aria-current={'page'}
                                    onClick={() => {
                                        changeTab('Pending');
                                    }}
                                >
                                    {'Pending'}
                                    
                                    <span
                                        className={classNames(
                                            currentTab === 'Pending' ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-900',
                                            'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                                        )}
                                    >
                                        {pendingCount}
                                    </span>
                                </div>

                                {/* Completed Tab */}
                                <div
                                    className={classNames(
                                        currentTab === 'Completed'
                                            ? 'border-blue-500 text-blue-600'
                                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                                        'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm cursor-pointer'
                                    )}
                                    aria-current={'page'}
                                    onClick={() => {
                                        changeTab('Completed');
                                    }}
                                >
                                    {'Completed'}
                                    
                                    <span
                                        className={classNames(
                                            currentTab === 'Completed' ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-900',
                                            'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                                        )}
                                    >
                                        {completedCount}
                                    </span>                                        
                                </div>

                                {/* Issues Tab */}
                                <div                                    
                                    className={classNames(
                                        currentTab === 'Issues'
                                            ? 'border-blue-500 text-blue-600'
                                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                                        'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm cursor-pointer'
                                    )}
                                    aria-current={'page'}
                                    onClick={() => {
                                        changeTab('Issues');
                                    }}
                                >
                                    {'Issues'}
                                    
                                    <span
                                        className={classNames(
                                            currentTab === 'Issues' ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-900',
                                            'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                                        )}
                                    >
                                        {issuesCount}
                                    </span>                                        
                                </div>

                                {/* All Tab */}
                                <div
                                    className={classNames(
                                        currentTab === 'All'
                                            ? 'border-blue-500 text-blue-600'
                                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                                        'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm cursor-pointer'
                                    )}
                                    aria-current={'page'}
                                    onClick={() => {
                                        changeTab('All');
                                    }}
                                >
                                    {'All'}
                                    
                                    <span
                                        className={classNames(
                                            currentTab === 'All' ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-900',
                                            'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                                        )}
                                    >
                                        {allCount}
                                    </span>                                        
                                </div>
                            </nav>                            
                        </div>
                    </div>
                </div>
            </div>

            {/* Payments Table */}
            <div className="mt-5 flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>     
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Invoice:
                                        </th>                                                                           
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Description
                                        </th>                                        
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-right text-xs font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Hours
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-right text-xs font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Total Amount
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-right text-xs font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Date Req.
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-center text-xs font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Status
                                        </th>
                                        <th className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium text-gray-500">{/* Actions */}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(!displayItems || displayItems.length === 0) &&
                                        <tr className={'bg-white hover:bg-blue-100' }>                                            
                                            <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium text-gray-900">No {currentTab} Items Available for Review.</td>
                                        </tr>
                                    }

                                    {displayItems && displayItems.map((payment, index) => (                                        
                                        <tr key={index} 
                                            className={classNames(index % 2 === 0 ? 'bg-white' : 'bg-blue-50', 'hover:bg-blue-100 cursor-pointer')}
                                            onClick={() => {
                                                console.log("Selected!");
                                                if (!props.showLimitedItems) {
                                                    setEditIndex(index)}
                                                }
                                                
                                            }>

                                            <td className="px-8 py-4 whitespace-nowrap text-left text-sm font-medium text-gray-900">{payment.invoiceNumber || '----'}</td>

                                            {editIndex !== index ?
                                                <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium text-gray-900">{payment.title}</td>
                                                :
                                                <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium text-gray-900">
                                                    <div className='flex'>
                                                        <input
                                                            type="text"
                                                            name="title"
                                                            id="title"
                                                            className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                            defaultValue={payment.title}
                                                            onChange={(e) => {
                                                                e.stopPropagation();
                                                                setEditedTitle(e.target.value)
                                                            }}
                                                        />  
                                                        <button 
                                                            className="mt-1 ml-1 flex items-center justify-center px-4 py-2 rounded-md font-bold focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white bg-blue-600 text-white"
                                                            onClick={(e)=> {
                                                                e.stopPropagation();
                                                                saveEditedTitle(index);
                                                            }}>
                                                            Save
                                                        </button>
                                                        <button 
                                                            className="mt-1 ml-1 flex items-center justify-center px-4 py-2 rounded-md font-bold focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white bg-gray-300 text-black"
                                                            onClick={(e)=> {
                                                                e.stopPropagation();
                                                                console.log("Clicked");
                                                                saveEditedTitle('');
                                                                setEditIndex(-1);
                                                            }}>
                                                            X
                                                        </button>
                                                    </div>
                                                </td>
                                            }

                                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium text-gray-500">{parseFloat(payment.hours)?.toFixed(2) || 'n/a'}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium text-gray-500">${payment.amountRequested}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium text-gray-500">{formattedDate(payment.createdDate)}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium text-gray-500">
                                                {payment.status === "Completed" &&
                                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                        {payment.status}
                                                    </span>
                                                }
                                                
                                                {payment.status === "Pending" &&                                                    
                                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-600 text-white">
                                                        {payment.status}
                                                    </span>
                                                }
                                                
                                                {payment.status === "Issues" &&                                                    
                                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-orange-600 text-white">
                                                        {payment.status}
                                                    </span>
                                                }
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                {!props.showLimitedItems && 
                                                    <ChevronRightIcon className="w-5 h-5 text-blue-600" />
                                                }
                                            </td>
                                        </tr>                                        
                                    ))}                                    
                                    <tr className={classNames((displayItems.length > 0 && displayItems.length % 2 === 0) ? 'bg-white' : 'bg-blue-50', '')}>
                                        <td colspan='7'>
                                            {props.showLimitedItems &&
                                                <div className='mx-4 my-4 flex justify-end items-center'>
                                                        <Link className='group rounded-md w-full'
                                                            to='/dashboard'
                                                                onClick={() => console.log("Clicked")}>
                                                                <div className='flex justify-end items-center text-blue-400 hover:text-blue-700'>
                                                                    <div className='text-sm font-medium'><span className="text-black">Showing 1-5 of {userState.paymentHistory.length} Items - </span> View All Invoices</div>
                                                                    <ChevronRightIcon className="w-4 h-4" />
                                                                </div>
                                                        </Link>                                    
                                                </div>
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>                            
                        </div>                        
                    </div>
                </div>
            </div>
        </>
    );
}