// settingsStorage.js

// Function to save settings to local storage
export const saveSettings = (newSettings) => {
    const currentSettings = loadSettings() || {};
    const mergedSettings = { ...currentSettings, ...newSettings };
    localStorage.setItem('q-settings', JSON.stringify(mergedSettings));
    //console.log("Saved Settings:", mergedSettings);
};
  
// Function to load settings from local storage
export const loadSettings = () => {
    const savedSettings = localStorage.getItem('q-settings');
    console.log("Loaded Settings:", savedSettings);
    return savedSettings ? JSON.parse(savedSettings) : null;
};

  // Function to clear settings from local storage
export const clearSettings = () => {
    localStorage.removeItem('q-settings');
};