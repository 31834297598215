import { Fragment, useState } from 'react'

import {
	ChevronRightIcon,
    UsersIcon,
    CurrencyDollarIcon,
    EnvelopeIcon,
    PhoneIcon
} from '@heroicons/react/24/outline'

import { ArrowSmallDownIcon, ArrowSmallUpIcon, PlusCircleIcon } from '@heroicons/react/20/solid'

import * as Constants from '../../model/index';

export const invoicesList = [
    {status: "Active", dueDate: "1-23-2022", invoiceNumber: "0234", clientName: "Right Hat", amountDue: 22000, clientEmail: "client1@thisistheclient.com", clientPhone: "(555) 456-7890"},
    {status: "Overdue", dueDate: "12-10-2021", invoiceNumber: "0233", clientName: "Allied Dispatch Solutions", amountDue: 8000, clientEmail: "client12@thisistheclient.com", clientPhone: "(555) 456-7890"},
    {status: "Draft", dueDate: "2-16-2022", invoiceNumber: "0232", clientName: "BrewFund, Inc.", amountDue: 120000, clientEmail: "client1@thisistheclient.com", clientPhone: "(555) 456-7890"},
    {status: "Paid", dueDate: "11-6-2021", invoiceNumber: "0231", clientName: "Duffek Mobile", amountDue: 850, clientEmail: "client3@thisistheclient.com", clientPhone: "(555) 456-7890"},
    {status: "Paid", dueDate: "4-18-2021", invoiceNumber: "0230", clientName: "Brand Content", amountDue: 2237, clientEmail: "client2@thisistheclient.com", clientPhone: "(555) 456-7890"},
]

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

function calculateTab(tab) {
    return invoicesList.filter( invoice => invoice.status === tab ).length;
}


export default function DashboardInvoices() {
    return (
        <>
            {/* Team Stats */}
            <div>
                <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
                    {/* Team Number */}
                    <div className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
                        <dt>
                            <div className="absolute bg-blue-500 rounded-md p-3">
                                <UsersIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </div>
                            <p className="ml-16 text-sm font-medium text-left text-gray-500 truncate">Total Invoices YTD</p>
                        </dt>
                        <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                            <p className="text-2xl font-semibold text-gray-900">139</p>
                            <p
                                className={classNames(
                                    'text-green-600', // : 'text-red-600',
                                    'ml-2 flex items-baseline text-sm font-semibold'
                                )}>
                                <ArrowSmallUpIcon className="self-center flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
                                {/*<ArrowSmallDownIcon className="self-center flex-shrink-0 h-5 w-5 text-red-500" aria-hidden="true" />*/}
                                10%
                            </p>
                            <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
                                <div className="text-sm text-left">
                                    <a href="#teamDeets" className="font-bold text-blue-600 hover:text-blue-500">
                                        {' '}
                                        View Details
                                    </a>
                                </div>
                            </div>
                        </dd>
                    </div>

                    {/* Paid Amount */}
                    <div className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
                        <dt>
                            <div className="absolute bg-blue-500 rounded-md p-3">
                                <CurrencyDollarIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </div>
                            <p className="ml-16 text-sm font-medium text-left text-gray-500 truncate">Total Paid Revenue</p>
                        </dt>
                        <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                            <p className="text-2xl font-semibold text-gray-900">$188,000</p>
                            <p
                                className={classNames(
                                    'text-green-600', // : 
                                    //'text-red-600',
                                    'ml-2 flex items-baseline text-sm font-semibold'
                                )}>
                                <ArrowSmallUpIcon className="self-center flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
                                {/* <ArrowSmallDownIcon className="self-center flex-shrink-0 h-5 w-5 text-red-500" aria-hidden="true" /> */}
                                9%
                            </p>
                            <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
                                <div className="text-sm text-left">
                                    <a href="#projectDeets" className="font-bold text-blue-600 hover:text-blue-500">
                                        {' '}
                                        View Details
                                    </a>
                                </div>
                            </div>
                        </dd>
                    </div>

                    {/* Unpaid Amount */}
                    <div className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
                        <dt>
                            <div className="absolute bg-blue-500 rounded-md p-3">
                                <CurrencyDollarIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </div>
                            <p className="ml-16 text-sm font-medium text-left text-gray-500 truncate">Total Unpaid Invoices</p>
                        </dt>
                        <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                            <p className="text-2xl font-semibold text-red-500">18</p>
                            <p
                                className={classNames(
                                    //'text-green-600', // : 
                                    'text-red-600',
                                    'ml-2 flex items-baseline text-sm font-semibold'
                                )}>
                                {/* <ArrowSmallUpIcon className="self-center flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" /> */}
                                <ArrowSmallDownIcon className="self-center flex-shrink-0 h-5 w-5 text-red-500" aria-hidden="true" />
                                4
                            </p>
                            <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
                                <div className="text-sm text-left">
                                    <a href="#projectDeets" className="font-bold text-blue-600 hover:text-blue-500">
                                        {' '}
                                        View Details
                                    </a>
                                </div>
                            </div>
                        </dd>
                    </div>

                    {/* Unpaid Amount */}
                    <div className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
                        <dt>
                            <div className="absolute bg-blue-500 rounded-md p-3">
                                <CurrencyDollarIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </div>
                            <p className="ml-16 text-sm font-medium text-left text-gray-500 truncate">Total Unpaid Revenue</p>
                        </dt>
                        <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                            <p className="text-2xl font-semibold text-red-500">$86,000</p>
                            <p
                                className={classNames(
                                    'text-green-600', // : 
                                    //'text-red-600',
                                    'ml-2 flex items-baseline text-sm font-semibold'
                                )}>
                                {/* <ArrowSmallUpIcon className="self-center flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" /> */}
                                <ArrowSmallDownIcon className="self-center flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
                                18
                            </p>
                            <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
                                <div className="text-sm text-left">
                                    <a href="#projectDeets" className="font-bold text-blue-600 hover:text-blue-500">
                                        {' '}
                                        View Details
                                    </a>
                                </div>
                            </div>
                        </dd>
                    </div>
                                  
                </dl>
            </div>


            {/* Invoice Table */}
            <div>
                <div className="sm:hidden">
                    <label htmlFor="tabs" className="sr-only">
                        Select a tab
                    </label>
                    {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                    <select
                        id="tabs"
                        name="tabs"
                        className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                        defaultValue={Constants.tabs.find((tab) => tab.current).name}
                    >
                        {Constants.tabs.map((tab) => (
                            <option key={tab.name}>{tab.name}</option>
                        ))}
                    </select>
                </div>

                <div className="hidden sm:block mt-10 text-left">
                    <div className="flex justify-start">
                        <div className="text-4xl text-gray-900">Invoice List</div>
                        <div className="flex">
                            <button className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium focus:outline-none"
                                onClick={() => console.log("New Project Clicked")}>
                                <PlusCircleIcon className="self-center flex-shrink-0 h-8 w-8 text-blue-500" />
                                <div className="ml-1 font-bold text-blue-500">New Invoice</div>
                            </button>
                        </div>
                    </div>
                    <div className="border-b border-gray-200">
                        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                            <a href="#active"
                                className={classNames(                                        
                                        'border-blue-500 text-blue-600',
                                        //'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200', 
                                        'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm'
                                )}
                                aria-current='page'>
                                    Active
                                <span
                                    className={classNames(
                                        'bg-blue-100 text-blue-600', 
                                        //'bg-gray-100 text-gray-900',
                                        'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                                    )}>
                                    {calculateTab("Active")}
                                </span>
                            </a>
                            <a href="#overdue"
                                className={classNames(                                        
                                        //'border-blue-500 text-blue-600',
                                        'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200', 
                                        'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm'
                                )}
                                aria-current='page'>
                                    Overdue
                                <span
                                    className={classNames(
                                        //'bg-blue-100 text-blue-600', 
                                        'bg-gray-100 text-gray-900',
                                        'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                                    )}>
                                    {calculateTab("Overdue")}
                                </span>
                            </a>
                            <a href="#paid"
                                className={classNames(                                        
                                        //'border-blue-500 text-blue-600'
                                        'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200', 
                                        'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm'
                                )}
                                aria-current='page'>
                                    Paid
                                <span
                                    className={classNames(
                                        //'bg-blue-100 text-blue-600'
                                        'bg-gray-100 text-gray-900',
                                        'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                                    )}>
                                    {calculateTab("Paid")}
                                </span>
                            </a>
                            <a href="#drafts"
                                className={classNames(                                        
                                        //'border-blue-500 text-blue-600'
                                        'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200', 
                                        'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm'
                                )}
                                aria-current='page'>
                                    Drafts
                                <span
                                    className={classNames(
                                        //'bg-blue-100 text-blue-600' 
                                        'bg-gray-100 text-gray-900',
                                        'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                                    )}>
                                    {calculateTab("Draft")}                                    
                                </span>
                            </a>
                        </nav>
                    </div>
                </div>
            </div>

            {/* Team Table */}
            <div className="mt-5 flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Status
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Due Date
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Invoice Number
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Client Name
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Amount Due
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Contact
                                        </th>
                                        <th scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider">
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {invoicesList.map((invoice, index) => (
                                        <tr key={invoice.contactEmail} className={index % 2 === 0 ? 'bg-white' : 'bg-blue-50'}>
                                            <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium text-gray-500">
                                                {invoice.status === "Active" &&
                                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                        {invoice.status}
                                                    </span>
                                                }

                                                {invoice.status === "Paid" &&
                                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                        {invoice.status}
                                                    </span>
                                                }
                                                
                                                {invoice.status === "Overdue" &&
                                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                                        {invoice.status}
                                                    </span>
                                                }

                                                {invoice.status === "Draft" &&
                                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-300 text-gray-900">
                                                        {invoice.status}
                                                    </span>
                                                }
                                            </td>                                                                                          
                                            <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium text-gray-500">{invoice.dueDate}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium text-gray-500">{invoice.invoiceNumber}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium text-gray-500">{invoice.clientName}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium text-gray-500">${invoice.amountDue.toFixed(2)}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium text-blue-500">
                                                <div className="flex flex-row">
                                                    <EnvelopeIcon className="w-6 mr-2 cursor-pointer" /> 
                                                    <PhoneIcon className="w-6 cursor-pointer" />
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 text-right text-sm font-medium">
                                                <a href="#edit" className="text-blue-600 hover:text-blue-900">
                                                    <div className="flex flex-row justify-start">                                                        
                                                        View
                                                        <ChevronRightIcon className="w-5 h-5 text-blue-600" />
                                                    </div>
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}