import { Fragment, useEffect, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { Link, useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from 'react-redux'
import { changeNavState } from '../app/NavigationSlice';
import { updateUserInfo, updateSetupStages } from '../app/UserSlice';

import { loadStripe } from '@stripe/stripe-js';

import { ReactComponent as StripeLogo } from '../assets/StripeLogo.svg'

import {
    CheckBadgeIcon,
    BellIcon,
    ClipboardDocumentListIcon,
} from '@heroicons/react/24/outline'

import {
    CheckCircleIcon,
    QuestionMarkCircleIcon
} from '@heroicons/react/20/solid';

import * as Constants from '../model/index';
import Divider from '../components/Divider';

import { ReactComponent as QurbieLogo } from '../assets/Qurbie.svg'
import ModalDialog from '../components/ModalDialog';
import ModalIndeterminate from '../components/ModalIndeterminate';

import Parse from 'parse';

const SetupStateOptions = {
    Basic: 0,
    BusinessInfo: 1,
    Verify: 2,
    StartQurbie: 3
};

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function SignUpGuide(props) {
    const [alert, setAlert] = useState({ show: false });
    const [progress, setProgress] = useState({ show: false });
    const [setupStage, setSetupStage] = useState(SetupStateOptions.Verify);
    const [stagesCompleted, setStagesCompleted] = useState({basic: true, businessInfo: false, verify: false, startQurbie: false});

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userState = useSelector((state) => state.userState);

    // get logged in user data
    useEffect(()=> {
        // is user logged in?
        if (!Parse.User.current()) {
            navigate("/sign-in", {replace: true});
            return;
        }

        // retrieve which stages are complete from server
        setProgress({show: true, title: 'Updating', message: ''});
        Parse.Cloud.run('GetUserData', {}).then((result) => {				
            setProgress({show: false});
            console.log('result:', result);             

            if (!result?.business?.businessName) {
                setStagesCompleted({basic: true, businessInfo: false, verify: false, startQurbie: false});                
                console.log("Setup Stage: Business Info");
                setSetupStage(SetupStateOptions.BusinessInfo);
            } 

            if (result?.business?.businessName) {
                setStagesCompleted({basic: true, businessInfo: true, verify: false, startQurbie: false});                
                console.log("Setup Stage: Verify");
                setSetupStage(SetupStateOptions.Verify);
            }

            if (result?.business?.financialAccountId) {
                setStagesCompleted({basic: true, businessInfo: true, verify: true, startQurbie: true});
                console.log("Setup Stage: Start Qurbie");
                setSetupStage(SetupStateOptions.StartQurbie);                
            }                    
        })
        .catch((error) => {
            setProgress({show: false});
            if (error.code === 209) { // invalid session
                // token expired
                console.log("User Login Expired");
                Parse.User.logOut().then(()=> navigate('/sign-in', {replace: true}));
            } else {
                console.log("Error with getUserData:", error);
                setAlert({show: true, title: 'Error', message: 'Error with call:' + error})
            }
        });		
    }, [])


    const updateUserData = async () => {
        try {
            const result = await Parse.Cloud.run('GetUserData', {});
            console.log('updateUserData result:', result);
            dispatch(updateUserInfo(result));
            return result;		
        } catch(error) {
			if (error.code === 209) { // invalid session
				// token expired
				console.log("User Login Expired");
				Parse.User.logOut().then(()=> navigate('/sign-in', {replace: true}));
			} else {
				console.log("Error with getUserData:", error);
				//setAlert({show: true, title: 'Error', message: 'Error with call:' + error})
			}
        }	
    }

    const handleVerifyButtonClick = async () => {
        try {
            // get the user data
            const userData = await updateUserData();

            const verifyLink = userData?.business?.stripeVerifyLink;
            console.log("verifyLink:", verifyLink);

            if (verifyLink) {
                window.location.href = verifyLink;
            }
        } catch (error) {
            console.log("Error:", error);
        }
    };

    return (
        <>
            <ModalDialog show={alert.show || false} title={alert.title} message={alert.message}
                confirmButtonTitle={alert.okTitle || "Ok"} cancelButtonTitle={alert.cancelTitle} icon={<BellIcon className="text-blue-500" />}
                confirmButtonFunc={() => setAlert({ showAlert: false })} />

            <ModalIndeterminate show={progress.show} title={progress.title || ''} message={progress.message || ''} />

            <div className='my-10 grid grid-cols-6 gap-4 mx-12'>            
                <div className='col-start-1 col-span-6 bg-white rounded-lg px-8 py-20 text-white text-center text-5xl font-bold bg-gradient-to-r from-indigo-400 to-cyan-400'>
                        Get Started With Qurbie                                  
                </div>

                {/* Left Column */}
                <div className='col-start-1 col-span-2 bg-white rounded-lg p-4 pb-8'>

                    <div className="flex items-center border-b-2 p-4 border-gray-100">
                        <ClipboardDocumentListIcon className='w-8 h-8 mr-4' />
                        <h3 className="font-semibold text-lg">Setup Checklist</h3>
                    </div>

                    <div className={classNames(setupStage === SetupStateOptions.Basic ? 'bg-blue-100' : 'bg-none', 'ml-3 p-2 rounded-lg flex')}>
                        <CheckCircleIcon className={classNames(stagesCompleted?.basic === true ? 'text-green-600' : '' ,'w-6 h-6 mr-4')} />
                        <button onClick={() => setSetupStage(SetupStateOptions.Basic)}>
                            <h3>Basic Account Setup</h3>
                        </button>

                    </div>

                    <div className={classNames(setupStage === SetupStateOptions.BusinessInfo ? 'bg-blue-100' : 'bg-none', 'ml-3 p-2 rounded-lg flex')}>
                        <CheckCircleIcon className={classNames(stagesCompleted?.businessInfo ? 'text-green-600' : '' ,'w-6 h-6 mr-4')} />
                        <button onClick={() => setSetupStage(SetupStateOptions.BusinessInfo)}>
                            <h3>Business Information</h3>
                        </button>
                    </div>

                    <div className={classNames(setupStage === SetupStateOptions.Verify ? 'bg-blue-100' : 'bg-none', 'ml-3 p-2 rounded-lg flex')}>
                        <CheckCircleIcon className={classNames(stagesCompleted?.verify ? 'text-green-600' : '' ,'w-6 h-6 mr-4')} />
                        <button onClick={() => setSetupStage(SetupStateOptions.Verify)}>
                            <h3>Verify Business & Identity</h3>
                        </button>
                    </div>                    
                    

                    <div className={classNames(setupStage === SetupStateOptions.StartQurbie ? 'bg-blue-100' : 'bg-none', 'ml-3 p-2 rounded-lg flex')}>
                        <CheckCircleIcon className={classNames((stagesCompleted?.basic && stagesCompleted?.businessInfo && stagesCompleted?.verify) ? 'text-green-600' : '', 'w-6 h-6 mr-4')} />
                        <button onClick={() => setSetupStage(SetupStateOptions.StartQurbie)}>
                            <h3>Start Using Qurbie</h3>
                        </button>
                    </div>

                </div>

                {/* Right Column */}
                <div className='col-span-4 bg-white rounded-lg p-8 '>

                    {/* Basic Information */}
                    {setupStage === SetupStateOptions.Basic &&
                        <>
                            <h3 className="font-semibold text-lg">Basic Account Information</h3>
                            <h4>This information was provided when you first signed up. And can be changed by editing your profile.</h4>
                            <h4>To do this, please click on your profile picture or icon in the top right and select <b>edit profile</b>.</h4>
                            <br />

                            <button
                                className="mt-4 ml-1 flex items-center justify-center px-4 py-2 rounded-md font-bold focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white bg-green-700 text-white"
                                onClick={(e) => {
                                    setSetupStage(SetupStateOptions.BusinessInfo)
                                }}>
                                Completed: Go to Next Step
                            </button>
                        </>
                    }

                    {/* Business Information */}
                    {setupStage === SetupStateOptions.BusinessInfo &&
                        <>
                            <h3 className="font-semibold text-lg">Business Information</h3>
                            <h4>We need to know more about your business in order to provide you with the best service.</h4>
                            <br />
                            <h4 className='font-semibold'>In order to complete this step, you will need your:</h4>
                            <ul className='decoration-black list-disc ml-8'>
                                <li>Business Address and Name</li>
                                <li>Business Type (LLC, C-Corp, etc.)</li>
                                <li>Tax Information (TIN, EIN, or SSN)</li>
                            </ul>
                            
                            {stagesCompleted.businessInfo ? 
                                <button
                                    className="mt-4 ml-1 flex items-center justify-center px-4 py-2 rounded-md font-bold focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white bg-green-700 text-white"
                                    onClick={(e) => {
                                        setSetupStage(SetupStateOptions.Verify)
                                    }}>
                                    Completed: Go to Next Step
                                </button>
                                :
                                <button
                                    className="mt-4 ml-1 flex items-center justify-center px-4 py-2 rounded-md font-bold focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white bg-blue-600 text-white"
                                    onClick={(e) => {
                                        navigate('business-info', {replace: false});
                                    }}>
                                    Complete Business Profile
                                </button>
                            }
                            
                        </>
                    }


                    {/* Verify Business Information */}
                    {setupStage === SetupStateOptions.Verify &&
                        <>
                            <h3 className="font-semibold text-lg">Verfiy Your Business Information</h3>
                            <h4>In order to pay workers and manage your account, our financial partner Stripe, needs to verify your business information.</h4>
                            <br />
                            <h4 className='font-semibold'>In This Step You Will:</h4>
                            
                            <ul className='decoration-black list-disc ml-8'>
                                <li><div className='flex items-center'>Verify Your Business Name and Information</div></li>
                                <li><div className='flex items-center'>Verify Your Tax Information</div></li>                                
                                <li><div className='flex items-center'>Authorize Qurbie to Manage Your 1099 Workers</div></li>
                                <li><div className='flex items-center'>Add/Verify a Bank Account to Enable Payments and Refunds</div></li>
                            </ul>                                                        

                            <br />
                            <h4 className='font-semibold'>What You Need to Know:</h4>
                            <div className='md:visible invisible text-gray-900 flex flex-row flex-wrap items-center'>
                                · Qurbie does not store banking and personal information.
                            </div>
                            <div className='md:visible invisible text-gray-900 flex flex-row flex-wrap items-center'>
                                · Qurbie partners with <a href="https://stripe.com/docs/security" rel="noreferrer" target="_blank"><StripeLogo className="w-16" /></a> to provide secure banking services.<a href="https://stripe.com/docs/security" className="ml-1 text-sm font-bold text-blue-600">Learn More</a>
                            </div>
                            <div className='md:visible invisible text-gray-900 flex flex-row flex-wrap items-center'>
                                · The verification process keeps the Qurbie platform safe and secure.
                            </div>

                            {stagesCompleted.verify ? 
                                <button
                                    className="mt-4 ml-1 flex items-center justify-center px-4 py-2 rounded-md font-bold focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white bg-green-700 text-white"
                                    onClick={(e) => {
                                        setSetupStage(SetupStateOptions.StartQurbie)
                                    }}>
                                    Completed: Go to Next Step
                                </button>
                                :
                                <button
                                    className="mt-4 ml-1 flex items-center justify-center px-4 py-2 rounded-md font-bold focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white bg-blue-600 text-white"
                                    onClick={(e) => {
                                        // navigate('#', {replace: false});
                                        e.preventDefault();
                                        handleVerifyButtonClick();
                                    }}>
                                    Begin Business Verification
                                </button>
                            }
                        </>
                    }                   

                    {/* Start Using Qurbie */}
                    {setupStage === SetupStateOptions.StartQurbie &&
                        <>
                            <div className="flex flex-col justify-center mx-auto ">

                                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                                    <QurbieLogo className="mx-auto h-12 w-auto" />
                                    <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-700">Qurbie Account Created!</h2>
                                    <CheckBadgeIcon className="text-green-600 w-24 h-24 mx-auto" />
                                    <p className="mt-2 mb-10 text-center text-base text-gray-600">
                                        <strong>Welcome to Qurbie!</strong><br /><br />Paying your workers and filing end of year taxes has never been faster or easier!
                                    </p>

                                </div>

                                <div className="pt-5">
                                    <div className="flex justify-center">
                                        <button
                                            type="submit"
                                            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-lg font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                            onClick={e => {
                                                navigate('/dashboard')
                                            }}
                                        >
                                            Start Using Qurbie
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>


            </div>
        </>
    )
}