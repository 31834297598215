import { Fragment, useState, useEffect } from 'react'
import { PlusCircleIcon } from '@heroicons/react/20/solid'
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { updateUserInfo } from '../app/UserSlice';

import { loadSettings } from '../util/settingsStorage';

import Parse from 'parse';
import handleParseError from '../util/handleParseError';

import * as Constants from '../model/index';
import { ChevronRightIcon } from '@heroicons/react/24/outline';

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

function formattedDate(dateString) {
    let date = new Date(dateString).toLocaleDateString("en-US");
    return date;
}

export default function PaymentsHistory() {   
    const [appSettings, setAppSettings] = useState(loadSettings()); 
    const [currentTab, setCurrentTab] = useState('All');
    const [allCount, setAllAcount] = useState(0);
    const [pendingCount, setPendingCount] = useState(0);
    const [issuesCount, setIssuesCount] = useState(0);
    const [completedCount, setCompletedCount] = useState(0);

    const userState = useSelector((state) => state.userState);
        
    const dispatch = useDispatch(); 
    const navigate = useNavigate();


    useEffect(()=>{
        Parse.Cloud.run('GetUserData', {}).then((result) => {				
            console.log('GetUSerData Result:', result);
            processTabCounts(result.paymentHistory);

            dispatch(updateUserInfo(result))
        })
        .catch((error) => {
            handleParseError(error, ()=>{
                // token expired
                console.log("GetUserData: User Login Expired");
                Parse.User.logOut().then(()=> navigate('/sign-in', {replace: true}));
            });            
        });	
    }, []);
    
    const processTabCounts = (payments) => {
        if (!payments) return;

        setAllAcount(payments?.length || 0);

        let totalPending = 0;
        let totalCompleted = 0; 
        let totalIssues = 0;

        payments?.forEach(payment => {
            if (payment.status === "Pending") {                                
                totalPending++;
            } else if (payment.status === "Completed") {
                totalCompleted++;
            } else if (payment.status === "Issues") {
                totalIssues++;
            }
        });

        setPendingCount(totalPending);
        setCompletedCount(totalCompleted);            
        setIssuesCount(totalIssues);
    }

    return (
        <>            
            {/* Payments List */}
            <div>
                <div className="sm:hidden">
                    <label htmlFor="tabs" className="sr-only">
                        Select a tab
                    </label>
                    {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                    <select
                        id="tabs"
                        name="tabs"
                        className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                        defaultValue={Constants.tabs.find((tab) => tab.current).name}
                    >
                        <option>All</option>
                        <option>Pending</option>
                        <option>Completed</option>
                        <option>Issues</option>
                    </select>
                </div>

                <div className="hidden sm:block mt-10 text-left">
                    <div className="flex justify-between">
                        {/* <div className="text-4xl text-gray-900">Contractors</div> */}                        
                    </div>
                    <div className="border-b border-gray-200">
                        <div className='flex'>
                            <nav className="-mb-px flex space-x-8" aria-label="Tabs">

                                {/* All Tab */}
                                <a
                                    href="#all"
                                    className={classNames(
                                        currentTab === 'All'
                                            ? 'border-blue-500 text-blue-600'
                                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                                        'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm'
                                    )}
                                    aria-current={'page'}
                                >
                                    {'All'}
                                    
                                    <span
                                        className={classNames(
                                            currentTab ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-900',
                                            'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                                        )}
                                    >
                                        {allCount}
                                    </span>                                        
                                </a>

                                {/* Pending Tab */}
                                <a
                                    href="#pending"
                                    className={classNames(
                                        currentTab === 'Pending'
                                            ? 'border-blue-500 text-blue-600'
                                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                                        'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm'
                                    )}
                                    aria-current={'page'}
                                >
                                    {'Pending'}
                                    
                                    <span
                                        className={classNames(
                                            currentTab === 'Pending' ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-900',
                                            'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                                        )}
                                    >
                                        {pendingCount}
                                    </span>                                        
                                </a>

                                {/* Completed Tab */}
                                <a
                                    href="#completed"
                                    className={classNames(
                                        currentTab === 'Completed'
                                            ? 'border-blue-500 text-blue-600'
                                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                                        'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm'
                                    )}
                                    aria-current={'page'}
                                >
                                    {'Completed'}
                                    
                                    <span
                                        className={classNames(
                                            currentTab === 'Completed' ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-900',
                                            'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                                        )}
                                    >
                                        {completedCount}
                                    </span>                                        
                                </a>

                                {/* Issues Tab */}
                                <a
                                    href="#issues"
                                    className={classNames(
                                        currentTab === 'Issues'
                                            ? 'border-blue-500 text-blue-600'
                                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                                        'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm'
                                    )}
                                    aria-current={'page'}
                                >
                                    {'Issues'}
                                    
                                    <span
                                        className={classNames(
                                            currentTab === 'Issues' ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-900',
                                            'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                                        )}
                                    >
                                        {issuesCount}
                                    </span>                                        
                                </a>
                            </nav>
                            <div>
                                <button className="inline-flex items-center ml-4 px-3 py-2 border border-transparent text-sm leading-4 font-medium focus:outline-none"
                                    onClick={() => console.log("New Project Clicked")}>
                                    <PlusCircleIcon className="self-center flex-shrink-0 h-8 w-8 text-blue-500" />
                                    <div className="ml-1 font-bold text-blue-500"><Link to="/add-worker">Add New Worker</Link></div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Payments Table */}
            <div className="mt-5 flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        {/* <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Date
                                        </th> */}
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Name
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Rate
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Hours
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Total Amount
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Date Req.
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider"
                                        >
                                            Status
                                        </th>
                                        <th className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium text-gray-500">{/* Actions */}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {console.log("Payment History:", userState.paymentHistory)}
                                    {userState.paymentHistory && userState.paymentHistory.map((payment, index) => (
                                        <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-blue-50'}>                                            
                                            <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium text-gray-900">{payment.payeeName}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium text-gray-500">${payment.rate}/hr</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium text-gray-500">{payment.hours}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium text-gray-500">${payment.rate * payment.hours}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium text-gray-500">{formattedDate(payment.createdDate)}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium text-gray-500">
                                                {payment.status === "Completed" ?
                                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                        {payment.status}
                                                    </span>
                                                    :
                                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-orange-600">
                                                        {payment.status}
                                                    </span>
                                                }
                                            </td>                                            
                                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                <a href="#details" className="text-blue-600 hover:text-blue-900">
                                                    <ChevronRightIcon className="w-5 h-5 text-blue-600" />
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}