import { Fragment, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Switch } from '@headlessui/react'
import validator from 'validator';
import NumberFormat from 'react-number-format'
import Divider from './Divider'

import { useSelector, useDispatch } from 'react-redux'
	
import { StateOptions, CountryOptions } from '../model/Constants';
import { Link } from 'react-router-dom'

import Parse from 'parse';
import { nanoid } from '@reduxjs/toolkit';

import ModalDialog from './ModalDialog';
import ModalIndeterminate from './ModalIndeterminate';
import {BellIcon} from '@heroicons/react/24/outline';

const steps = [
	{ id: 'Step 1', name: 'Worker Details', href: '#', status: 'current' },	
	{ id: 'Step 2', name: 'Worker Added', href: '#', status: 'upcoming' },	
]


function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

export default function AddNewWorker(props) {
	const navigate = useNavigate();
	const dispatch = useDispatch()  

	const userState = useSelector((state) => state.userState);

	const [firstName, setFirstName] = useState();
	const [lastName, setLastName] = useState();
	const [email, setEmail] = useState();
	const [isHourlyRate, setIsHourlyRate] = useState(true);
	const [hourlyRate, setHourlyRate] = useState();
	
	const [autoCollectTax, setAutoCollectTax] = useState(true);
	const [isIndividual, setIsIndividual] = useState(true);	
	const [taxId, setTaxId] = useState();
	
	const [autoCollectPay, setAutoCollectPay] = useState(true);
	const [isChecking, setIsChecking] = useState(true);	
	const [bankRouting, setBankRouting] = useState();
	const [bankAccount, setBankAccount] = useState();
	
	const [alert, setAlert] = useState({show: false});
    const [progress, setProgress] = useState({show: false});

	const handleAddWorker = () => {
		if (!email || !(validator.isEmail(email))) {
			setAlert({show: true, title: 'Invalid Email', message: 'Please enter a valid email and try again.'});
			return;
		}

		if (!firstName || firstName.length < 1 || !lastName || lastName.length < 1 || 
			(isHourlyRate && !hourlyRate) || (!autoCollectTax && !taxId) || (!autoCollectTax && taxId.length < 9) ||
			(!autoCollectPay && !bankRouting) || (!autoCollectPay && !bankAccount)) {
			setAlert({show: true, title: 'Invalid Input', message: 'Please fill out all fields and try again.'});
			return;
		}


		// TODO: If we have pay and tax info ==> Just add worker to account
		// !TO DO Move this to a cloud code function - and remove afterSave trigger!
		// if auto-collect is enabled on pay or tax info we create a work request
		const WorkerRequest = Parse.Object.extend('WorkerRequest');
		const newWorkerRequest = new WorkerRequest();
		newWorkerRequest.set('firstName', firstName);
		newWorkerRequest.set('lastName', lastName);
		newWorkerRequest.set('email', email);
		newWorkerRequest.set('isHourlyRate', isHourlyRate);
		newWorkerRequest.set('hourlyRate', (hourlyRate?.replace('$', '') || ''));
		newWorkerRequest.set('autoCollectTax', autoCollectTax);
		newWorkerRequest.set('autoCollectPay', autoCollectPay);

		if (!autoCollectPay) {
			newWorkerRequest.set('bankRouting', bankRouting);
			newWorkerRequest.set('bankAccount', bankAccount);
		}

		if (!autoCollectTax) {
			newWorkerRequest.set('taxId', taxId);
		}

		console.log("UserState:", userState);
		newWorkerRequest.set('businessName', userState?.businessName || '');
		newWorkerRequest.set('businessId', userState?.businessId || '');

		// add a unique id to interact with this request
		const uuid = nanoid(16);
		newWorkerRequest.set('requestId', uuid);

		setProgress({show: true, title: 'Saving Worker'})
		newWorkerRequest.save().then((result) => {
			console.log("Saved work request for:", result.get('firstName'), result.get('lastName'));
			setProgress({show: false});
			navigate('add-worker-review', {replace: false, state: {firstName: firstName, lastName: lastName, email: email, hourlyRate: hourlyRate}});
		}).catch(error => {
			console.log("Error", error);
			setProgress({show: false});
		});
	}


	// Scroll to top on first load
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return(
		<>			
			<div className="lg:max-w-full">			
				<ModalDialog show={alert.show || false} title={alert.title} message={alert.message}
					confirmButtonTitle={alert.okTitle || "Ok"} cancelButtonTitle={alert.cancelTitle} icon={<BellIcon className="text-blue-500" />}
					confirmButtonFunc={() => setAlert({show: false})}
					cancelButtonFunc={() => setAlert({show: false})} />

				<ModalIndeterminate show={progress.show || false} title={progress.title || ''} message={progress.message || ''} />
				
				<div className="text-left">
					<div className="px-4 py-5 sm:p-6">						
						{/* <QurbieLogo className="w-48 mt-20 ml-auto mr-auto" /> */}
						
						<div className="text-3xl leading-6 font-medium text-gray-900">
							Add a New Worker
							<p className="mt-4 text-lg text-gray-500">Enter a bit of information and we'll handle the rest</p>
						</div>

						{/* Progress Bar */}
						<div className="mt-10">
							<nav aria-label="Progress">
								<ol className="space-y-4 md:flex md:space-y-0 md:space-x-4">
									{steps.map((step) => (
											<li key={step.name} className="md:flex-1">
												{step.status === 'complete' ? (
													<a href={step.href} className="group pl-4 py-2 flex flex-col border-l-4 border-green-600 hover:border-green-800 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4">
														<span className="text-xs text-green-600 font-semibold tracking-wide uppercase group-hover:text-green-800">
															{step.id} | {step.name}
														</span>
														{/* <span className="text-sm font-medium text-green-600">{step.name}</span> */}
													</a>
												) : step.status === 'current' ? (
													<a href={step.href} className="pl-4 py-2 flex flex-col border-l-4 border-blue-600 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4" aria-current="step">
														<span className="text-xs text-blue-600 font-semibold tracking-wide uppercase">{step.id} | {step.name}</span>
														{/* <span className="text-sm font-medium">{step.name}</span> */}
													</a>
												) : (
													<a href={step.href} className="group pl-4 py-2 flex flex-col border-l-4 border-gray-200 hover:border-gray-300 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4">
														<span className="text-xs text-gray-500 font-semibold tracking-wide uppercase group-hover:text-gray-700">
															{step.id} | {step.name}
														</span>
														{/* <span className="text-sm font-medium">{step.name}</span> */}
													</a>
												)}
											</li>
										))}
									</ol>
							</nav>
						</div>						
						
						{/* Form Field */}
						<form className="space-y-8 divide-gray-200 lg:py-8">
							<div className="space-y-8 divide-gray-200">							

								<div className="col-span-full">
									<Divider />
								</div>

								<div>
									<div>
										<h3 className="text-lg leading-6 font-medium text-gray-900">New Worker Details</h3>
										<p className="mt-1 text-sm text-gray-500">Please enter the general information about your new worker.</p>
									</div>

									<div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">										
										<div className="sm:col-span-3">
											<label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
												First Name
											</label>
											<div className="mt-1">
												<input
													type="text"
													name="first-name"
													id="first-name"
													autoComplete="given-name"
													className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
													onChange={(e) => setFirstName(e.target.value)}
												/>
											</div>
										</div>

										<div className="sm:col-span-3">
											<label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
												Last Name
											</label>
											<div className="mt-1">
												<input
													type="text"
													name="last-name"
													id="last-name"
													autoComplete="family-name"
													className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
													onChange={(e) => setLastName(e.target.value)}
												/>
											</div>
										</div>

										{/* Email Address */}
										<div className="sm:col-span-full">
											<label htmlFor="email" className="block text-sm font-medium text-gray-700">
												Email Address
											</label>
											<div className="mt-1">
												<input
													id="email"
													name="email"
													type="email"
													autoComplete="email"
													className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
													onChange={(e) => setEmail(e.target.value)}
												/>
											</div>
										</div>										

										<div className="col-span-full">
											<Divider />
										</div>

										<div className="text-lg leading-6 font-medium text-gray-900 text-left col-span-4">
											Payment Information
											<p className="mt-1 text-sm text-gray-500">How will your worker be paid?</p>
											<fieldset className="mt-4">												
												<div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-5">												
													<div className="flex items-center cursor-pointer">
														<input
															id="hourly-pay"
															name="hourley-pay"
															type="radio"	
															checked={isHourlyRate}
															className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300"
															onChange={e => setIsHourlyRate(true)}
														/>
														<label htmlFor="hourly-pay" className="ml-3 block text-sm font-medium text-gray-700 cursor-pointer">
															Hourly Rate
														</label>
													</div>
													<div className="flex items-center cursor-pointer">
														<input
															id="fixed-pay"
															name="fixed-pay"
															type="radio"															
															className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300"
															checked={!isHourlyRate}							
															onChange={e => setIsHourlyRate(false)}
														/>
														<label htmlFor="fixed-pay" className="ml-3 block text-sm font-medium text-gray-700 cursor-pointer">
															Fixed Dollar Amount
														</label>
													</div>
												</div>
											</fieldset>
										</div>
										
										{isHourlyRate &&
											<div className="sm:col-span-2">
												<label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
													<div>Billing Rate Per Hour</div>
												</label>
											
												<div className="mt-1">
													<NumberFormat 				
														placeholder="$0.00"																						
														thousandSeparator={true} 
														decimalScale={2} 
														fixedDecimalScale prefix={'$'} 
														className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
														onChange={(e) => setHourlyRate(e.target.value)}
													/>												
												</div>
											
											</div>
										}
										
										<div className="col-span-full">
											<Divider />
										</div>									
									</div>

									<div className="mt-6 grid grid-cols-full gap-y-6 gap-x-4 sm:grid-cols-6">
										<div className="text-lg leading-6 font-medium text-gray-900 text-left col-span-4">
											Automatically Collect Tax Information
											<p className="mt-1 text-sm text-gray-500">We can automatically collect tax information from your workers or you can manually add it</p>
										</div>
										

										<div className="sm:col-span-2">
											<div className="flex justify-center mt-8">											
												<Switch
													checked={autoCollectTax}
													onChange={setAutoCollectTax}
													className={classNames(
														autoCollectTax ? 'bg-blue-600' : 'bg-gray-400',
														'relative inline-flex flex-shrink-0 h-8 w-14 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
													)}>
													<span
														aria-hidden="true"
														className={classNames(
															autoCollectTax ? 'translate-x-6' : 'translate-x-0',
															'pointer-events-none inline-block h-7 w-7 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
														)} />
												</Switch>								
											</div>																						
										</div>
									</div>
								</div>
								<div>
									{!autoCollectTax && 
										<div className="ml-4 mt-6 grid grid-cols-full gap-y-6 gap-x-4 sm:grid-cols-6">
											<div className="text-lg leading-6 font-medium text-gray-900 text-left col-span-full">
												Enter Tax Identification Number
												<p className="mt-1 text-sm text-gray-500">
													For individuals (i.e. freelancers) this is likely their Social Security Number (SSN) in the format of 000-00-0000. For businesses, please enter the business tax idenfitication number (TIN / EIN) in the format 00-0000000
												</p>
											</div>

											<div className="sm:col-span-full  pl-8">										
												<label className="text-base font-medium text-gray-900">What Type of Worker is This?</label>
												<p className="text-sm leading-5 text-gray-500">Please select individual (i.e. freelancer) or business.</p>
												<fieldset className="mt-4">
													<legend className="sr-only">Notification method</legend>
													<div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
														<div className="flex items-center">
															<input
																id="worker-type"
																name="worker-type"
																type="radio"
																defaultChecked={true}
																onChange={()=>setIsIndividual(true)}
																className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300"
															/>
															<label htmlFor="worker-type" className="ml-3 block text-sm font-medium text-gray-700">
																Individual
															</label>
														</div>
														<div className="flex items-center">
															<input
																id="worker-type"
																name="worker-type"
																type="radio"																
																onChange={()=>setIsIndividual(false)}
																className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300"
															/>
															<label htmlFor="worker-type" className="ml-3 block text-sm font-medium text-gray-700">
																Business
															</label>
														</div>														
													</div>
												</fieldset>
											</div>											

											<div className="sm:col-span-full  pl-8">										
												<label className="text-base font-medium text-gray-900">Please Enter Their {isIndividual ? 'Social Security Number' : 'Tax ID (EIN or TIN)'} </label>
												<p className="text-sm leading-5 text-gray-500">This information is securely stored and encrypted. This information is used to submit 1099s and any additional tax reporting requirements. <a href="#taxmore" className="font-semibold text-blue-500">Learn More</a></p>
													<NumberFormat
														placeholder={isIndividual ? "000-00-0000" : "00-0000000" }
														format={isIndividual ? "###-##-####" : "##-#######" }
														mask="_" 
														className="mt-1 shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
														onChange={(e)=> setTaxId(e.target.value)}
													/>
											</div>											
										</div>
								    }

									{/* Auto Collect Payment Information */}
									<div className="mt-6 grid grid-cols-full gap-y-6 gap-x-4 sm:grid-cols-6">
										<div className="text-lg leading-6 font-medium text-gray-900 text-left col-span-4">
											Automatically Collect Payment Details
											<p className="mt-1 text-sm text-gray-500">We can automatically collect your worker's payment information for direct deposit.</p>
										</div>
										
										<div className="sm:col-span-2">
											<div className="flex justify-center mt-8">											
												<Switch
													checked={autoCollectPay}
													onChange={setAutoCollectPay}
													className={classNames(
														autoCollectPay ? 'bg-blue-600' : 'bg-gray-400',
														'relative inline-flex flex-shrink-0 h-8 w-14 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
													)}>
													<span
														aria-hidden="true"
														className={classNames(
															autoCollectPay ? 'translate-x-6' : 'translate-x-0',
															'pointer-events-none inline-block h-7 w-7 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
														)} />
												</Switch>								
											</div>																						
										</div>
									</div>
									
									{!autoCollectPay && 
										<div className="ml-4 mt-6 grid grid-cols-full gap-y-6 gap-x-4 sm:grid-cols-6">
											<div className="text-lg leading-6 font-medium text-gray-900 text-left col-span-full">
												Enter Payment Information
												<p className="mt-1 text-sm text-gray-500">												
													We simply need the workers routing and account numbers to setup direct deposit into their account.
												</p>
											</div>

											<div className="sm:col-span-full lg:col-span-full pl-8">										
												<label className="text-base font-medium text-gray-900">What type of account is this?</label>
												<p className="text-sm leading-5 text-gray-500">We support <strong>checking</strong> and <strong>savings</strong> accounts for payment.</p>
												<fieldset id='second		' className="mt-4">
													<legend className="sr-only">Account Type</legend>
													<div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">													
														<div className="flex items-center">
															<input
																id="account-type"
																name="account-type"
																type="radio"
																defaultChecked={true}
																onChange={setIsChecking}
																className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300"
															/>
															<label htmlFor="worker-type" className="ml-3 block text-sm font-medium text-gray-700">
																Checking Account
															</label>
														</div>
														<div className="flex items-center">
															<input
																id="account-type"
																name="account-type"
																type="radio"
																defaultChecked={false}
																onChange={()=>setIsChecking(false)}
																className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300"
															/>
															<label htmlFor="worker-type" className="ml-3 block text-sm font-medium text-gray-700">
																Savings Account
															</label>
														</div>
													</div>
												</fieldset>
											</div>

											<div className="sm:col-span-3  pl-8">										
												<label className="text-base font-medium text-gray-900">Enter the Routing Number</label>
												<p className="text-sm leading-5 text-gray-500">This starts with :[ and is 9-digits</p>
													<NumberFormat
														placeholder=":[ 0123456789 ]:"
														format=":[ ######### ]:"
														mask="_" 
														className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
														onChange={(e)=> setBankRouting(e.target.value)}
													/>
											</div>											
											<div className="sm:col-span-3  pl-8">										
												<label className="text-base font-medium text-gray-900">Enter the Account Number</label>
												<p className="text-sm leading-5 text-gray-500">This is typically 10-14 digits</p>
													<NumberFormat
														placeholder="00012345678901234"
														format="################"
														mask="_" 
														className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
														onChange={(e)=> setBankAccount(e.target.value)}
													/>
											</div>											
										</div>
								    }
								</div>

								<div className="col-span-full">
									<Divider />
								</div>
								
							</div>

							<div className="pt-5">
								<div className="flex justify-end">
									<button
										type="button"
										className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
										onClick={e => {
											navigate("/", {replace: true})											
										}}>
										Cancel
									</button>
								
									<button											
										type="button"
										className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
										onClick={ e => {
											console.log("12 Items:", firstName, lastName, email, isHourlyRate ? 'hourlyTrue' : 'hourlyFalse',
											hourlyRate, autoCollectTax ? 'autoTaxTrue' : 'autoTaxFalse', isIndividual ? 'individualTrue' : 'indFalse',
											taxId, autoCollectPay ? 'autoPayTrue' : 'autoPayFalse', isChecking ? 'checkingTrue' : 'checkingFalse', 
											bankRouting, bankAccount);

											handleAddWorker();
										}}>
										Add Worker
									</button>
								
								</div>
							</div>
						</form>
					</div>
				</div>			
			</div>
		</>
	)
}

