import { Fragment, useState, useEffect } from 'react'
import { useParams, useNavigate } from "react-router-dom";

import ModalDialog from './ModalDialog';
import ModalIndeterminate from './ModalIndeterminate';
import { BellIcon } from '@heroicons/react/24/outline';

import { useSelector, useDispatch } from 'react-redux'
import UserSlice, { updateUserInfo } from '../app/UserSlice';


import { ReactComponent as QurbieLogo} from '../assets/Qurbie.svg'
import validator from 'validator';

import Parse from 'parse';

export default function SignIn(props) {	
	let navigate = useNavigate();
	const dispatch = useDispatch()

	const [email, setEmail] = useState();
	const [password, setPassword] = useState();
	const [alert, setAlert] = useState({show: false});
	const [progress, setProgress] = useState({show: false});

	const userState = useSelector((state) => state.userState)


	const handleSignIn = () => {
		if (!email || !validator.isEmail(email) || !password || password.length < 1) {
			setAlert({show: true, title: 'Invalid Sign In', message: 'Please enter a valid email/password and try again.'});
			return;
		}

		setProgress({show: true, title: 'Signing In...'})
		Parse.User.logIn(email, password, { usePost: true }).then((user) => {
			setProgress({show: false});
			console.log("Signed In!");

			// get the user data we need for the app
			// Parse.Cloud.run("GetUserData", {}).then(result => {
			// 	console.log("GetUserData Result:", result);
			// 	dispatch(updateUserInfo(result));

			// 	navigate('/dashboard');
			// }).catch(error => {
			// 	console.log("Error:", error)
			// 	setAlert({show: true, title: "Error Signing In", message: `Unable to retrieve data: ${error}`});
			// });

			navigate("/dashboard");
						
		}).catch((err) => {
			setProgress({show: false});
			console.log(err);			
			let errorMessage = "" + err;
			const errorArray = errorMessage.split(':');
			if (errorArray.length > 1) {
				errorMessage = errorArray[1];
			}

			setAlert({show: true, title: 'Unable to Log In', message: errorMessage});
		});
	}

	useEffect((e) => {
		if (Parse.User.current()) {
			// get the user data we need for the app
			// Parse.Cloud.run("GetUserData", {}).then(result => {
			// 	console.log("GetUserData Result:", result);
			// 	dispatch(updateUserInfo(result));

			// 	navigate('/dashboard');
			// }).catch(error => {
			// 	console.log("Error:", error);
			// 	setAlert({show: true, title: "Error With Login", message: `${error}`});
			// });

			navigate('/dashboard');

		}
	},[]);

	return (
		<>
			<ModalDialog show={alert.show || false} title={alert.title} message={alert.message}
				confirmButtonTitle={alert.okTitle || "Ok"} cancelButtonTitle={alert.cancelTitle} icon={<BellIcon className="text-blue-500" />}
				confirmButtonFunc={() => setAlert({showAlert:false})} />

			<ModalIndeterminate show={progress.show} title={progress.title || ''} message={progress.message || ''} />

			<div className="min-h-full flex flex-col justify-center my-32 sm:px-6 lg:px-8">
				<div className="sm:mx-auto sm:w-full sm:max-w-md">
					<QurbieLogo className="mx-auto h-12 w-auto" />
					<h2 className="mt-6 text-center text-2xl font-extrabold text-gray-700">Sign in to your account</h2>
					<p className="mt-2 text-center text-sm text-gray-600">
						or{' '}
						<a href="/sign-up" className="font-medium text-blue-600 hover:text-blue-500">
							Sign Up to Get Started for Free
						</a>					
					</p>
				</div>

				<div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
					<div className="bg-white py-8 px-4 sm:rounded-lg sm:px-10">
						<form className="space-y-6" action="#" method="POST">
							<div>
								<label htmlFor="email" className="block text-sm font-medium text-gray-700">
									Email address
								</label>
								<div className="mt-1">
									<input
										id="email"
										name="email"
										type="email"
										autoComplete="email"
										required
										className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
										onChange={e => setEmail(e.target.value)}
									/>
								</div>
							</div>

							<div>
								<label htmlFor="password" className="block text-sm font-medium text-gray-700">
									Password
								</label>
								<div className="mt-1">
									<input
										id="password"
										name="password"
										type="password"
										autoComplete="current-password"
										required
										className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
										onChange={(e) => setPassword(e.target.value)}
									/>
								</div>
							</div>

							<div className="flex items-center justify-between">
								<div className="flex items-center">
									<input
										id="remember-me"
										name="remember-me"
										type="checkbox"
										className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
									/>
									<label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
										Remember me
									</label>
								</div>

								<div className="text-sm">
									<a href="/forgot-password" className="font-medium text-blue-600 hover:text-blue-500">
										Forgot your password?
									</a>
								</div>
							</div>

							<div>
								<button
									type="submit"
									className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-base font-bold text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
									onClick={e => {
										e.preventDefault(true);										

										// to login
										handleSignIn();
									}}
								>
									Sign in
								</button>
							</div>
						</form>

						<div className="mt-6">
							<div className="relative">
								<div className="absolute inset-0 flex items-center">
									<div className="w-full border-t border-gray-300" />
								</div>
								<div className="relative flex justify-center text-sm">
									<span className="px-2 bg-white text-gray-500">Or Sign In With</span>
								</div>
							</div>

							<div className="mt-6 grid grid-cols-1 gap-3">
								
								<div onClick={(e) => {
									setAlert({show: true, title: 'Coming Soon!', message: 'Sign In With Google is Coming Soon!'});
								}}>
									<a
										href="#google"
										className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
									>
										<span className="sr-only">Sign in with Google</span>										
										<div className='flex'>
											<svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 388 400">
												<g id="g1520" transform="matrix(4.1666667,0,0,4.1666667,159.78086,-139.50597)">
													<path fillRule="evenodd" clipRule="evenodd" d="M52.6,82.5c0-2.99-0.26-5.82-0.72-8.58H9.18v17.05h24.45c-1.1,5.59-4.31,10.32-9.07,13.53v11.34 h14.59C47.69,107.94,52.6,96.3,52.6,82.5L52.6,82.5z"/>
													<path fillRule="evenodd" clipRule="evenodd" d="M9.18,126.84c12.25,0,22.49-4.08,29.97-11L24.56,104.5c-4.08,2.72-9.26,4.38-15.38,4.38 c-11.83,0-21.85-7.97-25.44-18.75H-31.3v11.68C-23.86,116.63-8.55,126.84,9.18,126.84L9.18,126.84z"/>
													<path fillRule="evenodd" clipRule="evenodd" d="M-16.26,90.14c-0.94-2.72-1.44-5.63-1.44-8.66c0-3.02,0.53-5.93,1.44-8.66V61.15H-31.3 c-3.1,6.12-4.88,13-4.88,20.33c0,7.33,1.78,14.21,4.88,20.33L-16.26,90.14z"/>
													<path fillRule="evenodd" clipRule="evenodd" d="M9.18,54.08c6.69,0,12.66,2.31,17.39,6.8l12.93-12.93c-7.82-7.33-18.07-11.83-30.31-11.83 c-17.73,0-33.03,10.2-40.48,25.02l15.04,11.68C-12.67,62.05-2.65,54.08,9.18,54.08L9.18,54.08z"/>
												</g>
											</svg>
											<div className='ml-2'>Sign In with Google</div>
										</div>
									</a>
								</div>								
								
							</div>							
						</div>
					</div>
				</div>
			</div>			
		</>
	)
}
