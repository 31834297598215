import { Fragment, useEffect, useState, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useSelector, useDispatch } from 'react-redux'
import { changeClockTimeSaved } from '../app/ClockTimeSavedSlice';
import { changeGeneralState } from '../app/GeneralSlice';
import { loadSettings } from '../util/settingsStorage';

import Parse from 'parse'
import ModalDialog from './ModalDialog';
import ModalIndeterminate from './ModalIndeterminate';
import { BellIcon } from '@heroicons/react/24/outline';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function TimeSaver(props) {
    const [alert, setAlert] = useState(false);
    const [progress, setProgress] = useState(false);
    const [description, setDescription] = useState();
    const [descInvalid, setDescInvalid] = useState(false);
    const [timeInvalid, setTimeInvalid] = useState(false);
    const [rate, setRate] = useState(75);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [newDate, setNewDate] = useState((new Date()).toLocaleString('en-us'));
    const [editTime, setEditTime] = useState(false);
    const [editRate, setEditRate] = useState(false);

    const cancelButtonRef = useRef(null)

    const dispatch = useDispatch();
    const clockTimeSavedState = useSelector((state) => state.clockTimeSavedState);   
    const generalState = useSelector((state) => state.generalState);
    const userState = useSelector((state) => state.userState);


    const handleSaveNewTime = () => {
        let mins = parseInt(hours) * 60 + parseInt(minutes);
        console.log("Total New Mins:", mins);        
        dispatch(changeClockTimeSaved({minutes: mins, date: newDate, manualEntry: clockTimeSavedState.manualEntry}))

        setEditTime(false);
    }

    const saveWorkLog = (callback) => {

        if (!description || description.length <= 3) {
            setDescInvalid(true);
            return;
        }

        if (!clockTimeSavedState.minutes) {
            setTimeInvalid(true);
            return;
        }
        
        // load app settings
        const appSettings = loadSettings();
        if (!appSettings.accountCompany) {
            alert("Unable to access company.");
            return;
        }
        
        setProgress({show: true, title: 'Saving Time Data...'});        
        Parse.Cloud.run('SaveWorkLogEntry', {
            dateWorked: clockTimeSavedState.date,
            businessId: appSettings.accountCompany.id,
            workHours: clockTimeSavedState.minutes,
            workRate: rate,
            workDescription: description
        }).then(result => {
            setProgress({show: false});
            console.log("Result:", result);
            console.log("Saved work entry to db:", clockTimeSavedState.date, clockTimeSavedState.minutes, rate, description);
            // clear time saver from showing
            dispatch(changeClockTimeSaved({minutes: null, date: null}));
            dispatch(changeGeneralState({shouldUpdateWorkLog: true}));
        }).catch(error => {
            setProgress({show: false});
            console.log("SaveWorkLogEntry Error -", error);
            setAlert({show: true, title: 'Unable to Save', message: `Unable to save the work data because of an error: ${error}`})
        });

        // const WorkLogObject = Parse.Object.extend('WorkLog');
        // const newLogEntry = new WorkLogObject();
        
        // newLogEntry.set('worker', Parse.User.current());
        // newLogEntry.set('dateWorked', clockTimeSavedState.date || '');
        // newLogEntry.set('businessId', userState.businessId);
        // newLogEntry.set('workHours', clockTimeSavedState.minutes);
        // newLogEntry.set('workRate', parseFloat(rate));
        // newLogEntry.set('workDescription', description);
        // newLogEntry.set('status', 'unpaid');

        // setProgress({show: true, title: 'Saving Time Data...'});
        // newLogEntry.save().then((result) => {
        //     setProgress({show: false});
        //     console.log("Saved work entry to db:", clockTimeSavedState.date, clockTimeSavedState.minutes, rate, description);
        //     // clear time saver from showing
        //     dispatch(changeClockTimeSaved({minutes: null, date: null}));
        //     dispatch(changeGeneralState({shouldUpdateWorkLog: true}));
        // }).catch((error) => {
        //     setProgress({show: false});
        //     console.log("Error saving work entry:", error);
        //     setAlert({show: true, title: 'Unable to Save', message: `Unable to save the work data because of an error: ${error}`})
        // });
    }


    return (
        <>
            <ModalDialog show={alert.show || false} title={alert.title} message={alert.message}
                confirmButtonTitle={alert.okTitle || "Ok"} cancelButtonTitle={alert.cancelTitle} icon={<BellIcon className="text-blue-500" />}
                confirmButtonFunc={() => setAlert({show: false})}
                cancelButtonFunc={() => setAlert({show: false})} />

            <ModalIndeterminate show={progress.show || false} title={progress.title || ''} message={progress.message || ''} />

            <div className='m-1 p-8 rounded-md border-dashed border-2 border-gray-400'>
                <div className='flex justify-between gap-8'>
                    <div className='w-full'>
                        <label htmlFor="work-desc" className="block text-sm font-medium text-gray-700">
                            Description of Work Completed on {clockTimeSavedState.date || newDate}
                        </label>
                        <textarea
                            id="work-desc"
                            name="work-desc"
                            autoComplete="work-desc"
                            placeholder={descInvalid ? 'Please enter a valid description' : 'Please enter a description of the work completed...'}
                            type="text"
                            rows={3}
                            className={
                                classNames(descInvalid ? "border-red-600" : "",
                                    "shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md")
                                } 
                            onChange={(e) => {
                                setDescription(e.target.value);
                                if (e.target.value.length > 0) {
                                    setDescInvalid(false);
                                }
                            }}
                        />
                    </div>
                </div>
                <div className='w-full mt-4 flex justify-between'>
                    <div className={classNames( timeInvalid ? 'border-red-500' : '', 'my-auto text-lg font-bold border-dashed border-2 p-2')}>
                        {(editTime || clockTimeSavedState.minutes === undefined) &&
                            <>
                            <div className="flex flex-row items-center">
                                <input 
                                    id="edit-hours"
                                    name="edit-hours"
                                    autoComplete="edit-hours"    
                                    placeholder='Hours'                        
                                    type="text"
                                    value={hours}
                                    className='w-24 text-sm rounded-md border border-gray-300'
                                    onChange={(e)=>setHours(e.target.value)}
                                /> 
                                <div className='mx-2'>Hrs</div>
                                
                                <input 
                                    id="edit-minutes"
                                    name="edit-minutes"
                                    autoComplete="edit-minutes"                            
                                    placeholder='Minutes'
                                    type="text"
                                    value={minutes}
                                    className='ml-0 w-24 text-sm rounded-md border border-gray-300'
                                    onChange={(e)=>setMinutes(e.target.value)}
                                />
                                    <div className='mx-2'>Mins</div>
                                <br/>                                
                            </div>
                            <button 
                                    className="mt-2 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-2 sm:text-sm"
                                    onClick={()=> handleSaveNewTime()}>
                                    Save
                            </button>
                            </>
                        }

                        {(!editTime && clockTimeSavedState.minutes !== undefined) &&
                            <>
                                {Math.trunc(clockTimeSavedState.minutes/60) || '0'} <span className='text-sm'>Hours {' '}</span>
                                {Math.trunc(clockTimeSavedState.minutes-(Math.trunc(clockTimeSavedState.minutes/60) * 60)) || '0'} <span className='text-sm'>Minutes {' '}</span>
                                <span className='align-top text-xs text-blue-600 cursor-pointer'
                                    onClick={()=>setEditTime(true)}>Edit</span>
                                <br/><span className='text-xs'>({(clockTimeSavedState.minutes/60).toFixed(2)} hours billable)</span>
                                
                            </>
                        }

                    </div>
                    <div className='my-auto text-2xl font-semibold'>
                        {editRate &&
                            <>
                                <input 
                                    id="edit-rate"
                                    name="edit-rate"
                                    autoComplete="edit-rate"    
                                    placeholder='Rate'                        
                                    type="text"
                                    value={rate}
                                    className='w-auto text-sm rounded-md border border-gray-300'
                                    onChange={(e)=>setRate(e.target.value)}
                                />                                                                
                                <button 
                                    className="ml-2 mt-2 w-auto inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-2 sm:text-sm"
                                    onClick={()=>setEditRate(false)}>
                                    Save
                                </button>
                            </>
                        }

                        {!editRate &&
                            <>
                                ${props.rate || rate}<span className='text-sm'>/hr</span>
                                <span className='align-top text-xs text-blue-600 cursor-pointer'
                                    onClick={()=>setEditRate(true)}>Edit</span>
                            </>
                        }
                    </div>
                    <div className='my-auto text-2xl font-semibold'>
                        <span className="text-lg">Total</span> ${((clockTimeSavedState.minutes/60 || 0).toFixed(2) * (props.rate || rate)).toFixed(2)}
                    </div>
                </div>

                {!editTime && !editRate && 
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                        <button
                            type="button"
                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-2 sm:text-sm"
                            onClick={() => {
                                saveWorkLog(()=> {                                                    
                                    // clear the clockTimeSavedState
                                    dispatch(changeClockTimeSaved({minutes: null, date: null}));
                                });
                                
                            }}
                        >
                            Save Hours
                        </button>
                        <button
                            type="button"
                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                            onClick={() => {
                                dispatch(changeClockTimeSaved({minutes: null, date: null}));
                            }}
                            ref={cancelButtonRef}
                        >
                            Cancel
                        </button>
                    </div>
                }
            </div>
       </>
    );
}