/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'

const workers = [
	{ id: 0, name: 'Select a Worker', rate: 0, type: '' },
    { id: 1, name: 'Create a New Worker...', rate: 0, type: '' },
    { id: 2, name: 'David Nelson', rate: 90.00, type: 'Dev' },
	{ id: 3, name: 'Chelsea Bill', rate: 65.00, type: 'Design' },
	{ id: 4, name: 'Jeff Rainy', rate: 85.00, type: 'Dev' },
	{ id: 5, name: 'Mark Upton', rate: 55.00, type: 'Sales' },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function DropdownClients() {
    const [selected, setSelected] = useState(workers[0])

    return (
        <Listbox value={selected} onChange={setSelected}>
            {({ open }) => (
                <>
                    {/* <Listbox.Label className="block text-sm font-medium text-gray-700">Available Clients</Listbox.Label> */}
                    <div className="relative">
                        <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                            <span className="block truncate">{selected.name}</span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                        </Listbox.Button>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0">

                            <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                {workers.map((worker) => (
                                    <Listbox.Option
                                        key={worker.id}
                                        className={({ active }) =>
                                            classNames(
                                                active ? 'text-white bg-blue-600' : 'text-gray-900',
                                                    'cursor-default select-none relative py-2 pl-3 pr-9'
                                            )
                                        }
                                        value={worker}>

                                        {({ selected, active }) => (
                                            <>
                                                {worker.id === 1 &&
                                                    <span className={classNames(selected ? 'font-semibold text' : 'font-normal', 'block truncate')}>
                                                        <span className="text-blue-500">
                                                            {worker.name}
                                                        </span>
                                                    </span>
                                                }

                                                {worker.id !== 1 &&
                                                    <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                        {(worker.id !== 0) ? `${worker.name} | ${worker.type} | $${worker.rate.toFixed(2)}`  :
                                                                             `${worker.name}`
                                                        }
                                                    </span>
                                                }

                                                {selected ? (
                                                    <span
                                                        className={classNames(
                                                            active ? 'text-white' : 'text-blue-600',
                                                            'absolute inset-y-0 right-0 flex items-center pr-4'
                                                        )}>
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    )
}
