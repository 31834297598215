
import { ShieldCheckIcon } from '@heroicons/react/24/outline';
import { Fragment, } from 'react'
import { useNavigate } from "react-router-dom";


import { ReactComponent as StripeLogo } from '../assets/StripeLogo.svg'

import Parse from 'parse';

export default function SignUpGuideVerifyInfo() {
	const navigate = useNavigate();

	return (
		<>
			<div className="bg-white flex flex-col p-10 m-10 rounded-2xl">	
				<div>
					<div className='flex items-center'>
						<ShieldCheckIcon className="text-green-600 w-20 h-20 mr-4" />
						<h2 className="text-4xl font-extrabold text-gray-800">Verify Your Account</h2>
					</div>

					<p className="mt-2 mb-10 text-xl text-gray-600">
						Before we can finalize your account, our financial partner, Stripe, needs to verify your business information and your identity.
					</p>

					<p className="text-gray-700"><strong>You Will Need the Following Information:</strong></p>

					<ul className="list-disc ml-8">
						<li>Your Business Name as Filed</li>
						<li>Your Business Tax Identifier (TIN/EIN)</li>
						<li>The Entity Type for Your Business (LLC, S-Corp, etc.)</li>
						<li>Drivers License and/or Tax ID</li>
					</ul>				

					<div className='lg:visible invisible mt-4 text-gray-900 flex items-center'>
						Qurbie partners with <a href="https://stripe.com/docs/security" rel="noreferrer" target="_blank"><StripeLogo className="w-16" /></a> to verify your identity and account information <span className="ml-1 text-sm font-bold text-blue-600">Learn More</span>
					</div>	
				</div>
				
				<div className="pt-5">
					<div className="flex justify-start">
						<button
							type="submit"
							className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-bold rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
							onClick={e => {
								//navigate('/dashboard')
								let currentUser = Parse.User.current();
								currentUser.fetch().then(result=>{
									let verifyLink = currentUser.get("stripeVerify");								
									console.log("Url:", verifyLink);
									document.location.href = verifyLink;
								});
								//navigate(url);
							}}
						>
							Verify Your Account
						</button>

						<button
							type="submit"
							className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-bold rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
							onClick={e => {
								navigate("/getting-started");
							}}
						>
							Back
						</button>
					</div>
				</div>				
			</div>
		</>
	)
}