import { Fragment, useState, useEffect } from 'react'
import { Listbox, Transition, Switch } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon, ClipboardDocumentListIcon } from '@heroicons/react/20/solid'
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import { ReactComponent as QurbieLogo} from '../../assets/Qurbie.svg'

import DropdownClients from './DropdownClients'
import Divider from '../Divider'

import { StateOptions, CountryOptions } from '../../model/Constants';

const steps = [
	{ id: 'Step 1', name: 'Select Client', href: '#', status: 'complete' },
	{ id: 'Step 2', name: 'Project Details', href: '#', status: 'current' },
	{ id: 'Step 3', name: 'Add Workers', href: '#', status: 'upcoming' },
	{ id: 'Step 4', name: 'Send Rquest', href: '#', status: 'upcoming' },
]

const projectTasks = [
	{ id: '1', title: 'Complete Design', description: 'Finish designing the interface of the application.' },
	{ id: '2', title: 'Implement Interface', description: 'Implement the designs and build the UI in Tailwind CSS using the Tailwind UI components to make this process faster.' },
	{ id: '3', title: 'Develop MVP', description: 'Build out each component one at a time and test them to ensure they are working correctly. Then put them together in one nice designed web application.' },
]


function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

export default function NewProject2Details(props) {
	const [w9SwitchEnabled, setW9SwitchEnabled] = useState(false);

	// Scroll to top on first load
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return(
		<>
			<div className="lg:max-w-full mx-auto">
				<div className="bg-white overflow-hidden shadow-lg sm:rounded-lg text-left rounded-lg">
					<div className="px-4 py-5 sm:p-6 mx-10 my-10">						
						{/* <QurbieLogo className="w-48 mt-20 ml-auto mr-auto" /> */}
						
						<div className="px-4 py-5 sm:px-6 mx-auto text-center">
							<p className="text-3xl leading-6 font-semibold text-gray-700">Create Project: Project Details</p>
							<p className="mt-4 text-md text-gray-500">Add the project name, description, and tasks for your project</p>
						</div>

						{/* Progress Bar */}
						<div className="mt-20 lg:ml-40 lg:mr-40">
							<nav aria-label="Progress">
								<ol className="space-y-4 md:flex md:space-y-0 md:space-x-8">
									{steps.map((step) => (
										<li key={step.name} className="md:flex-1">
											{step.status === 'complete' ? (
												<a href={step.href} className="group pl-4 py-2 flex flex-col border-l-4 border-green-600 hover:border-green-800 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4">
													<span className="text-xs text-green-600 font-semibold tracking-wide uppercase group-hover:text-green-800">
														{step.id} | {step.name}
													</span>
													{/* <span className="text-sm font-medium text-green-600">{step.name}</span> */}
												</a>
											) : step.status === 'current' ? (
												<a href={step.href} className="pl-4 py-2 flex flex-col border-l-4 border-blue-600 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4" aria-current="step">
													<span className="text-xs text-blue-600 font-semibold tracking-wide uppercase">{step.id} | {step.name}</span>
													{/* <span className="text-sm font-medium">{step.name}</span> */}
												</a>
											) : (
												<a href={step.href} className="group pl-4 py-2 flex flex-col border-l-4 border-gray-200 hover:border-gray-300 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4">
													<span className="text-xs text-gray-500 font-semibold tracking-wide uppercase group-hover:text-gray-700">
														{step.id} | {step.name}
													</span>
													{/* <span className="text-sm font-medium">{step.name}</span> */}
												</a>
											)}
										</li>
									))}
								</ol>
							</nav>
						</div>						
						
						{/* Form Field */}
						<form className="space-y-8 divide-gray-200 lg:px-40 lg:py-20">
							<div className="space-y-8 divide-gray-200">								
								<div>
									<div>
										<h3 className="text-lg leading-6 font-medium text-gray-900">Enter the Details for This Project</h3>
										<p className="mt-1 text-sm text-gray-500">This information will be sent to the workers you add to this project.</p>
									</div>

									<div className="sm:col-span-6 mt-6">
										<label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
											Project Name
										</label>
										<div className="mt-1">
											<input
												type="text"
												name="first-name"
												id="first-name"
												autoComplete="given-name"
												className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
											/>												
										</div>
										<label htmlFor="about" className="mt-6 block text-sm font-medium text-gray-700">
											Project Description
										</label>
										<div className="mt-1">
											<textarea
											id="about"
											name="about"
											rows={3}
											className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
											defaultValue={''} />
										</div>										
									</div>

									<div className="col-span-full">
										<Divider />
									</div>

									<div className="mt-10">
										<h3 className="text-lg leading-6 font-medium text-gray-900">Add Project Tasks</h3>
										<p className="mt-1 text-sm text-gray-500">These are the tasks to be preformed by the workers on this project.</p>
									</div>

									<div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
										<div className="sm:col-span-full">
											<label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
												Project Task Name
											</label>
											<div className="mt-1">
												<input
													type="text"
													name="first-name"
													id="first-name"
													autoComplete="given-name"
													className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
												/>												
											</div>

											<label htmlFor="about" className="block text-sm font-medium text-gray-700 mt-4">
												Task Description
											</label>
											<div className="mt-1">
												<textarea
												id="taskDescription"
												name="taskDescription"
												rows={3}
												className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border border-gray-300 rounded-md"
												defaultValue={''} />
											</div>	

											<div className="flex justify-end mt-8">												
												<button
													type="submit"
													className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
													Add Task
												</button>
											</div>	
										</div>										
									</div>
								</div>

								<div className="col-span-full">
									<Divider />
								</div>

								<div className="mt-10">
									<h3 className="text-lg leading-6 font-medium text-gray-900">Project Tasks:</h3>
									<p className="mt-1 text-sm text-gray-500">These are the tasks that ouline your new project and need to be completed.</p>
								</div>
								
								{/* Project Task List */}
								<div className="mt-10">
									<div className="flow-root">
										<ul className="-mb-8">
											{projectTasks.map((task, taskIndex) => (
												<li key={task.id}>
													<div className="relative pb-8">
												
														{taskIndex !== projectTasks.length - 1 ? (
															<span className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
														) : null}

														<div className="relative flex justify-between">
															<div className="relative flex items-start space-x-3">
																<div className="relative w-10 h-10 ring-2 bg-white ring-blue-500 rounded-full flex justify-center items-center">
																	<ClipboardDocumentListIcon 
																		className="h-6 w-6 text-blue-500 text-center" />
																</div>
																<div className="min-w-0 flex-1">
																	<div>
																		<div className="text-md font-semibold text-gray-600">																		
																			{task.title}																		
																		</div>
																		<p className="mt-0.5 text-sm text-gray-500">{task.description}</p>
																	</div>
																</div>
															</div>
															<div className="flex self-center ml-20">
																<a href="#edit"><PencilSquareIcon className="text-blue-600 w-6 h-6" /></a> 
																<a href="#delete"><TrashIcon className="text-blue-600 w-6 h-6" /></a>
															</div>
														</div>
													</div>
												</li>
											))}
										</ul>
									</div>
								</div>								
							</div>

							<div className="pt-5">
								<div className="flex justify-end">
									<button
										type="button"
										className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
										Cancel
									</button>
									<button
										type="submit"
										className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
										onClick={()=> props.nextCallback()}>
										Next Step
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>			
			</div>
		</>
	)
}