import { Fragment, useState } from 'react';

import NewProject1Client from '../components/Old/NewProject1Client';
import NewProject2Details from '../components/Old/NewProject2Details';
import NewProject3Workers from '../components/Old/NewProject3Workers';
import NewProject4Review from '../components/Old/NewProject4Review';

const NewProjectState = {
    Client: 1,
    Details: 2,
    Workers: 3,
    Review: 4,
};

export default function NewProjectView() {
    const [newProjectState, setNewProjectState] = useState(NewProjectState.Client);

    const nextCallback = () => {
        if (newProjectState === NewProjectState.Client) {
            setNewProjectState(NewProjectState.Details);
        } else if (newProjectState === NewProjectState.Details) {
            setNewProjectState(NewProjectState.Workers);
        } else if (newProjectState === NewProjectState.Workers) {
            setNewProjectState(NewProjectState.Review);
        } else if (newProjectState === NewProjectState.Review) {
            setNewProjectState(NewProjectState.Client);
        } else {
            setNewProjectState(NewProjectState.Client);
        }

        console.log(newProjectState);
    }

    return ( 
        <>
            {newProjectState === NewProjectState.Client && 
                <NewProject1Client nextCallback={nextCallback} />
            }

            {newProjectState === NewProjectState.Details && 
                <NewProject2Details nextCallback={nextCallback} />
            }

            {newProjectState === NewProjectState.Workers && 
                <NewProject3Workers nextCallback={nextCallback} />
            }

            {newProjectState === NewProjectState.Review && 
                <NewProject4Review nextCallback={nextCallback} />
            }
        </>
    )
}