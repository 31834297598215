import { Fragment, useState, useEffect } from 'react'
import { Switch } from '@headlessui/react'

import NumberFormat from 'react-number-format'

import DropdownClients from './DropdownClients'
import Divider from '../Divider'

import { StateOptions, CountryOptions } from '../../model/Constants';

const steps = [
	{ id: 'Step 1', name: 'Select Client', href: '#', status: 'current' },
	{ id: 'Step 2', name: 'Project Details', href: '#', status: 'upcoming' },
	{ id: 'Step 3', name: 'Add Workers', href: '#', status: 'upcoming' },
	{ id: 'Step 4', name: 'Send Rquest', href: '#', status: 'upcoming' },
]


function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

export default function NewProject1Client(props) {
	const [w9SwitchEnabled, setW9SwitchEnabled] = useState(false);

	// Scroll to top on first load
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return(
		<>
			<div className="lg:max-w-full mx-auto pb-20 pt-0">
				<div className="bg-white overflow-hidden shadow-lg sm:rounded-lg text-left rounded-lg">
					<div className="px-4 py-5 sm:p-6 mx-10 my-10">						
						{/* <QurbieLogo className="w-48 mt-20 ml-auto mr-auto" /> */}
						
						<div className="px-4 py-5 sm:px-6 mx-auto text-center">
							<p className="text-3xl leading-6 font-semibold text-gray-700">New Project: Select Client</p>
							<p className="mt-4 text-md text-gray-500">Select the client or enter a new one to create your project</p>
						</div>

						{/* Progress Bar */}
						<div className="mt-20 lg:ml-36 lg:mr-36">
							<nav aria-label="Progress">
								<ol className="space-y-4 md:flex md:space-y-0 md:space-x-4">
									{steps.map((step) => (
											<li key={step.name} className="md:flex-1">
												{step.status === 'complete' ? (
													<a href={step.href} className="group pl-4 py-2 flex flex-col border-l-4 border-green-600 hover:border-green-800 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4">
														<span className="text-xs text-green-600 font-semibold tracking-wide uppercase group-hover:text-green-800">
															{step.id} | {step.name}
														</span>
														{/* <span className="text-sm font-medium text-green-600">{step.name}</span> */}
													</a>
												) : step.status === 'current' ? (
													<a href={step.href} className="pl-4 py-2 flex flex-col border-l-4 border-blue-600 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4" aria-current="step">
														<span className="text-xs text-blue-600 font-semibold tracking-wide uppercase">{step.id} | {step.name}</span>
														{/* <span className="text-sm font-medium">{step.name}</span> */}
													</a>
												) : (
													<a href={step.href} className="group pl-4 py-2 flex flex-col border-l-4 border-gray-200 hover:border-gray-300 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4">
														<span className="text-xs text-gray-500 font-semibold tracking-wide uppercase group-hover:text-gray-700">
															{step.id} | {step.name}
														</span>
														{/* <span className="text-sm font-medium">{step.name}</span> */}
													</a>
												)}
											</li>
										))}
									</ol>
							</nav>
						</div>						
						
						{/* Form Field */}
						<form className="space-y-8 divide-gray-200 lg:px-40 lg:py-20">
							<div className="space-y-8 divide-gray-200">
								<div className="text-lg leading-6 font-medium text-gray-900 text-left">
									Select an Existing Client for This Project
									<p className="mt-1 text-sm text-gray-500">Below is a list of your existing clients that are available for projects. You can also add a new client below.</p>

									{/* Select a Client */}
									<div className="mt-5 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
										<div className="sm:col-span-4 lg:col-span-full">
											<DropdownClients />
										</div>
									</div>
								</div>

								<div className="col-span-full">
									<Divider />
								</div>

								<div>
									<div>
										<h3 className="text-lg leading-6 font-medium text-gray-900">Create A New Client</h3>
										<p className="mt-1 text-sm text-gray-500">Fill out the info below to add a new client to your account for this project and future ones.</p>
									</div>

									<div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
										<div className="sm:col-span-full">
											<label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
												Business Name
											</label>
											<div className="mt-1">
												<input
													type="text"
													name="first-name"
													id="first-name"
													autoComplete="given-name"
													className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
												/>
											</div>
										</div>

										<div className="sm:col-span-3">
											<label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
												Primary Contact First name
											</label>
											<div className="mt-1">
												<input
													type="text"
													name="first-name"
													id="first-name"
													autoComplete="given-name"
													className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
												/>
											</div>
										</div>

										<div className="sm:col-span-3">
											<label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
												Last name
											</label>
											<div className="mt-1">
												<input
													type="text"
													name="last-name"
													id="last-name"
													autoComplete="family-name"
													className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
												/>
											</div>
										</div>

										{/* Email Address */}
										<div className="sm:col-span-3">
											<label htmlFor="email" className="block text-sm font-medium text-gray-700">
												Email address
											</label>
											<div className="mt-1">
												<input
													id="email"
													name="email"
													type="email"
													autoComplete="email"
													className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
												/>
											</div>
										</div>

										{/* Phone Number */}
										<div className="sm:col-span-3">
											<label htmlFor="email" className="block text-sm font-medium text-gray-700">
												Phone Number
											</label>
											<div className="mt-1">
												<NumberFormat
													placeholder="(___) ___-____"
  													format="(###) ###-####"
													mask="_" 
													className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
													onChange={(e)=> console.log(e.target.value)}
													/>																								
											</div>
										</div>

										<div className="col-span-full">
											<Divider />
										</div>

										<div className="text-lg leading-6 font-medium text-gray-900 text-left col-span-4">
											Client Address
											<p className="mt-1 text-sm text-gray-500">This information is optional and can be added later.<br/>
											This information is often needed for tax purposes and mailings.</p>
										</div>

										<div className="sm:col-span-6">
											<label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
												Street address
											</label>
											<div className="mt-1">
												<input
													type="text"
													name="street-address"
													id="street-address"
													autoComplete="street-address"
													className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
												/>
											</div>
										</div>

										<div className="sm:col-span-2">
											<label htmlFor="city" className="block text-sm font-medium text-gray-700">
												City
											</label>
											<div className="mt-1">
												<input
													type="text"
													name="city"
													id="city"
													autoComplete="address-level2"
													className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
												/>
											</div>
										</div>

										<div className="sm:col-span-2">
											<label htmlFor="region" className="block text-sm font-medium text-gray-700">
												State / Province
											</label>
											<div className="mt-1">
												<select
													id="country"
													name="country"
													autoComplete="country-name"
													className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md">
													{StateOptions()}													
												</select>
											</div>
										</div>

										<div className="sm:col-span-2">
											<label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
												ZIP / Postal code
											</label>
											<div className="mt-1">
												<input
													type="text"
													name="postal-code"
													id="postal-code"
													autoComplete="postal-code"
													className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
												/>
											</div>
										</div>

										<div className="sm:col-span-2">
											<label htmlFor="country" className="block text-sm font-medium text-gray-700">
												Country
											</label>
											<div className="mt-1">
												<select
													id="country"
													name="country"
													autoComplete="country-name"
													className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
												>
													{CountryOptions()}													
												</select>
											</div>
										</div>
									</div>
								</div>

								<div className="col-span-full">
									<Divider />
								</div>

								<div className="pt-0">
									<div>
										<Switch.Group as="div" className="flex items-center justify-between">
											<span className="flex-grow flex flex-col">
												<Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
													Automatically Send Signed W9 to Client
												</Switch.Label>
												<Switch.Description as="span" className="text-sm text-gray-500">
													We can automatically send your Signed W9 to the email address listed above.<br/>
													Don't have a signed W9 on file? <a href="#addw9" className="text-blue-500">Add One Now</a>.
												</Switch.Description>
											</span>
											<Switch
												checked={w9SwitchEnabled}
												onChange={setW9SwitchEnabled}
												className={classNames(
													w9SwitchEnabled ? 'bg-blue-600' : 'bg-gray-400',
													'relative inline-flex flex-shrink-0 h-8 w-14 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
												)}>
												<span
													aria-hidden="true"
													className={classNames(
														w9SwitchEnabled ? 'translate-x-6' : 'translate-x-0',
														'pointer-events-none inline-block h-7 w-7 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
													)} />
											</Switch>
										</Switch.Group>
									</div>
								</div>
							</div>

							<div className="pt-5">
								<div className="flex justify-end">
									<button
										type="button"
										className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
										Cancel
									</button>
									<button
										//type="submit"
										type="button"
										className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
										onClick={props.nextCallback}>
										Next Step
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>			
			</div>
		</>
	)
}

