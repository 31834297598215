import { Fragment, useState, useEffect } from 'react'
import { useParams, useNavigate, Link } from "react-router-dom";
import Parse from 'parse';
import ModalDialog from './ModalDialog';
import ModalIndeterminate from './ModalIndeterminate';
import { BellIcon } from '@heroicons/react/24/outline';
import { ReactComponent as QurbieLogo } from '../assets/Qurbie.svg'
import { BallTriangle } from 'react-loader-spinner'
import { getUsersIp } from '../util/ipUtils';

export default function AccountStatus(props) {
    const [alert, setAlert] = useState({ showAlert: false });
    const [progress, setProgress] = useState({ show: false });
    
    const navigate = useNavigate();

    useEffect(() => {
        // first make sure we have a user signed in
        if (!Parse.User.current()) {
            props.navigate('/sign-in');
            return;
        }

        getAccountStatus();
    }, []);


    const getAccountStatus = async () => {
        try {
            //now get a new verification link for the account via cloud code
            const response = await Parse.Cloud.run('GetStripeAccountStatus');
            console.log("GetStripeAccountStatus Response:", response);

            const accountId = response.stripeAccount?.id
            console.log("Stripe Account Id:", accountId);

            const status = response.status;
            if (!status.treasury) {
                console.log("No Treasury! Need to create the fa account.");

                // create the treasury account
                const response = await Parse.Cloud.run("CreateFinancialAccount", { accountId: accountId });
                console.log("CreateFinancialAccount Response:", response);

                // save the verification links?
                const faId = response?.financialAccount?.id || null;
                const faLink = response?.verification?.url || null;

                console.log("FA ID:", faId);
                console.log("faLink:", faLink);

                if (faId && faLink) {
                    const response = await Parse.Cloud.run('UpdateFaInfo', { faAccountId: faId, faLink: faLink });
                    console.log("UpdateFaInfo Response:", response);

                    // redirect user to faLink for verification
                    document.location.href = faLink;
                } else {
                    console.log("ERROR! What happened here?");
                }

            } else if (status.treasury === "active" || status.treasury === "pending" || status.treasury === "inactive") {
                // get users IP
                const usersIp = await getUsersIp();
                
                const result = await Parse.Cloud.run('FinalizeBankingInfo', { stripeAccountId: accountId, userAgent: navigator.userAgent, ipAddress: usersIp });
                console.log("UpdateBankAccountUsage Result:", result);
                // const jsonResult = await result.json();
                if (result.status === "succeeded") {
                    console.log("Banking info updated successfully!");
                } else {
                    console.log("Banking info failed to update!");
                    // TODO: Create event log to track issues in the future
                }

                console.log("Everything is setup!");
                navigate('/getting-started');
            }
        } catch (error) {
            console.log("getAccountStatus Error", error);
        }

    }

    return (
        <>
            <div>
                <ModalDialog show={alert.showAlert || false} title={alert.title} message={alert.message}
                    confirmButtonTitle={alert.okTitle || "Ok"} cancelButtonTitle={alert.cancelTitle} icon={<BellIcon className="text-blue-500" />}
                    confirmButtonFunc={() => setAlert({ showAlert: false })} />

                <ModalIndeterminate show={progress.show} title={progress.title || ''} message={progress.message || ''} />

                {/*  */}

                <div className="bg-white min-h-full w-1/2 flex flex-col justify-center p-20 mx-auto my-20 rounded-2xl">

                    <div className="sm:mx-auto sm:w-full sm:max-w-md">
                        <QurbieLogo className="mx-auto h-12 w-auto" />
                        <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-700">Updating Account</h2>
                        <p className="mt-2 mb-10 text-center text-sm text-gray-600">
                            This will only take a second...
                        </p>
                        <div className="mt-2 flex justify-center">
                            <BallTriangle color="#00BFFF" height={60} width={60} />
                        </div>
                    </div>
                </div>

                {/*  */}
            </div>
        </>
    )
}